import React, {useState} from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {Formik} from "formik";

import {
    Alert as MuiAlert,
    Button,
    TextField as MuiTextField,
} from "@mui/material";
import {spacing} from "@mui/system";

import {useForgotPasswordMutation} from "../../graphql/auth/auth.graphql-gen";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ResetPassword() {

    const [message, setMessage] = useState('');
    const [forgotPassword] = useForgotPasswordMutation();

    return (
        <Formik
            initialValues={{
                email: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Muss eine gültige E-Mail sein")
                    .max(255)
                    .required("E-Mail ist erforderlich"),
            })}
            onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                try {
                    forgotPassword({
                        variables: {email: values.email}
                    });
                    setMessage('Link zum Zurücksetzen des Passworts wird an Ihre E-Mail gesendet')

                } catch (error: any) {
                    const message = error.message || "Etwas ist schief gelaufen";

                    setStatus({success: false});
                    setErrors({submit: message});
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}
                    {message && (
                        <Alert mt={2} mb={1} severity="success">
                            {message}
                        </Alert>
                    )}
                    <TextField
                        type="email"
                        name="email"
                        label="E-Mail Adresse"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        Passwort zurücksetzen
                    </Button>
                </form>
            )}
        </Formik>
    );
}

export default ResetPassword;
