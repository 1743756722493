import React from "react";
import {Button, Grid, Typography} from "@mui/material";

// Types


// Styling
import {DynamicSelectOption} from "../../../../types/dynamicSelectOption";
import DynamicOptionItem from "./DynamicOptionItem";
import {Plus} from "react-feather";


const DynamicOptions: React.FC<{ options: DynamicSelectOption[], onAdd: (data: DynamicSelectOption) => void, onUpdate: (data: any) => void, onDelete: (data: any) => void }> = (props) => {
    const {onAdd, onUpdate, onDelete} = props;

    // Prepare files for upload
    const onAddNew = () => {
        let sorting: number = 0;

        if (props.options) {
            const sortingArray: any = props.options.map(item => item.sorting);
            if(sortingArray.length > 0){
                let lastElement = Math.max(...sortingArray);
                sorting = ++lastElement;
            }
        }

        const newOption = {
            id: new Date().getTime().toString(),
            title: '',
            sorting
        }

        onAdd(newOption);

    }

    // Trigger file update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }

    // Trigger file delete
    const onDeleteHandler = (data: any) => {
        onDelete(data.id);
    }


    return (
        <React.Fragment>

            <Grid item xs={12} mb={5} mx={2}>

                <Typography variant="h6" mb={5}>
                    Optionen
                </Typography>
            </Grid>

            <Grid item xs={12}  mx={2}>
                {props.options.map((item: DynamicSelectOption) => {
                    return (
                        <DynamicOptionItem key={item.id} item={item} onUpdate={onUpdateHandler}
                                           onDelete={onDeleteHandler}/>
                    )
                })}
            </Grid>

            <Grid item xs={12} mb={5} mx={2}>
                <Button variant="contained" component="span" startIcon={<Plus />} onClick={() => onAddNew()}>Option hinzufügen</Button>
            </Grid>
        </React.Fragment>
    );
}

export default DynamicOptions;
