import React, {ChangeEvent, useEffect, useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Divider as MuiDivider,
    Grid, IconButton, InputLabel, Stack, TextField as MuiTextField,
    Typography
} from "@mui/material";
import ReactQuill from "react-quill";
import InlineMedia from "../inline-media/InlineMedia";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    InlineMediaUploadProductMutation,
    useInlineMediaUploadProductMutation
} from "../../graphql/product/product.graphql-gen";
import SnackbarNotification from "../SnackbarNotification";
import {MediaItemType} from "../../types/inlineMedia";

// Types
import {ContentElementItemType} from "../../types/inlineContentElementType";

// Icons
import {Trash2 as TrashIcon} from "react-feather";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import Loader from "../Loader";

const Divider = styled(MuiDivider)(spacing);
const QuillWrapper = styled.div`.ql-editor {
  min-height: 200px;
}`;
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const ContentElementItem: React.FC<{
    item: ContentElementItemType,
    onUpdate: (data: any) => void,
    onDelete: (data: any) => void,
    onMediaDelete: (data: any) => void,
    onMediaBrowse: (data: any) => void,
    multiEdit: any
}> = (props) => {
    const [inlineMediaUpload] = useInlineMediaUploadProductMutation();
    const [item, setItem] = useState(props.item);
    const [mediaItems, setMediaItems] = useState<any>(item.images ? item.images : []);
    const [loader, setLoader] = useState<boolean>(false);


    useEffect(() => {
        props.onUpdate(item);
    }, [item]);

    // Set loading
    if (loader) {
        return <Loader/>
    }


    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setItem({...item, [fieldName]: value});
    };


    // Update description on change
    const handleChangeDescription = (event: any) => {
        event.toString('html');
        setItem({...item, ['description']: event});
    };
    // Update description on change
    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setItem({...item, ['descriptionDe']: event});
    };

    // trigger update
    const updateHandler = () => {
        props.onUpdate(item);
    };

    // Trigger delete
    const deleteHandler = () => {
        props.onDelete(item);
    };


    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id: '',
                model: 'contentElement',
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;

                // Update inline media
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });

                // Update select value
                if (addedFiles) {
                    // @ts-ignore
                    setItem({...item, ['images']: addedFiles});
                }
                setLoader(false);

            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        mediaItems[foundIndex] = updateData;
        setMediaItems(mediaItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
        props.onMediaDelete(id);
    }

    const onMediaBrowseHandler = () => {
        props.onMediaBrowse(item);
    }

    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={9} md={11}>
                    <Accordion className="media-item-accordion" sx={{borderRadius: 6, borderColor: 'rgba(0,0,0,0.12)'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            sx={{margin: '0'}}
                        >
                            <Stack direction="row" spacing={2} alignItems="center" sx={{padding: '0 10px'}}>
                                {item.title !== ''
                                    ? <Typography>{item.title}</Typography>
                                    : <Typography>Neues Inhaltselement</Typography>
                                }

                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails className="media-item-content">
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="title"
                                        label="Titel"
                                        value={item.title}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        required
                                        my={2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="titleDe"
                                        label="Titel FRS"
                                        value={item.titleDe}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={3} md={1}>
                    <IconButton aria-label="delete" size="large"
                                onClick={() => deleteHandler()}>
                        <TrashIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ContentElementItem;
