import React, {useState} from "react";

import {
    Checkbox, Divider as MuiDivider, FormControl,
    FormControlLabel,
    FormGroup, FormLabel,
    Grid, Radio, RadioGroup,
    TextField as MuiTextField, Typography,

} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import de from "date-fns/locale/de";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Divider = styled(MuiDivider)(spacing);



const AtributesDanger: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const [valueEnvironmentalDangers, setValueEnvironmentalDangers] = useState<string>(values.environmentalDangers ? values.environmentalDangers : '1');
    const [valueActive, setValueActive] = useState<string>(values.active ? values.active : '1');
    const [lvsLastActivity, setLvsLastActivity] = React.useState<Date | null>(state.lvsLastActivity ?  state.lvsLastActivity : (values.lvsLastActivity ?  values.lvsLastActivity : null));



    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueEnvironmentalDangers((event.target as HTMLInputElement).value);
    };
    const handleRadioActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueActive((event.target as HTMLInputElement).value);
    };


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={4}>
                    <FormControl>
                        <FormLabel
                            id="environmentalDangers">Umweltgefahren</FormLabel>
                        <RadioGroup
                            aria-labelledby="environmentalDangers"
                            name="environmentalDangers"
                            value={valueEnvironmentalDangers}
                            onChange={(e) => {
                                handleRadioChange(e);
                                onChange(e)
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Ja"/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label="Nein"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} mb={4}>
                    <FormControl>
                        <FormLabel
                            id="active">Aktiv</FormLabel>
                        <RadioGroup
                            aria-labelledby="active"
                            name="active"
                            value={valueActive}
                            onChange={(e) => {
                                handleRadioActiveChange(e);
                                onChange(e)
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Ja"/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label="Nein"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={6}>
                    <TextField
                        name="fishDangerLabel"
                        label={'Fisch Gefahrzettel'}
                        value={values.fishDangerLabel ? values.fishDangerLabel : ''}
                        error={Boolean(touched.fishDangerLabel && errors.fishDangerLabel)}
                        fullWidth
                        helperText={touched.fishDangerLabel && errors.fishDangerLabel}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearFishDangerLabel"
                                    checked={state.clearFishDangerLabel ? state.clearFishDangerLabel : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={6}>
                    <TextField
                        name="alignmentArrowsDangerLabels"
                        label={'Ausrichtungspfeile Gefahrzettel'}
                        value={values.alignmentArrowsDangerLabels ? values.alignmentArrowsDangerLabels : ''}
                        error={Boolean(touched.alignmentArrowsDangerLabels && errors.alignmentArrowsDangerLabels)}
                        fullWidth
                        helperText={touched.alignmentArrowsDangerLabels && errors.alignmentArrowsDangerLabels}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearAlignmentArrowsDangerLabels"
                                    checked={state.clearAlignmentArrowsDangerLabels ? state.clearAlignmentArrowsDangerLabels : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                        <DatePicker
                            label="Date LVS Last Activity"
                            value={new Date(lvsLastActivity!)}
                            onChange={(newValue: any) => {
                                setLvsLastActivity(newValue)
                                onChange( '', 'lvsLastActivity', 'date', newValue);
                            }}
                        />
                    </LocalizationProvider>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearLvsLastActivity"
                                    checked={state.clearLvsLastActivity ? state.clearLvsLastActivity : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Gefahrzettel
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={6}>
                    <TextField
                        name="class3dangerLabel"
                        label={'Kl. 3 Gefahrzettel'}
                        value={values.class3dangerLabel ? values.class3dangerLabel : ''}
                        error={Boolean(touched.class3dangerLabel && errors.class3dangerLabel)}
                        fullWidth
                        helperText={touched.class3dangerLabel && errors.class3dangerLabel}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearClass3dangerLabel"
                                    checked={state.clearClass3dangerLabel ? state.clearClass3dangerLabel : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={6}>
                    <TextField
                        name="class51dangerLabel"
                        label={'Kl. 5.1 Gefahrzettel'}
                        value={values.class51dangerLabel ? values.class51dangerLabel : ''}
                        error={Boolean(touched.class51dangerLabel && errors.class51dangerLabel)}
                        fullWidth
                        helperText={touched.class51dangerLabel && errors.class51dangerLabel}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearClass51dangerLabel"
                                    checked={state.clearClass51dangerLabel ? state.clearClass51dangerLabel : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={6}>
                    <TextField
                        name="class8dangerLabel"
                        label={'Kl. 8 Gefahrzettel'}
                        value={values.class8dangerLabel ? values.class8dangerLabel : ''}
                        error={Boolean(touched.class8dangerLabel && errors.class8dangerLabel)}
                        fullWidth
                        helperText={touched.class8dangerLabel && errors.class8dangerLabel}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearClass8dangerLabel"
                                    checked={state.clearClass8dangerLabel ? state.clearClass8dangerLabel : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={6}>
                    <TextField
                        name="class9dangerLabel"
                        label={'Kl. 9 Gefahrzettel'}
                        value={values.class9dangerLabel ? values.class9dangerLabel : ''}
                        error={Boolean(touched.class9dangerLabel && errors.class9dangerLabel)}
                        fullWidth
                        helperText={touched.class9dangerLabel && errors.class9dangerLabel}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearClass9dangerLabel"
                                    checked={state.clearClass9dangerLabel ? state.clearClass9dangerLabel : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Dangerous Labels
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={6}>
                    <TextField
                        name="dangerousLabel8"
                        label={'Dangerous Label 8'}
                        value={values.dangerousLabel8 ? values.dangerousLabel8 : ''}
                        error={Boolean(touched.dangerousLabel8 && errors.dangerousLabel8)}
                        fullWidth
                        helperText={touched.dangerousLabel8 && errors.dangerousLabel8}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDangerousLabel8"
                                    checked={state.clearDangerousLabel8 ? state.clearDangerousLabel8 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={6}>
                    <TextField
                        name="dangerousLabel9"
                        label={'Dangerous Label 9'}
                        value={values.dangerousLabel9 ? values.dangerousLabel9 : ''}
                        error={Boolean(touched.dangerousLabel9 && errors.dangerousLabel9)}
                        fullWidth
                        helperText={touched.dangerousLabel9 && errors.dangerousLabel9}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDangerousLabel9"
                                    checked={state.clearDangerousLabel9 ? state.clearDangerousLabel9 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={6}>
                    <TextField
                        name="dangerousLabel10"
                        label={'Dangerous Label 10'}
                        value={values.dangerousLabel10 ? values.dangerousLabel10 : ''}
                        error={Boolean(touched.dangerousLabel10 && errors.dangerousLabel10)}
                        fullWidth
                        helperText={touched.dangerousLabel10 && errors.dangerousLabel10}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDangerousLabel10"
                                    checked={state.clearDangerousLabel10 ? state.clearDangerousLabel10 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AtributesDanger;
