import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel, Divider as MuiDivider, InputLabel, Select, MenuItem, FormControl
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const AdditionalProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const dangerousGoods: { id: string, title: string, code: string }[] = data.selectDangerousGoods;
    const selectedDangerousGoodsObj = state.dangerousGoods ? state.dangerousGoods.map((id: string) => dangerousGoods.filter((item) => item.id === id)[0]) : (values.dangerousGoods ? values.dangerousGoods.map((id: string) => dangerousGoods.filter((item) => item.id === id)[0]) : []);
    const [selectedDangerousGoods, setSelectedDangerousGoods] = useState<any[]>(selectedDangerousGoodsObj);

    const symbols: { id: string, title: string, code: string }[] = data.selectSymbols;
    const selectedSymbolsObj = state.symbols ? state.symbols.map((id: string) => symbols.filter((item) => item.id === id)[0]) : (values.symbols ? values.symbols.map((id: string) => symbols.filter((item) => item.id === id)[0]) : []);
    const [selectedSymbols, setSelectedSymbols] = useState<any[]>(selectedSymbolsObj);

    const unNumber1: { id: string, title: string, code: string }[] = data.selectDangerousGoods;
    const selectedUnNumber1Obj = state.unNumber1 ? state.unNumber1.map((id: string) => unNumber1.filter((item) => item.id === id)[0]) : (values.unNumber1 ? values.unNumber1.map((id: string) => unNumber1.filter((item) => item.id === id)[0]) : []);
    const [selectedUnNumber1, setSelectedUnNumber1] = useState<any[]>(selectedUnNumber1Obj);

    const unNumber2: { id: string, title: string, code: string }[] = data.selectDangerousGoods;
    const selectedUnNumber2Obj = state.unNumber2 ? state.unNumber2.map((id: string) => unNumber2.filter((item) => item.id === id)[0]) : (values.unNumber2 ? values.unNumber2.map((id: string) => unNumber2.filter((item) => item.id === id)[0]) : []);
    const [selectedUnNumber2, setSelectedUnNumber2] = useState<any[]>(selectedUnNumber2Obj);

    const selectedLimitedQuantityObj = state.limitedQuantity ? state.limitedQuantity : '';
    const [selectedLimitedQuantity, setSelectedLimitedQuantity] = useState<any[]>(selectedLimitedQuantityObj);

    const selectedSpecialRegulationObj = state.specialRegulation ? state.specialRegulation : '';
    const [selectedSpecialRegulation, setSelectedSpecialRegulation] = useState<any[]>(selectedSpecialRegulationObj);

    //------------------------------- Public methods -------------------------------------

    // Update multiselect on change with autocomplete


    const handleDangerousGoodsChange = (value: any) => {
        setSelectedDangerousGoods(value);
    };

    const handleSymbolsChange = (value: any) => {
        setSelectedSymbols(value);
    };

    const handleUnNumber1Change = (value: any) => {
        setSelectedUnNumber1(value);
    };

    const handleUnNumber2Change = (value: any) => {
        setSelectedUnNumber2(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Gefahrengut
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={4} mb={5}>
                    <Autocomplete
                        multiple
                        disabled={true}
                        options={unNumber1}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title && option.code ? option.title + ' (' + option.code + ')' : ''}
                        value={selectedUnNumber1 || []}
                        defaultValue={selectedUnNumber1 || []}
                        onChange={(event: any, value: any) => {
                            handleUnNumber1Change(value);
                            onChange(event, 'unNumber1', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title + ' (' + option.code + ')'}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="UN-Nummer 1" placeholder="UN-Nummer 1"
                                       name="unNumber1"/>
                        )}
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <Autocomplete
                        multiple
                        disabled={true}
                        options={unNumber2}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title && option.code ? option.title + ' (' + option.code + ')' : ''}
                        value={selectedUnNumber2 || []}
                        defaultValue={selectedUnNumber2 || []}
                        onChange={(event: any, value: any) => {
                            handleUnNumber2Change(value);
                            onChange(event, 'unNumber2', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title + ' (' + option.code + ')'}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="UN-Nummer 2" placeholder="UN-Nummer 2"
                                       name="unNumber2"/>
                        )}
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Begrenzte Menge</InputLabel>
                        <Select
                            name="limitedQuantity"
                            value={selectedLimitedQuantity}
                            label="Begrenzte Menge"
                            onChange={(event: any) => {
                                setSelectedLimitedQuantity(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="Ja" value="1">
                                Ja
                            </MenuItem>
                            <MenuItem key="Nein" value="0">
                                Nein
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearLimitedQuantity"
                                    checked={state.clearLimitedQuantity ? state.clearLimitedQuantity : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Sondervorschrift</InputLabel>
                        <Select
                            name="specialRegulation"
                            value={selectedSpecialRegulation}
                            label="Sondervorschrift"
                            onChange={(event: any) => {
                                setSelectedSpecialRegulation(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="Ja" value="1">
                                Ja
                            </MenuItem>
                            <MenuItem key="Nein" value="0">
                                Nein
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSpecialRegulation"
                                    checked={state.clearSpecialRegulation ? state.clearSpecialRegulation : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <Autocomplete
                        multiple
                        options={symbols}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedSymbols || []}
                        defaultValue={selectedSymbols || []}
                        onChange={(event: any, value: any) => {
                            handleSymbolsChange(value);
                            onChange(event, 'symbols', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <img src={`/img/symbols/${option.title}.svg`} width={30} height={30}/> {/*Mock image, attribute in option*/}
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Gefahrensymbole "
                                       placeholder="Gefahrensymbole "
                                       name="symbols"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSymbols"
                                    checked={state.clearSymbols ? state.clearSymbols : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="chzn"
                        label="CHZN/CHZB-Nr."
                        value={values.chzn ? values.chzn : ''}
                        error={Boolean(touched.chzn && errors.chzn)}
                        fullWidth
                        helperText={touched.chzn && errors.chzn}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearChzn"
                                    checked={state.clearChzn ? state.clearChzn : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="ufiNr"
                        label="UFI-Nr."
                        value={values.ufiNr ? values.ufiNr : ''}
                        error={Boolean(touched.ufiNr && errors.ufiNr)}
                        fullWidth
                        helperText={touched.ufiNr && errors.ufiNr}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearUfiNr"
                                    checked={state.clearUfiNr ? state.clearUfiNr : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="biocideNotice"
                        label="Biozidhinweis"
                        value={values.biocideNotice ? values.biocideNotice : ''}
                        error={Boolean(touched.biocideNotice && errors.biocideNotice)}
                        fullWidth
                        helperText={touched.biocideNotice && errors.biocideNotice}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearBiocideNotice"
                                    checked={state.clearTunnelCode ? state.clearTunnelCode : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="biocidesLeaflet"
                        label="Merkblatt Biozide"
                        type={"url"}
                        value={values.biocidesLeaflet ? values.biocidesLeaflet : ''}
                        error={Boolean(touched.biocidesLeaflet && errors.biocidesLeaflet)}
                        fullWidth
                        helperText={touched.biocidesLeaflet && errors.biocidesLeaflet}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearBiocidesLeaflet"
                                    checked={state.clearBiocidesLeaflet ? state.clearBiocidesLeaflet : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AdditionalProduct;
