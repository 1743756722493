import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
    en: {
        translation: {
            Search: "Search topics…",
            "Welcome back": "Willkommen zurück",
            "We've missed you": "Wir haben Sie vermisst.",
            Title: "Titel",
            Subtitle: "Untertitel",
            ShortDescription: "Kurze Beschreibung",
            Description: "Beschreibung",
            ApprovalsCompliancesNote: "Approvals & Compliances note",
            SeoTitle: "Seo Titel",
            SeoDescription: "Seo Beschreibung",
            SeoKeywords: "Seo Schlüsselwörter",
            TechnicalSpecificationsNote: "Technical specifications Note",
            City: "City",
            Additional: "Additional",
            Link: "Link",
            Eplan: "Eplan",
            CadCae: "CAD & CAE",
            CadFileLink: "Cad File Link",
            SpiceModel: "Spice Model",
            Value: "Value",
            Type: "Type",
            CompaniesTitleListView: "Lieferanten"
        },
    },
    fr: {
        translation: {
            Search: "Rechercher…",
            "Welcome back": "Bon retour",
            "We've missed you": "Tu nous as manqué",
            Title: "Title Fr",
            Subtitle: "Subtitle Fr",
            ShortDescription: "Short description Fr",
            Description: "Description Fr",
            ApprovalsCompliancesNote: "Approvals & Compliances note Fr",
            SeoTitle: "Seo Title Fr",
            SeoDescription: "Seo Description Fr",
            SeoKeywords: "Seo Keywords Fr",
            TechnicalSpecificationsNote: "Technical specifications Note Fr",
            City: "City Fr",
            Additional: "Additional Fr",
            Link: "Link Fr",
            Eplan: "Eplan Fr",
            CadCae: "CAD & CAE fr",
            CadFileLink: "Cad File Link Fr",
            SpiceModel: "Spice Model Fr",
            Value: "Value Fr",
            Type: "Type Fr",
            CompaniesTitleListView: "Fournisseurs"
        },
    },
    de: {
        translation: {
            Search: "Suchen…",
            "Welcome back": "Willkommen zurück",
            "We've missed you": "Wir haben dich vermisst",
            Title: "Title De",
            Subtitle: "Subtitle De",
            ShortDescription: "Short description De",
            Description: "Description De",
            ApprovalsCompliancesNote: "Approvals & Compliances note De",
            SeoTitle: "Seo Title De",
            SeoDescription: "Seo Description De",
            SeoKeywords: "Seo Keywords De",
            TechnicalSpecificationsNote: "Technical specifications Note De",
            City: "City De",
            Additional: "Additional De",
            Link: "Link De",
            Eplan: "Eplan De",
            CadCae: "CAD & CAE De",
            CadFileLink: "Cad File Link De",
            SpiceModel: "Spice Model De",
            Value: "Value De",
            Type: "Type De",
            CompaniesTitleListView: "Lieferanten"
        },
    },
    nl: {
        translation: {
            Search: "Zoeken…",
            "Welcome back": "Welkom terug",
            "We've missed you": "We hebben je gemist",
            Title: "Title Nl",
            Subtitle: "Subtitle Nl",
            ShortDescription: "Short description Nl",
            Description: "Description Nl",
            ApprovalsCompliancesNote: "Approvals & Compliances note Nl",
            SeoTitle: "Seo Title Nl",
            SeoDescription: "Seo Description Nl",
            SeoKeywords: "Seo Keywords Nl",
            TechnicalSpecificationsNote: "Technical specifications Note Nl",
            City: "City Nl",
            Additional: "Additional Nl",
            Link: "Link Nl",
            Eplan: "Eplan Nl",
            CadCae: "CAD & CAE Nl",
            CadFileLink: "Cad File Link Nl",
            SpiceModel: "Spice Model Nl",
            Value: "Value Nl",
            Type: "Type Nl",
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});
