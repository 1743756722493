import "react-app-polyfill/stable";
import "typeface-cormorant";

import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import { ThemeProvider } from "./contexts/ThemeContext";

import {setContext} from "@apollo/client/link/context";
import {ApolloClient, ApolloProvider, DefaultOptions, InMemoryCache} from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import './assets/scss/style.scss';
import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {

    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only'
    },
    query: {
        fetchPolicy: 'network-only'
    }
};

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions
});

ReactDOM.render(
  <ApolloProvider client={client}>
      <BrowserRouter>
          <ThemeProvider>
              <App />
          </ThemeProvider>
      </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
