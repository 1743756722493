import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoArticle from "./fieldGroups/GeneralInfoArticle";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    ArticleQuery,
    CreateArticleMutation, InlineMediaUploadArticleMutation, NewArticleQuery,
    useArticleQuery, useCreateArticleMutation, useInlineMediaUploadArticleMutation,
    useNewArticleQuery, useResetArticleMutation,
    useUpdateArticleMutation
} from "../../graphql/article/article.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    articleNo: Yup.string().required("Required"),
});

const Article: React.FC = () => {
    let title: string = 'New article'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'files'];
    const navigate = useNavigate();
    const model: string = 'Article';
    let fromMediaCenter: boolean = false;
    const location: any = useLocation();

    // Queries
    const {data, error, loading} = useArticleQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: ArticleQuery) => {
            setImages(data.article.images);
            setMediaItems(data.article.images?.items);

            setFiles(data.article.files);
            setFilesItems(data.article.files?.items);

            setUserId(data.user.user?.id);

            if (data.article && data.article.article) {
                setState(data.article.article);
                setInicialValue({
                    "data": data.article.article,
                    "images": data.article.images?.items,
                    "files": data.article.files?.items,
                });
            }
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewArticleQuery({
        variables: {
            fields: inlineModels,
            model
        },
        skip: id !== '',
        onCompleted: (data: NewArticleQuery) => {
            setState({});
            setInicialValue({});
            setUserId(data.user.user?.id);
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'files':
                            setFiles(item.inlineMedia);
                            break;
                        default:
                    }
                    return item
                })
            }
        }
    });
    const [updateArticle] = useUpdateArticleMutation();
    const [createArticle] = useCreateArticleMutation();
    const [inlineMediaUpload] = useInlineMediaUploadArticleMutation();
    const [resetArticle] = useResetArticleMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.article ? dataObj.article.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.article ? dataObj.article.images.items : []);
    const [files, setFiles] = useState<any>(dataObj.article ? dataObj.article.files : {});
    const [filesItems, setFilesItems] = useState<any>(dataObj.article ? dataObj.article.files.items : []);
    const [userId, setUserId] = useState<any>();
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(location.state?.fromMediaCenter ? 1 : 0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);


    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.article && dataObj.article.article) {
        title = dataObj.article.article.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveArticle = (fieldName?: any, fieldType?: any, isMultiple?: any) => {
        if (id !== '') {
            updateArticleMutation(state, fieldName, fieldType, isMultiple);
        } else {
            createArticleMutation(state, fieldName, fieldType, isMultiple);
        }
        setInicialValue({
            "data": state, "images": mediaItems, "files": filesItems
        });
    }

    // Update article mutation
    const updateArticleMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean) => {

        updateArticle({
            variables: {
                model,
                id,
                data,
                images: mediaItems,
                files: filesItems,
                removedMedia,
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)
            if (fromMediaCenter) {
                navigate(`/mediacenter/`, {
                    replace: true,
                    state: {fieldName, fieldType, isMultiple, modelName: model, modelId: id}
                })
            }

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Create article mutation
    const createArticleMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean) => {
        createArticle({
            variables: {
                model,
                data,
                user: userId,
                images: mediaItems,
                files: filesItems,
                removedMedia,
            },
        })
            .then((res: FetchResult<CreateArticleMutation>) => {
                if (res.data && res.data.createArticle.article) {
                    setUpdated(true);
                    if (fromMediaCenter) {
                        navigate(`/mediacenter/`, {
                            replace: true,
                            state: {
                                fieldName,
                                fieldType,
                                isMultiple,
                                modelName: model,
                                modelId: res.data.createArticle.article.id
                            }
                        })
                    } else {
                        navigate(`/articles/${res.data.createArticle.article.id}`, {replace: true})
                    }

                }
            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        resetArticle({
            variables: {
                model,
                id
            }
        }).then(r => {
            if (!isLang) {
                navigate(`/articles`, {replace: true})
            } else {
                navigate(`/articles/${lang}/${id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "files": filesItems
        });
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "files": filesItems
        });
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/articles/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'files',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id === updateData.id);
        mediaItems[foundIndex] = updateData;
        setMediaItems(mediaItems)
    }
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id === updateData.id);
        filesItems[foundIndex] = updateData;
        setFilesItems(filesItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    //Brows media
    const onMediaBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveArticle('images', images.fieldType, images.isMultiple);
    }
    const onFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveArticle('files', files.fieldType, files.isMultiple);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;


        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }

        }

        // Update select value
        setState({...state, [fieldName]: value});

    };


    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Article"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {id &&
                            <div>
                                <Chip className="lang-nav active" variant="outlined" avatar={<Avatar src={england}/>}
                                      label="En"/>
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {
                                    openTranslateDialog('de')
                                }} clickable={true} avatar={<Avatar src={germany}/>} label="De"/>
                            </div>
                        }
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveArticle}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Media" {...tabsProps(1)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onMediaBrowseHandler}
                                                        languages={images.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Files
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={files.allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onFilesBrowseHandler}
                                                        languages={files.languages}/>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                </TabPanel>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }

            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Article;
