import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import * as events from "events";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const frequencies: { id: string, title: string, pattern: string }[] = [
    {id: 'minute', title: 'Jede Minute', pattern: '* * * * *'},
    {id: 'hour', title: 'Jede Stunde', pattern: '0 * * * *'},
    {id: 'day', title: 'Jeden Tag', pattern: '0 0 * * *'},
    {id: 'custom', title: 'Benutzerdefiniert', pattern: ''},
    {id: 'manual', title: 'Manueller Läufer', pattern: ''},
]


const GeneralInfoApproval: React.FC<FieldsGroupType> = (props) => {
    const {data, state, values, touched, errors, onChange} = props;

    const entities: [{ id: number, model: string }] = data.entities ? data.entities : [];
    // States
    const [status, setStatus] = useState<boolean>(values.hidden?.length == 0 || !values.hidden ? false : values.hidden);
    const [selectedFrequency, setSelectedFrequency] = useState<string>(values.frequency ? values.frequency : '');
    const [selectedCroneSchedule, setSelectedCroneSchedule] = useState<string>(values.cronSchedule ? values.cronSchedule : '');
    const [selectedEntity, setSelectedEntity] = useState<string>(values.entity ? values.entity : '');
    const [selectedImportSource, setSelectedImportSource] = useState<string>(values.importSource ? values.importSource : 'file');


    //------------------------------- Public methods -------------------------------------

    // update Status
    const handleStatusChange = (event: any) => {
        setStatus(event.target.checked);
    };

    // update Frequency
    const handleFrequencyChange = (event: any) => {
        const crone: string = frequencies.find(item => item.id === event.target.value)?.pattern!
        const element: any = document.getElementById("cronSchedule");
        const lastValue = element?.value;
        const ev = new Event("input", {bubbles: true});
        const tracker = element._valueTracker;

        setSelectedFrequency(event.target.value);

        element.value = crone;

        if (tracker) {
            tracker.setValue(lastValue);
        }

        element?.dispatchEvent(ev);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="hidden"
                                checked={status}
                                onChange={(e) => {
                                    handleStatusChange(e);
                                    onChange(e)
                                }}
                            />
                        } label="Auftrag deaktivieren"/>
                    </FormGroup>
                </Grid>

                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Entität</InputLabel>

                        <Select
                            name="entity"
                            value={selectedEntity}
                            label="Entität"
                            error={Boolean(touched.entity && errors.entity)}
                            onChange={(event: any) => {
                                setSelectedEntity(event.target.value);
                                onChange(event)
                            }}
                            readOnly={data.import !== undefined ?? true}
                            disabled={data.import !== undefined ?? true}
                        >
                            {entities.map((item: { id: number, model: string }) => (
                                <MenuItem key={item.id} value={item.model}>
                                    {item.model}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="title"
                        label='Titel'
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>

                <Grid item xs={12} md={1} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sortierung"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>
            </Grid>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Frequenz</InputLabel>
                        <Select
                            name="frequency"
                            value={selectedFrequency}
                            label="Frequenz"
                            onChange={(event: any) => {
                                handleFrequencyChange(event);
                                onChange(event, undefined, frequencies.find(item => item.id === event.target.value)?.pattern)
                            }}
                        >
                            {frequencies.map((item: { id: string, title: string, pattern: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        id="cronSchedule"
                        name="cronSchedule"
                        label='Cron-Zeitplan'
                        value={selectedCroneSchedule}
                        error={Boolean(touched.cronSchedule && errors.cronSchedule)}
                        fullWidth
                        helperText={touched.cronSchedule && errors.cronSchedule}
                        onChange={(event: any) => {
                            setSelectedCroneSchedule(event.target.value);
                            onChange(event)
                        }}
                        InputProps={{
                            readOnly: selectedFrequency !== 'custom',
                            disabled: selectedFrequency !== 'custom',
                        }}
                        variant="outlined"/>
                </Grid>
            </Grid>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h6" mb={1}>
                        Datei-Daten-Informationen
                    </Typography>
                </Grid>

                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Quelle importieren</InputLabel>
                        <Select
                            name="importSource"
                            value={selectedImportSource}
                            label="Quelle importieren"
                            onChange={(event: any) => {
                                setSelectedImportSource(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="file" value="file">
                                Datei
                            </MenuItem>
                            <MenuItem key="url" value="url">
                                Url
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


export default GeneralInfoApproval;
