import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox
} from "@mui/material";
import {useTranslation} from "react-i18next";

// Types
import {FieldsGroupType} from "../../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const ApprovalMultiEditTranslate: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange, state, multiEdit} = props;
    const { t } = useTranslation();

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label='Titel Fr'
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitleDe"
                                    checked={state.clearTitleDe ? state.clearTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="linkDe"
                        label='Link Fr'
                        value={values.linkDe ? values.linkDe : ''}
                        error={Boolean(touched.linkDe && errors.linkDe)}
                        fullWidth
                        helperText={touched.linkDe && errors.linkDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearLinkDe"
                                    checked={state.clearLinkDe ? state.clearLinkDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={12} mb={5}>
                    <TextField
                        label='Beschreibung Fr'
                        name="descriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.descriptionDe ? values.descriptionDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescriptionDe"
                                    checked={state.clearDescriptionDe ? state.clearDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>
        </React.Fragment>
    );
}


export default ApprovalMultiEditTranslate;
