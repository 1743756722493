import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Button,
    Grid, Menu, MenuItem,
    Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import ListViewTable from "../../components/ListViewTable";
import {AdditionalContext} from "./store/additional-context";

// Types
import {HeadCell} from "../../../types/headCell";
import {Add as AddIcon, FileDownload, MoreHoriz} from "@mui/icons-material";
import {Settings} from "react-feather";
import ListViewTableSettings from "../../components/ListViewTableSettings";


function Additional() {
    const additionalCtx = useContext(AdditionalContext);

    // Set table headers data
    const headCells: Array<HeadCell> = additionalCtx.headers;
    const model = 'Additional';
    const tableSettings = additionalCtx.getTableSettings!

    // States
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openSettings, setOpenSettings] = React.useState(false);

    //------------------------------- Public methods -------------------------------------

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Open setting modal
    const onSettingsHandel = (): void => {
        setOpenSettings(true);
    }

    // Close settings modal
    const toggleSettingsHandler = () => {
        setOpenSettings(!openSettings);
    }

    // Update settings
    const onSettingsUpdateHandel = (data: any): void => {
        if (additionalCtx.setTableSettings) {
            additionalCtx.setTableSettings(data);
        }
        setOpenSettings(false);
    }

    return (
        <React.Fragment>
            <Helmet title="Additional"/>
            <Grid item xs={12} mb={10}>
                <Typography variant="h1" gutterBottom display="inline">
                    Zusätzliche Attribute
                </Typography>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={additionalCtx.search} onSearch={additionalCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={additionalCtx.addItem} startIcon={<AddIcon/>}>
                            Neues Attribute
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (additionalCtx.exportCsv) {
                                    additionalCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV-Export Gefahrengut
                            </MenuItem>
                            

                            <MenuItem
                                onClick={(event: any) => onSettingsHandel()}
                                disableRipple>
                                <Settings/>
                                Settings
                            </MenuItem>
                        </Menu>
                    </div>
                </Grid>
            </Grid>


            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={additionalCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={additionalCtx.removeItem}
                    onSelectHandler={additionalCtx.selectItem}
                    onPaginateHandler={additionalCtx.onPaginate}
                    onSortHandler={additionalCtx.onSort}
                    onDataUpdate={additionalCtx.onDataUpdate}
                    count={additionalCtx.count}
                    page={additionalCtx.page}
                    sorting={additionalCtx.sorting}
                    onMultiEditHandler={additionalCtx.multiSelected}
                    selectedArray = {additionalCtx.selected}
                    rowsPerPage={additionalCtx.rowsPerPage}
                    model={model}
                />
            </Grid>

            <ListViewTableSettings
                open={openSettings}
                handleClose={toggleSettingsHandler}
                items={tableSettings}
                selectedItems={headCells}
                updateSettings={onSettingsUpdateHandel}
            />
        </React.Fragment>
    );
}

export default Additional;
