import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    FormControl,
    InputLabel,
    Checkbox,
    Autocomplete, FormControlLabel, FormGroup, Select, MenuItem,
} from "@mui/material";

import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {
  min-height: 200px;
}`;


const GeneralInfoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;



    // States
    const [description, setDescription] = useState(state.description ? state.description : (values.description ? values.description : ''));
    const [descriptionDe, setDescriptionDe] = useState(state.descriptionDe ? state.descriptionDe : (values.descriptionDe ? values.descriptionDe : ''));

    const [valueExportToErp, setValueExportToErp] = useState<boolean>(values.exportToErp?.length == 0 || !values.exportToErp ? false : values.exportToErp);
    const [valueExportToWebshop, setValueExportToWebshop] = useState<boolean>(values.exportToWebshop?.length == 0 || !values.exportToWebshop ? false : values.exportToWebshop);


    //------------------------------- Public methods -------------------------------------


    // Update description on change
    const handleChangeDescription = (event: any) => {
        event.toString('html');
        setDescription(event);
    };

    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setDescriptionDe(event);
    };

    const handleExportToErpChange = (event:any) => {
        setValueExportToErp(event.target.checked);
    };

    const handleExportToWebshopChange = (event:any) => {
        setValueExportToWebshop(event.target.checked);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="title"
                        label={multiEdit ? 'Titel' : 'Titel *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitle"
                                    checked={state.clearTitle ? state.clearTitle : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="titleDe"
                        label='Titel FRS'
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitleDe"
                                    checked={state.clearTitleDe ? state.clearTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={1} mb={5}>
                    <TextField
                        name="code"
                        label="Code"
                        value={values.code ? values.code : ''}
                        error={Boolean(touched.code && errors.code)}
                        fullWidth
                        helperText={touched.code && errors.code}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCode"
                                    checked={state.clearCode ? state.clearCode : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={1} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sortieren"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"

                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSorting"
                                    checked={state.clearSorting ? state.clearSorting : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportToErp"
                                checked={valueExportToErp}
                                onChange={(e) =>{handleExportToErpChange(e); onChange(e)} }
                            />
                        } label="Export zum ERP"/>
                    </FormGroup>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearExportToErp"
                                    checked={state.clearExportToErp ? state.clearExportToErp : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportToWebshop"
                                checked={valueExportToWebshop}
                                onChange={(e) =>{handleExportToWebshopChange(e); onChange(e)} }
                            />
                        } label="Export zum Webshop"/>
                    </FormGroup>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearExportToWebshop"
                                    checked={state.clearExportToWebshop ? state.clearExportToWebshop : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitle"
                        label="Untertitel"
                        value={values.subtitle ? values.subtitle : ''}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitle"
                                    checked={state.clearSubtitle ? state.clearSubtitle : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitleDe"
                        label='Untertitel FRS'
                        value={values.subtitleDe ? values.subtitleDe : ''}
                        error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                        fullWidth
                        helperText={touched.subtitleDe && errors.subtitleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitleDe"
                                    checked={state.clearSubtitleDe ? state.clearSubtitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Beschreibung</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={(e) => {
                                handleChangeDescription(e);
                                onChange(e, 'description')
                            }}
                        />
                    </QuillWrapper>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescription"
                                    checked={state.clearDescription ? state.clearDescription : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Beschreibung FRS</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={descriptionDe}
                            onChange={(e) => {
                                handleChangeDescriptionDe(e);
                                onChange(e, 'descriptionDe')
                            }}
                        />
                    </QuillWrapper>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescriptionDe"
                                    checked={state.clearDescriptionDe ? state.clearDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoCategory;
