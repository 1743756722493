import React, {ChangeEvent, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import TabPanel from "../components/TabPanel";
import GeneralInfoMediacenter from "./fieldGroups/GeneralInfoMediacenter";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    CategoryQuery,
    CreateCategoryMutation, NewCategoryQuery,
    useCategoryQuery, useInlineMediaUploadMutation,
    useNewCategoryQuery,
    useUpdateCategoryMutation
} from "../../graphql/category/category.graphql-gen";
import {
    useCreateFolderMutation, CreateFolderMutation, useFolderQuery, FolderQuery, useUpdateFolderMutation
} from "../../graphql/mediacenter/mediacenter.graphql-gen";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Erforderlich"),
});

const Folder: React.FC = () => {
    let title: string = 'Neuer Ordner'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const location: any = useLocation();

    const isNewFolder: boolean = params.id ? false : true;
    // if(location){
    //     const id = location.state.id ? location.state.id : '';
    // }


    // Queries
    const {data: data, error: error, loading: loading} = useFolderQuery({
        variables: {id},
        skip: isNewFolder,
        onCompleted: (data: FolderQuery) => {
            if (data.getFolder && data.getFolder.folder) {
                setState(data.getFolder.folder);
                setInicialValue({
                    "data": data.getFolder.folder
                });
                setParentId(data.getFolder.folder.parent)
            }
        }
    });

    const [updateFolder] = useUpdateFolderMutation();
    const [createFolder] = useCreateFolderMutation();


    // States
    const [updated, setUpdated] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [message, setMessage] = useState<string>('Saved successfully');
    const [messageType, setMessageType] = useState<any>('success');
    const [parentId, setParentId] = useState<any>('');

    const [userId, setUserId] = useState<any>();

    useEffect(() => {
        if (isNewFolder) {
            setState({});
        }
    }, []);


    // Set loading
    if (loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    //Set data
    if (data) {
        dataObj = data
    }


    // Set title
    if (dataObj.getFolder) {
        title = 'Edit folder ' + dataObj.getFolder.folder.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveCategory = () => {
        if (id != '') {
            updateFolderMutation(state);
        } else {
            createFolderMutation(state);
        }
        setInicialValue({"data": state});
    }

    // Update category mutation
    const updateFolderMutation = (data: any) => {

        updateFolder({
            variables: {
                id,
                data
            }
        }).then(r => {
            setMessage(r.data?.updateFolder.errorMessage ? r.data?.updateFolder.errorMessage : 'Erfolgreich gespeichert');
            setMessageType(r.data?.updateFolder.errorMessage ? 'error' : 'success')

            setUpdated(true);
            setTimeout(() => {
                setUpdated(false);
            }, 3000)


        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }


    // Create category mutation
    const createFolderMutation = (data: any) => {
        createFolder({
            variables: {
                data,
                parent: location.state.id
            },
        })
            .then((res: FetchResult<CreateFolderMutation>) => {
                setMessage(res.data?.createFolder.errorMessage ? res.data?.createFolder.errorMessage : 'Erfolgreich gespeichert');
                setMessageType(res.data?.createFolder.errorMessage ? 'error' : 'success');
                setUpdated(true);
                setTimeout(() => {
                    setUpdated(false);
                }, 3000)
                if (res.data && res.data.createFolder.folder) {
                    setUpdated(true);
                    navigate(`/mediacenter/folder/${res.data.createFolder.folder.id}`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}})
                }
            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        if(parentId){
            navigate(`/mediacenter/folder/${parentId}`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}})
        }else{
            navigate(`/mediacenter`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}})
        }
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state
        });
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }


    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            if (event.target) {
                fieldName = event.target.name;
                value = event.target.value;
            }

            if (field) {
                fieldName = field
                value = event
            }
        }
        // Update select value
        setState({...state, [fieldName]: value});

    };


    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <React.Fragment>
            <Helmet title="Folder"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveCategory}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>

                                                    <GeneralInfoMediacenter
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />


                                                </TabPanel>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }

            {updated &&
                <SnackbarNotification message={message} open={updated} type={messageType}/>
            }

            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Folder;
