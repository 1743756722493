import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// Icons
import {ChevronDown, ChevronRight} from "react-feather";

const Tree: React.FC<{ data: any, checked: [], expanded: [], onCheck: any, onExpand: any }> = (props) => {
    const {checked, expanded, onCheck, onExpand} = props;

    return (
        <CheckboxTree
            checked={checked}
            expanded={expanded}
            noCascade
            nativeCheckboxes
            showNodeIcon={false}
            nodes={props.data}
            onCheck={onCheck}
            onExpand={onExpand}
            icons={{
                halfCheck: '',
                expandClose: <ChevronRight className="rct-icon rct-icon-expand-close"/>,
                expandOpen: <ChevronDown className="rct-icon rct-icon-expand-open"/>,
                parentClose: '',
                parentOpen: '',
                leaf: ''
            }}
        />
    );

}

export default Tree;