import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {Formik} from "formik";

import {
    Alert as MuiAlert,
    Button,
    TextField as MuiTextField,
} from "@mui/material";
import {spacing} from "@mui/system";


import {useResetProfilePasswordMutation, useSignOutMutation, useUserQuery} from "../../graphql/auth/auth.graphql-gen";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ResetProfilePassword() {
    const navigate = useNavigate();

    const {data, error} = useUserQuery({
        variables: {model: 'User'}
    });
    const [resetProfilePassword] = useResetProfilePasswordMutation();
    const [logoutUser] = useSignOutMutation();

    const [userEmail, setUserEmail] = useState<string>( '' );
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (data && data.user && data.user.user && data.user.user.email) {
            setUserEmail(data.user.user.email);
        }
    }, [data]);

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    return (
        <Formik
            initialValues={{
                oldPassword: "",
                password: "",
                confirmPassword: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                oldPassword: Yup.string()
                    .required('Altes Passwort ist erforderlich')
                    .min(8, 'Altes Passwort sollte mindestens 8 Zeichen lang sein'),
                password: Yup.string()
                    .required('Passwort ist erforderlich')
                    .min(8, 'Das Passwort sollte mindestens 8 Zeichen lang sein.'),
                confirmPassword: Yup.string()
                    .required('Passwortbestätigung ist erforderlich')
                    .oneOf([Yup.ref('password')], 'Passwörter müssen und sollten übereinstimmen')

            })}
            onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                try {
                    await resetProfilePassword({
                        variables: {password: values.password, oldPassword: values.oldPassword, email:userEmail}
                    }).then(res => {
                        if (res.data && res.data.resetProfilePassword && res.data.resetProfilePassword.error) {
                            setErrorMessage(res.data.resetProfilePassword.error);
                        }else{
                            logoutUser();
                            localStorage.clear();
                            navigate("/auth/login");
                        }

                    });
                }catch (error: any) {
                    const message = error.message || "Etwas ist schief gelaufen";
                    setStatus({success: false});
                    setErrors({submit: message});
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}
                    {errorMessage && (
                        <Alert mt={2} mb={1} severity="warning">
                            {errorMessage}
                        </Alert>
                    )}
                    <TextField
                        type="password"
                        name="oldPassword"
                        label="Aktuelles Passwort"
                        value={values.oldPassword}
                        error={Boolean(touched.oldPassword && errors.oldPassword)}
                        fullWidth
                        helperText={touched.oldPassword && errors.oldPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <TextField
                        type="password"
                        name="password"
                        label="Passwort"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Bestätigen Sie das Passwort"
                        value={values.confirmPassword}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        fullWidth
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        Passwort speichern
                    </Button>
                </form>
            )}
        </Formik>
    );
}

export default ResetProfilePassword;
