import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import SnackbarNotification from "../../../components/SnackbarNotification";
import InlineMedia from "../../../components/inline-media/InlineMedia";
import TabPanel from "../../components/TabPanel";

// GraphQL
import {FetchResult, QueryResult, useLazyQuery} from "@apollo/client";
import {
    CreateImportMutation,
    ImportQuery,
    InlineMediaUploadImportMutation,
    MappedFieldsQuery,
    MappedFieldsQueryResult,
    NewImportQuery,
    useCreateImportMutation,
    useImportQuery,
    useInlineMediaUploadImportMutation,
    useMappedFieldsLazyQuery,
    useNewImportQuery,
    useResetImportMutation,
    useRunImportMutation,
    useUpdateImportMutation,
    useValidateFileImportMutation
} from "../../../graphql/settings/import/import.graphql-gen";

// Types
import {MediaItemType} from "../../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";
import {PlayArrow} from "@mui/icons-material";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import GeneralInfoImport from "./fieldGroups/GeneralInfoImport";
import FileFromUrlImport from "./fieldGroups/FileFromUrImport";
import FieldsMappingImport from "./fieldGroups/FieldsMappingImport";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    entity: Yup.string().required("Required"),
});

const Import: React.FC = () => {
    let title: string = ' Neue Einfuhr'
    let dataObj: any = {};
    const params = useParams();
    let id = params.id ? params.id : '';
    const inlineModels: string[] = ['file'];
    const navigate = useNavigate();
    const model: string = 'Import';
    let fromMediaCenter: boolean = false;


    // Queries
    const {data: data, error: error, loading: loading, refetch: referchImportQuery} = useImportQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: ImportQuery) => {
            setFile(data.import.file);
            setFileItems(data.import.file?.items);
            if (data.import && data.import.import) {
                setState(data.import.import);
                setInicialValue({
                    "data": data.import.import,
                    "file": data.import.file?.items
                });
            }
            setUserId(data.user.user?.id);

            if (data.import && data.import.import?.importSource === 'url') {
                setShowInlineMedia(false);
            }
            if (data.mappedFields) {
                setMappedFields(data.mappedFields);

                if (data.mappedFields.length > 0) {
                    setCanRun(true);
                }
            }
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewImportQuery({
        variables: {
            fields: inlineModels,
        },
        skip: id !== '',
        onCompleted: (data: NewImportQuery) => {
            setState({importSource: 'file'});
            setInicialValue({});
            setUserId(data.user.user?.id);
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'file':
                            setFile(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [mappedFieldsData] = useMappedFieldsLazyQuery()
    const [updateImport] = useUpdateImportMutation();
    const [createImport] = useCreateImportMutation();
    const [inlineMediaUpload] = useInlineMediaUploadImportMutation();
    const [resetImport] = useResetImportMutation();
    const [validateFileImport] = useValidateFileImportMutation();
    const [runImport, { data: runData, loading:runLoading, error:errorRun }] = useRunImportMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [verified, setVerified] = useState(false);
    const [saveFirst, setSaveFirst] = useState(false);
    const [file, setFile] = useState<any>(dataObj.import ? dataObj.import.file : {});
    const [fileItems, setFileItems] = useState<any>(dataObj.import ? dataObj.import.file.items : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [userId, setUserId] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);
    const [showInlineMedia, setShowInlineMedia] = useState<boolean>(true);
    const [mappedFields, setMappedFields] = useState<any[]>(dataObj.mappedFields ? dataObj.mappedFields : []);
    const [canRun, setCanRun] = useState<boolean>(false);


    // Set loading
    if (loading || loadingNew || loader || runLoading) {
        return (
            <React.Fragment>
                <Loader/>

                {runLoading &&
                    <Typography variant="subtitle1" align="center" className="loader-message">
                        Bitte warten Sie, dies kann ein paar Minuten dauern...
                    </Typography>
                }
            </React.Fragment>
        )
    }

    // Handle error
    if (error || errorNew || errorRun) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.import && dataObj.import.import) {
        title = dataObj.import.import.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveImport = (fieldName?: any, fieldType?: any, isMultiple?: any) => {
        if (id !== '') {
            updateImportMutation(state, fieldName, fieldType, isMultiple);
        } else {
            createImportMutation(state, fieldName, fieldType, isMultiple);
        }
        setInicialValue({
            "data": state, "file": fileItems
        });
    }

    // Update approval mutation
    const updateImportMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean) => {
        updateImport({
            variables: {
                model,
                id,
                data,
                file: fileItems,
                removedMedia,
                temp: fromMediaCenter,
                mappedFields: mappedFields,
            }
        }).then(r => {
            referchImportQuery().then(res => {
                setState({...state, ['validImportFile']: res.data.import.import?.validImportFile})
            });
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

            if (fromMediaCenter) {
                navigate(`/mediacenter/`, {
                    replace: true,
                    state: {fieldName, fieldType, isMultiple, modelName: model, modelId: id}
                })
            }

            setCanRun(true);

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }

    // Create approval mutation
    const createImportMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean) => {
        createImport({
            variables: {
                model,
                data,
                user: userId,
                file: fileItems,
                removedMedia,
                temp: fromMediaCenter
            },
        })
            .then((res: FetchResult<CreateImportMutation>) => {
                if (res.data && res.data.createImport.import) {
                    setUpdated(true);
                    if (fromMediaCenter) {
                        navigate(`/mediacenter/`, {
                            replace: true,
                            state: {
                                fieldName,
                                fieldType,
                                isMultiple,
                                modelName: model,
                                modelId: res.data.createImport.import.id
                            }
                        })
                    } else {
                        navigate(`/settings/imports/${res.data.createImport.import.id}`, {replace: true})
                    }

                }
            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        resetImport({
            variables: {
                model,
                id
            }
        }).then(r => {
            if (!isLang) {
                navigate(`/settings/imports`, {replace: true})
            } else {
                navigate(`/settings/imports/${lang}/${id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }

    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state, "file": fileItems});
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // Add file
    const onAddFileHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'file',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadImportMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFileItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        })
    }

    // Update media
    const onFileUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = fileItems.findIndex((item: MediaItemType) => item.id === updateData.id);
        fileItems[foundIndex] = updateData;
        setFileItems(fileItems)
    }

    // Remove media
    const onFileRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFileItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onFileBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveImport('file', file.fieldType, file.isMultiple);
    }

    // import file validate
    const onFileValidateHandler = () => {

        if (removedMedia.length > 0) {
            setSaveFirst(true);

            setTimeout(function () {
                setSaveFirst(false);
            }, 3000)

            return;
        }

        validateFileImport({
            variables: {
                model,
                id,
            },
        }).then((response: any) => {
            if (response.data.validateFileImport) {
                mappedFieldsData({
                    variables: {id, model},
                }).then((res: any) => {
                    if (res.data) {
                        setMappedFields(res.data.mappedFields);

                        if (mappedFields.length > 0) {
                            setCanRun(true);
                        }

                        setState({...state, ['validImportFile']: true})
                    }
                })

                return;
            }

            setVerified(true);

            setTimeout(function () {
                setVerified(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string | null, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;
        let additionalField: string = '';
        let additionalValue: string | undefined;

        if (event.target) {
            fieldName = event.target.name;

            // set new value
            value = event.target.value;

            if (field) {
                fieldName = field
                value = event
            }

            switch (fieldName) {
                case 'hidden':
                    // @ts-ignore
                    value = event.target.checked;
                    break;
                case 'importSource':
                    if (value === 'url') {
                        setShowInlineMedia(false);
                    } else {
                        setShowInlineMedia(true);
                    }
                    break;
                case 'frequency':
                    additionalField = 'cronSchedule'
                    additionalValue = type;
                    break;
                default:
            }
        }

        // Update select value
        if (additionalField !== '') {
            setState({...state, [fieldName]: value, [additionalField]: additionalValue});
        } else {
            setState({...state, [fieldName]: value});
        }
    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // run import
    const onRunImport = (): void => {
        runImport({
            variables: {
                id,
                model,
            },
        }).then(res => {
            console.log('import finished');
        }).catch(error => {
            return <SnackbarNotification message="Importfehler!." open={true} type={'error'}/>
        });
    }

    const fieldsMappingHandler = (data: any) => {
        setMappedFields(data);
    }

    return (
        <React.Fragment>
            <Helmet title="Import"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>


                        {canRun &&
                            <Button variant="contained" color="secondary" onClick={onRunImport}>
                                <PlayArrow/>
                            </Button>
                        }
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveImport}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="Daten importieren" {...tabsProps(0)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoImport
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Datei
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {showInlineMedia &&
                                                        <InlineMedia
                                                            items={fileItems}
                                                            fieldType={file.fieldType}
                                                            allowedExtensions={file.allowedExtensions}
                                                            isMultiple={file.isMultiple}
                                                            onAdd={onAddFileHandler}
                                                            onUpdate={onFileUpdateHandler}
                                                            onDelete={onFileRemoveHandler}
                                                            isBrowseMedia={true}
                                                            browseMedia={onFileBrowseHandler}
                                                            languages={file.languages}
                                                        />
                                                    }

                                                    <FileFromUrlImport
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        additionalData={{showInlineMedia: showInlineMedia}}
                                                        onAdditionalDataChange={onFileValidateHandler}
                                                    />

                                                    {mappedFields.length > 0 &&
                                                        <FieldsMappingImport
                                                            data={dataObj}
                                                            onChange={(e, field, type, values) => {
                                                                handleChange(e);
                                                            }}
                                                            onAdditionalDataChange={(data: any) => {
                                                                fieldsMappingHandler(data)
                                                            }}
                                                            touched={touched}
                                                            errors={errors}
                                                            values={values}
                                                        />
                                                    }


                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }

            {verified &&
                <SnackbarNotification message="Bitte wählen Sie die Quelle der Importdatei." open={verified} type={'error'}/>
            }

            {saveFirst &&
                <SnackbarNotification message="Bitte speichern Sie zuerst die gespeicherten Daten" open={verified} type={'warning'}/>
            }


            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Import;
