import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import {useTranslation} from "react-i18next";
import ListViewTable from "../components/ListViewTable";
import {CompaniesContext} from "./store/companies-context";
import SearchBar from "../components/SearchBar";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Companies() {
    const companiesCtx = useContext(CompaniesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();
    const { i18n, t } = useTranslation();
    const model: string = 'Companies';

    // Set table headers data
    const headCells: Array<HeadCell> = companiesCtx.headers;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Lieferanten"/>
            <Grid item>
                <Typography variant="h1" gutterBottom display="inline">
                    Lieferanten
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={companiesCtx.search} onSearch={companiesCtx.onSearch}/>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={companiesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={companiesCtx.removeItem}
                    onSelectHandler={companiesCtx.selectItem}
                    onPaginateHandler={companiesCtx.onPaginate}
                    onSortHandler={companiesCtx.onSort}
                    onDataUpdate={companiesCtx.onDataUpdate}
                    count={companiesCtx.count}
                    page={companiesCtx.page}
                    rowsPerPage={companiesCtx.rowsPerPage}
                    sorting={companiesCtx.sorting}
                    filters={companiesCtx.filters}
                    onMultiEditHandler={companiesCtx.multiSelected}
                    selectedArray = {companiesCtx.selected}
                    readOnly = {true}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Companies;
