import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    TableContainer, Table, TableBody, TableRow, TableCell,
    Alert as MuiAlert, IconButton
} from "@mui/material";


import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";

// GraphQL
import {
    FileQuery,
    useFileQuery,
    useUpdateFileMutation
} from "../../graphql/mediacenter/mediacenter.graphql-gen";

// Icons
import {Download, Edit3 as EditIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import Avatar from "@mui/material/Avatar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {KeyboardReturn, RemoveRedEye as RemoveRedEyeIcon} from "@mui/icons-material";
import Lightbox from "react-image-lightbox";
import Popup from "./EditFileMetadataDialog";
import SnackbarNotification from "../../components/SnackbarNotification";
import {FileType} from "../../types/mediaCenter";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const File: React.FC = () => {
    let title: string = 'File'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const location: any = useLocation();
    const [parentId, setParentId] = useState<any>('');

    // Queries
    const {data: data, error: error, loading: loading} = useFileQuery({
        variables: {id},
        onCompleted: (data: FileQuery) => {
            if (data && data.getFile) {
                setParentId(data.getFile.file?.folder);
            }

            if (data && data.getFile?.file) {
                // @ts-ignore
                setFile(data.getFile.file);
            }
        }
    });

    // Mutations
    const [updateFile] = useUpdateFileMutation();

    // States
    const [loader, setLoader] = useState<boolean>(false);
    const [lightbox, setLightbox] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState<FileType>(null);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }

    //------------------------------- Public methods -------------------------------------


    //Back to parent folder
    const backToListView = () => {
        if (parentId) {
            navigate(`/mediacenter/folder/${parentId}`, {
                replace: true,
                state: {
                    fieldName: location.state?.fieldName,
                    fieldType: location.state?.fieldType,
                    isMultiple: location.state?.isMultiple,
                    modelName: location.state?.modelName,
                    modelId: location.state?.modelId,
                    modelIds: location.state?.modelIds,
                    fromMediaCenter: true,
                    submodelName: location.state?.submodelName,
                    submodelId: location.state?.submodelId
                }
            })
        } else {
            navigate(`/mediacenter`, {
                replace: true,
                state: {
                    fieldName: location.state?.fieldName,
                    fieldType: location.state?.fieldType,
                    isMultiple: location.state?.isMultiple,
                    modelName: location.state?.modelName,
                    modelId: location.state?.modelId,
                    modelIds: location.state?.modelIds,
                    fromMediaCenter: true,
                    submodelName: location.state?.submodelName,
                    submodelId: location.state?.submodelId
                }
            })
        }
    }

    // Preview image in lightbox
    const onPreview = () => {
        setLightbox(true);
    };

    // File download
    const download = (e: any, fileName: string, mimeType: string, name: string) => {
        e.preventDefault();
        fetch(fileName, {
            method: 'GET',
            headers: {
                'Content-Type': mimeType,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name,
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }

            });
    };

    // Edit metadata
    const onEditMetadata = () => {
        setIsOpen(!isOpen);
    };

    // Update metadata
    const onUpdateMetadataHandler = (data: any) => {
        updateFile({
            variables: {
                id: data.id,
                data: {
                    name: data.name,
                    altName: data.altName,
                    languages: data.languages
                },
            },
        })
            .then((res: any) => {

                console.log(data);
                setFile(data);
                togglePopup();

            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });

    };

    // Metadata edit popup
    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <Helmet title="File"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="primary" onClick={backToListView}
                                className="multi-btn" startIcon={<KeyboardReturn/>}>
                            Zurück
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {file &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className="file-show">
                                    <Grid container spacing={6} alignItems="center">
                                        <Grid item xs={2} md={1}>
                                            {file.thumbnail == ''
                                                ? <Avatar><AssignmentIcon/></Avatar>
                                                : <Avatar src={file.thumbnail}/>
                                            }
                                        </Grid>
                                        <Grid item xs={10} md={11}>
                                            {file.thumbnail == ''
                                                ? <IconButton aria-label="details" size="large"
                                                              href={file.path} target="_blank">
                                                    <RemoveRedEyeIcon/> </IconButton>
                                                : <IconButton aria-label="details" size="large"
                                                              onClick={() => onPreview()}> <RemoveRedEyeIcon/>
                                                </IconButton>
                                            }

                                            <a href={file.path}
                                               download
                                               onClick={e => download(e, file.path, file.mimeType, file.title)}
                                            >
                                                <IconButton aria-label="download" size="large">
                                                    <Download/>
                                                </IconButton>
                                            </a>
                                            <IconButton aria-label="details" size="large"
                                                        onClick={() => onEditMetadata()}> <EditIcon/> </IconButton>
                                        </Grid>
                                    </Grid>

                                    <div className="file-more">
                                        <TableContainer>
                                            <Table sx={{maxWidth: 650}} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Dateiname</TableCell>
                                                        <TableCell>{file.title}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Grösse</TableCell>
                                                        <TableCell>{file.size}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Erweiterung</TableCell>
                                                        <TableCell>{file.extension}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">MimeTyp</TableCell>
                                                        <TableCell>{file.mimeType}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Titel</TableCell>
                                                        <TableCell>{file.name}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Alt titel</TableCell>
                                                        <TableCell>{file.altName}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Sprache</TableCell>
                                                        <TableCell>{file.languages?.toString()}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }

            {lightbox && (
                <Lightbox
                    mainSrc={dataObj.getFile.file.path}
                    onCloseRequest={() => setLightbox(false)}
                />
            )}

            {isOpen && <Popup item={file} open={isOpen} handleClose={togglePopup}
                              onUpdateMetadata={onUpdateMetadataHandler}/>}
        </React.Fragment>
    );
}

export default File;
