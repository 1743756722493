import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoProduct from "./fieldGroups/GeneralInfoProduct";
import AdditionalProduct from "./fieldGroups/AdditionalProduct";
import PromotionProduct from "./fieldGroups/PromotionProduct";
import CompanyProduct from "./fieldGroups/CompanyProduct";
import VideoProduct from "./fieldGroups/VideoProduct";
import InlineContentElement from "../../components/inline-content-element/InlineContentElement";
import Tree from "../../components/tree/Tree";
import ErpProduct from "./fieldGroups/ErpProduct";
import {useTranslation} from "react-i18next";


// GraphQL
import {FetchResult} from "@apollo/client";
import {
    NewProductQuery,
    ProductQuery,
    useCreateProductMutation,
    useNewProductQuery,
    useProductQuery,
    useUpdateProductMutation,
    CreateProductMutation,
    useInlineMediaUploadProductMutation,
    InlineMediaUploadProductMutation,
    useResetProductMutation
} from "../../graphql/product/product.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";
import {TechnicalSpecificationItemType} from "../../types/inlineTechnicalSpecificationsType";
import {ContentElementItemType} from "../../types/inlineContentElementType";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import RelatedProduct from "./fieldGroups/RelatedProduct";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";
import france from "../../assets/img/france.svg";
import ApprovalsProduct from "./fieldGroups/ApprovalsProduct";
import TechnicalSpecificationProduct from "./fieldGroups/TechnicalSpecificationProduct";
import InlineTechnicalSpecifications from "./inline-technical-specifications/InlineTechnicalSpecifications";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    sku: Yup.string().required("Required"),
});

const Product: React.FC = () => {
    let title: string = 'Neues Produkt';
    const model: string = 'Product';
    let dataObj: any = {};
    const params = useParams();
    let id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'headerImages', 'files', 'tdbFiles', 'beaFiles', 'proFiles', 'sdbFiles', 'sdoFiles', 'cadFiles', 'zeiFiles', 'expFiles', 'vidFiles', 'schFiles', 'moaFiles'];
    const navigate = useNavigate();
    const multipleSelect: boolean = true;
    let fromMediaCenter: boolean = false;
    const location: any = useLocation();
    const {i18n, t} = useTranslation();

    // Queries
    const {data: data, error: error, loading: loading} = useProductQuery({
        variables: {id, model, technicalSpecificationModel: 'TechnicalSpecification'},
        skip: id === '',
        onCompleted: (data: ProductQuery) => {
            setImages(data.product.images);
            setMediaItems(data.product.images?.items);

            setHeaderImages(data.product.headerImages);
            setHeaderImagesItems(data.product.headerImages?.items);

            setFiles(data.product.files);
            setFilesItems(data.product.files?.items);

            setTdbFiles(data.product.tdbFiles);
            setTdbFilesItems(data.product.tdbFiles?.items);

            setBeaFiles(data.product.beaFiles);
            setBeaFilesItems(data.product.beaFiles?.items);

            setProFiles(data.product.proFiles);
            setProFilesItems(data.product.proFiles?.items);

            setSdbFiles(data.product.sdbFiles);
            setSdbFilesItems(data.product.sdbFiles?.items);

            setSdoFiles(data.product.sdoFiles);
            setSdoFilesItems(data.product.sdoFiles?.items);

            setCadFiles(data.product.cadFiles);
            setCadFilesItems(data.product.cadFiles?.items);

            setZeiFiles(data.product.zeiFiles);
            setZeiFilesItems(data.product.zeiFiles?.items);

            setExpFiles(data.product.expFiles);
            setExpFilesItems(data.product.expFiles?.items);

            setVidFiles(data.product.vidFiles);
            setVidFilesItems(data.product.vidFiles?.items);

            setSchFiles(data.product.schFiles);
            setSchFilesItems(data.product.schFiles?.items);

            setMoaFiles(data.product.moaFiles);
            setMoaFilesItems(data.product.moaFiles?.items);

            setContentElements(data.product.contentElements);

            setTechnicalSpecifications(data.product.technicalSpecifications);
            setTechnicalSpecificationsTypes(data.getTechnicalSpecificationSelect);

            setUserId(data.user.user?.id);

            if (data.product && data.product.product) {
                setState(data.product.product);
                setInicialValue({
                    "data": data.product.product,
                    "images": data.product.images?.items,
                    "headerImages": data.product.headerImages?.items,
                    "files": data.product.files?.items,
                    "tdbFiles": data.product.tdbFiles?.items,
                    "beaFiles": data.product.beaFiles?.items,
                    "proFiles": data.product.proFiles?.items,
                    "sdbFiles": data.product.sdbFiles?.items,
                    "sdoFiles": data.product.sdoFiles?.items,
                    "cadFiles": data.product.cadFiles?.items,
                    "zeiFiles": data.product.zeiFiles?.items,
                    "expFiles": data.product.expFiles?.items,
                    "vidFiles": data.product.vidFiles?.items,
                    "schFiles": data.product.schFiles?.items,
                    "moaFiles": data.product.moaFiles?.items,
                    "contentElements": data.product.contentElements,
                    "technicalSpecifications": data.product.technicalSpecifications,
                    "checked": data.categoriesThree.checked,
                    "checkedShop": data.categoriesThree.checkedShop
                });
            }

            setChecked(data.categoriesThree.checked);
            setCheckedShop(data.categoriesThree.checkedShop);
            setExpanded(data.categoriesThree.expanded);
            setExpandedShop(data.categoriesThree.expandedShop);
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewProductQuery({
        variables: {
            fields: inlineModels,
            model,
            technicalSpecificationModel: 'TechnicalSpecification'
        },
        skip: id !== '',
        onCompleted: (data: NewProductQuery) => {
            setState({});
            setInicialValue({});

            setUserId(data.user.user?.id);

            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'headerImages':
                            setHeaderImages(item.inlineMedia);
                            break;
                        case 'files':
                            setFiles(item.inlineMedia);
                            break;
                        case 'tdbFiles':
                            setTdbFiles(item.inlineMedia);
                            break;
                        case 'beaFiles':
                            setBeaFiles(item.inlineMedia);
                            break;
                        case 'proFiles':
                            setProFiles(item.inlineMedia);
                            break;
                        case 'sdbFiles':
                            setSdbFiles(item.inlineMedia);
                            break;
                        case 'sdoFiles':
                            setSdoFiles(item.inlineMedia);
                            break;
                        case 'cadFiles':
                            setCadFiles(item.inlineMedia);
                            break;
                        case 'zeiFiles':
                            setZeiFiles(item.inlineMedia);
                            break;
                        case 'expFiles':
                            setExpFiles(item.inlineMedia);
                            break;
                        case 'vidFiles':
                            setVidFiles(item.inlineMedia);
                            break;
                        case 'schFiles':
                            setSchFiles(item.inlineMedia);
                            break;
                        case 'moaFiles':
                            setMoaFiles(item.inlineMedia);
                            break;
                    }
                })
            }
            setTechnicalSpecificationsTypes(data.getTechnicalSpecificationSelect);
        }
    });
    const [updateProduct] = useUpdateProductMutation();
    const [createProduct] = useCreateProductMutation();
    const [inlineMediaUpload] = useInlineMediaUploadProductMutation();
    const [resetProduct] = useResetProductMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.product ? dataObj.product.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.product ? dataObj.product.images.items : []);

    const [headerImages, setHeaderImages] = useState<any>(dataObj.product ? dataObj.product.headerImages : {});
    const [headerImagesItems, setHeaderImagesItems] = useState<any>(dataObj.product ? dataObj.product.headerImages.items : []);

    const [files, setFiles] = useState<any>(dataObj.product ? dataObj.product.files : {});
    const [filesItems, setFilesItems] = useState<any>(dataObj.product ? dataObj.product.files.items : []);

    const [tdbFiles, setTdbFiles] = useState<any>(dataObj.product ? dataObj.product.tdbFiles : {});
    const [tdbFilesItems, setTdbFilesItems] = useState<any>(dataObj.product ? dataObj.product.tdbFiles.items : []);

    const [beaFiles, setBeaFiles] = useState<any>(dataObj.product ? dataObj.product.beaFiles : {});
    const [beaFilesItems, setBeaFilesItems] = useState<any>(dataObj.product ? dataObj.product.beaFiles.items : []);

    const [proFiles, setProFiles] = useState<any>(dataObj.product ? dataObj.product.proFiles : {});
    const [proFilesItems, setProFilesItems] = useState<any>(dataObj.product ? dataObj.product.proFiles.items : []);

    const [sdbFiles, setSdbFiles] = useState<any>(dataObj.product ? dataObj.product.sdbFiles : {});
    const [sdbFilesItems, setSdbFilesItems] = useState<any>(dataObj.product ? dataObj.product.sdbFiles.items : []);

    const [sdoFiles, setSdoFiles] = useState<any>(dataObj.product ? dataObj.product.sdoFiles : {});
    const [sdoFilesItems, setSdoFilesItems] = useState<any>(dataObj.product ? dataObj.product.sdoFiles.items : []);

    const [cadFiles, setCadFiles] = useState<any>(dataObj.product ? dataObj.product.cadFiles : {});
    const [cadFilesItems, setCadFilesItems] = useState<any>(dataObj.product ? dataObj.product.cadFiles.items : []);

    const [zeiFiles, setZeiFiles] = useState<any>(dataObj.product ? dataObj.product.zeiFiles : {});
    const [zeiFilesItems, setZeiFilesItems] = useState<any>(dataObj.product ? dataObj.product.zeiFiles.items : []);

    const [expFiles, setExpFiles] = useState<any>(dataObj.product ? dataObj.product.expFiles : {});
    const [expFilesItems, setExpFilesItems] = useState<any>(dataObj.product ? dataObj.product.expFiles.items : []);

    const [vidFiles, setVidFiles] = useState<any>(dataObj.product ? dataObj.product.vidFiles : {});
    const [vidFilesItems, setVidFilesItems] = useState<any>(dataObj.product ? dataObj.product.vidFiles.items : []);

    const [schFiles, setSchFiles] = useState<any>(dataObj.product ? dataObj.product.schFiles : {});
    const [schFilesItems, setSchFilesItems] = useState<any>(dataObj.product ? dataObj.product.schFiles.items : []);

    const [moaFiles, setMoaFiles] = useState<any>(dataObj.product ? dataObj.product.moaFiles : {});
    const [moaFilesItems, setMoaFilesItems] = useState<any>(dataObj.product ? dataObj.product.moaFiles.items : []);

    const [contentElements, setContentElements] = useState<any>(dataObj.product ? dataObj.product.contentElements : []);
    const [technicalSpecifications, setTechnicalSpecifications] = useState<any>(dataObj.product ? dataObj.product.technicalSpecifications : []);

    const [technicalSpecificationsTypes, setTechnicalSpecificationsTypes] = useState<any>(dataObj.product ? dataObj.product.getTechnicalSpecificationSelect : []);

    const [userId, setUserId] = useState<any>();

    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(location.state?.submodelName ? 4 : (location.state?.fromMediaCenter ? 3 : 0));

    const [checked, setChecked] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checked : []);
    const [checkedShop, setCheckedShop] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checkedShop : []);
    const [expanded, setExpanded] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.expanded : []);
    const [expandedShop, setExpandedShop] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.expandedShop : []);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);

    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.product && dataObj.product.product) {
        title = dataObj.product.product.title + ' (' + dataObj.product.product.sku + ')';
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveProduct = (fieldName?: any, fieldType?: any, isMultiple?: any, submodelId?: any, submodelName?: string) => {
        if (id != '') {
            updateProductMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        } else {
            createProductMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        }
        setInicialValue({
            "data": state, "images": mediaItems, "headerImages": headerImagesItems,
            "files": filesItems,
            "tdbFiles": tdbFilesItems,
            "beaFiles": beaFilesItems,
            "proFiles": proFilesItems,
            "sdbFiles": sdbFilesItems,
            "sdoFiles": sdoFilesItems,
            "cadFiles": cadFilesItems,
            "zeiFiles": zeiFilesItems,
            "expFiles": expFilesItems,
            "vidFiles": vidFilesItems,
            "schFiles": schFilesItems,
            "moaFiles": moaFilesItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked,
            "checkedShop": checkedShop
        });
    }

    // Update product mutation
    const updateProductMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean, submodelId?: any, submodelName?: string) => {
        updateProduct({
            variables: {
                model,
                id,
                data,
                categories: checked,
                categoriesShop: checkedShop,
                images: mediaItems,
                files: filesItems,
                tdbFiles: tdbFilesItems,
                beaFiles: beaFilesItems,
                proFiles: proFilesItems,
                sdbFiles: sdbFilesItems,
                sdoFiles: sdoFilesItems,
                cadFiles: cadFilesItems,
                zeiFiles: zeiFilesItems,
                expFiles: expFilesItems,
                vidFiles: vidFilesItems,
                schFiles: schFilesItems,
                moaFiles: moaFilesItems,
                removedMedia,
                contentElements,
                technicalSpecifications,
                temp: fromMediaCenter
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)
            if (fromMediaCenter) {
                navigate(`/mediacenter/`, {
                    replace: true,
                    state: {fieldName, fieldType, isMultiple, modelName: model, modelId: id, submodelId, submodelName}
                })
            }

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        });
    }

    // Create product mutation
    const createProductMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean, submodelId?: any, submodelName?: string) => {
        createProduct({
            variables: {
                model,
                data,
                user: userId,
                categories: checked,
                categoriesShop: checkedShop,
                images: mediaItems,
                files: filesItems,
                tdbFiles: tdbFilesItems,
                beaFiles: beaFilesItems,
                proFiles: proFilesItems,
                sdbFiles: sdbFilesItems,
                sdoFiles: sdoFilesItems,
                cadFiles: cadFilesItems,
                zeiFiles: zeiFilesItems,
                expFiles: expFilesItems,
                vidFiles: vidFilesItems,
                schFiles: schFilesItems,
                moaFiles: moaFilesItems,
                removedMedia,
                contentElements,
                technicalSpecifications,
                temp: fromMediaCenter
            },
        }).then((res: FetchResult<CreateProductMutation>) => {
            if (res.data && res.data.createProduct.product) {
                setUpdated(true);
                if (fromMediaCenter) {
                    navigate(`/mediacenter/`, {
                        replace: true,
                        state: {
                            fieldName,
                            fieldType,
                            isMultiple,
                            modelName: model,
                            modelId: res.data.createProduct.product.id,
                            submodelId,
                            submodelName
                        }
                    })
                } else {
                    navigate(`/products/${res.data.createProduct.product.id}`, {replace: true})
                }
            }
        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        });
    }

    //Back to ListView
    const backToListView = () => {
        resetProduct({
            variables: {
                model,
                id
            }
        }).then(r => {
            if (!isLang) {
                navigate(`/products`, {replace: true})
            } else {
                navigate(`/products/${lang}/${id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        });
    }
    const openDialog = () => {

        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "headerImages": headerImagesItems,
            "files": filesItems,
            "tdbFiles": tdbFilesItems,
            "beaFiles": beaFilesItems,
            "proFiles": proFilesItems,
            "sdbFiles": sdbFilesItems,
            "sdoFiles": sdoFilesItems,
            "cadFiles": cadFilesItems,
            "zeiFiles": zeiFilesItems,
            "expFiles": expFilesItems,
            "vidFiles": vidFilesItems,
            "schFiles": schFilesItems,
            "moaFiles": moaFilesItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked,
            "checkedShop": checkedShop
        });
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }

    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "headerImages": headerImagesItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked,
            "checkedShop": checkedShop
        });
        i18n.changeLanguage(lang);
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/products/${lang}/${id}`, {replace: true})
        }
    }

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName: any | string = '';
        let value: any;

        if (type) {
            fieldName = field;
            value = values
        } else {
            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };


    //------------------------ Inline media methods -------------------------------------

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...mediaItems];
        newItems[foundIndex] = updateData;
        setMediaItems(newItems);
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onMediaBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('images', images.fieldType, images.isMultiple);
    }


    // Add header images
    const onAddHeaderImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'headerImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setHeaderImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update header images
    const onHeaderImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = headerImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...headerImagesItems];
        newItems[foundIndex] = updateData;
        setHeaderImagesItems(newItems);
    }

    // Remove header images
    const onHeaderImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setHeaderImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onHeaderImagesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('headerImages', headerImages.fieldType, headerImages.isMultiple);
    }

    // Add files
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'files',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...filesItems];
        newItems[foundIndex] = updateData;
        setFilesItems(newItems);
    }

    // Remove files
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('files', files.fieldType, files.isMultiple);
    }

    const onAddTdbFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'tdbFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onTdbFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = tdbFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...tdbFilesItems];
        newItems[foundIndex] = updateData;
        setTdbFilesItems(newItems);
    }

    // Remove files
    const onTdbFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setTdbFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onTdbFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('tdbFiles', tdbFiles.fieldType, tdbFiles.isMultiple);
    }

    const onAddBeaFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'beaFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onBeaFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = beaFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...beaFilesItems];
        newItems[foundIndex] = updateData;
        setBeaFilesItems(newItems);
    }

    // Remove files
    const onBeaFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setBeaFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onBeaFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('beaFiles', beaFiles.fieldType, beaFiles.isMultiple);
    }

    const onAddProFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'proFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onProFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = proFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...proFilesItems];
        newItems[foundIndex] = updateData;
        setProFilesItems(newItems);
    }

    // Remove files
    const onProFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setProFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onProFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('proFiles', proFiles.fieldType, proFiles.isMultiple);
    }

    const onAddSdbFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'sdbFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onSdbFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = sdbFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...sdbFilesItems];
        newItems[foundIndex] = updateData;
        setSdbFilesItems(newItems);
    }

    // Remove files
    const onSdbFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setSdbFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onSdbFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('sdbFiles', sdbFiles.fieldType, sdbFiles.isMultiple);
    }

    const onAddSdoFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'sdoFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onSdoFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = sdoFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...sdoFilesItems];
        newItems[foundIndex] = updateData;
        setSdoFilesItems(newItems);
    }

    // Remove files
    const onSdoFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setSdoFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onSdoFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('sdoFiles', sdoFiles.fieldType, sdoFiles.isMultiple);
    }

    const onAddCadFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'cadFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onCadFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = cadFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...cadFilesItems];
        newItems[foundIndex] = updateData;
        setCadFilesItems(newItems);
    }

    // Remove files
    const onCadFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setCadFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onCadFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('cadFiles', cadFiles.fieldType, cadFiles.isMultiple);
    }

    const onAddZeiFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'zeiFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onZeiFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = zeiFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...zeiFilesItems];
        newItems[foundIndex] = updateData;
        setZeiFilesItems(newItems);
    }

    // Remove files
    const onZeiFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setZeiFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onZeiFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('zeiFiles', zeiFiles.fieldType, zeiFiles.isMultiple);
    }

    const onAddExpFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'expFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onExpFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = expFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...expFilesItems];
        newItems[foundIndex] = updateData;
        setExpFilesItems(newItems);
    }

    // Remove files
    const onExpFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setExpFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onExpFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('expFiles', expFiles.fieldType, expFiles.isMultiple);
    }

    const onAddVidFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'vidFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onVidFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = vidFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...vidFilesItems];
        newItems[foundIndex] = updateData;
        setVidFilesItems(newItems);
    }

    // Remove files
    const onVidFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setVidFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onVidFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('vidFiles', vidFiles.fieldType, vidFiles.isMultiple);
    }

    const onAddSchFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'schFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onSchFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = schFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...schFilesItems];
        newItems[foundIndex] = updateData;
        setSchFilesItems(newItems);
    }

    // Remove files
    const onSchFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setSchFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onSchFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('schFiles', schFiles.fieldType, schFiles.isMultiple);
    }

    const onAddMoaFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'moaFiles',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update files
    const onMoaFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = moaFilesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...moaFilesItems];
        newItems[foundIndex] = updateData;
        setMoaFilesItems(newItems);
    }

    // Remove files
    const onMoaFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMoaFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onMoaFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveProduct('moaFiles', moaFiles.fieldType, moaFiles.isMultiple);
    }


    //------------------------ Inline content element methods --------------------------

    // Add content element
    const onAddContentElementHandler = (element: ContentElementItemType) => {
        setContentElements((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update content element
    const onContentElementUpdateHandler = (updateData: ContentElementItemType) => {
        const updateContentElements = contentElements.map((el: ContentElementItemType) => {
            if (el.id == updateData.id) {
                return updateData
            } else {
                return el;
            }
        });
        setContentElements(updateContentElements);
    }

    // Remove content element
    const onContentElementRemoveHandler = (id: number | string) => {
        setContentElements((prevState: ContentElementItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Remove media from content element
    const onContentElementMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
    }

    // Brows media for content element
    const onContentElementMediaBrowseHandler = (item: any) => {
        fromMediaCenter = true;
        onSaveProduct('images', 'image', false, item.id, 'ContentElement');
    }


    //------------------------ Inline technical specifications methods --------------------------

    // Add technical option
    const onAddTechnicalSpecificationHandler = (element: TechnicalSpecificationItemType) => {
        setTechnicalSpecifications((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update technical option
    const onTechnicalSpecificationUpdateHandler = (updateData: TechnicalSpecificationItemType) => {

        console.log(updateData);

        const updateTechnicalSpecifications = technicalSpecifications.map((el: TechnicalSpecificationItemType) => {
            if (el.id == updateData.id) {
                return updateData
            } else {
                return el;
            }
        });
        setTechnicalSpecifications(updateTechnicalSpecifications);
    }

    // Remove technical option
    const onTechnicalSpecificationRemoveHandler = (id: number | string) => {
        setTechnicalSpecifications((prevState: TechnicalSpecificationItemType[]) => prevState.filter(item => item.id !== id));
    }


    //------------------------------- Tabs methods -------------------------------------

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    //--------------------------- Categories tree methods ------------------------------

    //Set tree handlerers
    const treeHandlerCheck = (checkedArr: any, targetNode: any) => {
        if (checkedArr.length != 0) {
            setChecked([targetNode.value.toString()])
        } else {
            setChecked([]);
        }
    }
    const treeHandlerExpand = (event: any) => {
        setExpanded(event)
    }

    const treeHandlerCheckShop = (checkedArr: any, targetNode: any) => {
        if (multipleSelect) {
            setCheckedShop(checkedArr)
        } else {
            if (checkedArr.length != 0) {
                setCheckedShop([targetNode.value.toString()])
            } else {
                setCheckedShop([]);
            }
        }
    }
    const treeHandlerExpandShop = (event: any) => {
        setExpandedShop(event)
    }

    return (
        <React.Fragment>
            <Helmet title="Product"/>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>
                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveProduct}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                    <Tab label="Kategorien" {...tabsProps(1)} />
                                                    <Tab label="Gefahrengut" {...tabsProps(2)} />
                                                    <Tab label="Marken & Artikel" {...tabsProps(3)} />
                                                    <Tab label="Medien" {...tabsProps(4)} />
                                                    <Tab label="Attribute" {...tabsProps(5)} />
                                                    <Tab label="ERP" {...tabsProps(6)} />
                                                    <Tab label="SEO" {...tabsProps(7)} />
                                                </Tabs>
                                            </div>

                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        checked={checked}
                                                        dataObj={dataObj}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Inhaltliche Elemente
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineContentElement
                                                        items={contentElements}
                                                        onAdd={onAddContentElementHandler}
                                                        onUpdate={onContentElementUpdateHandler}
                                                        onDelete={onContentElementRemoveHandler}
                                                        onMediaDelete={onContentElementMediaRemoveHandler}
                                                        onMediaBrowse={onContentElementMediaBrowseHandler}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={12}>
                                                        <Grid item xs={12} md={12} mb={12}>
                                                            <Grid container spacing={12}>
                                                                <Grid item xs={12}>
                                                                    <React.Fragment><span style={{
                                                                        fontSize: 13,
                                                                        color: "orange"
                                                                    }}>Shop *</span></React.Fragment>
                                                                    <Typography variant="h6" mb={5}>
                                                                        Kategorie
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Tree
                                                                data={dataObj.categoriesThree.categoriesShop}
                                                                checked={checkedShop}
                                                                expanded={expandedShop}
                                                                onCheck={treeHandlerCheckShop}
                                                                onExpand={treeHandlerExpandShop}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <AdditionalProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={3}>
                                                    <ApprovalsProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                    <Grid container spacing={6} mt={5}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <RelatedProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Bild
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onMediaBrowseHandler}
                                                        languages={images.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Kopfzeilen-Bilder
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={headerImagesItems}
                                                        fieldType={headerImages.fieldType}
                                                        allowedExtensions={headerImages.allowedExtensions}
                                                        isMultiple={headerImages.isMultiple}
                                                        onAdd={onAddHeaderImagesHandler}
                                                        onUpdate={onHeaderImagesUpdateHandler}
                                                        onDelete={onHeaderImagesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onHeaderImagesBrowseHandler}
                                                        languages={headerImagesItems.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <VideoProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Dateien
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={files.allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onFilesBrowseHandler}
                                                        languages={files.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Technische Datenblätter
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={tdbFilesItems}
                                                        fieldType={tdbFiles.fieldType}
                                                        allowedExtensions={tdbFiles.allowedExtensions}
                                                        isMultiple={tdbFiles.isMultiple}
                                                        onAdd={onAddTdbFilesHandler}
                                                        onUpdate={onTdbFilesUpdateHandler}
                                                        onDelete={onTdbFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onTdbFilesBrowseHandler}
                                                        languages={tdbFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Bedienungsanleitungen
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={beaFilesItems}
                                                        fieldType={beaFiles.fieldType}
                                                        allowedExtensions={beaFiles.allowedExtensions}
                                                        isMultiple={beaFiles.isMultiple}
                                                        onAdd={onAddBeaFilesHandler}
                                                        onUpdate={onBeaFilesUpdateHandler}
                                                        onDelete={onBeaFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onBeaFilesBrowseHandler}
                                                        languages={beaFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Prospekte
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={proFilesItems}
                                                        fieldType={proFiles.fieldType}
                                                        allowedExtensions={proFiles.allowedExtensions}
                                                        isMultiple={proFiles.isMultiple}
                                                        onAdd={onAddProFilesHandler}
                                                        onUpdate={onProFilesUpdateHandler}
                                                        onDelete={onProFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onProFilesBrowseHandler}
                                                        languages={proFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Sicherheitsdatenblätter
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={sdbFilesItems}
                                                        fieldType={sdbFiles.fieldType}
                                                        allowedExtensions={sdbFiles.allowedExtensions}
                                                        isMultiple={sdbFiles.isMultiple}
                                                        onAdd={onAddSdbFilesHandler}
                                                        onUpdate={onSdbFilesUpdateHandler}
                                                        onDelete={onSdbFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onSdbFilesBrowseHandler}
                                                        languages={sdbFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Weitere Dokumente
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={sdoFilesItems}
                                                        fieldType={sdoFiles.fieldType}
                                                        allowedExtensions={sdoFiles.allowedExtensions}
                                                        isMultiple={sdoFiles.isMultiple}
                                                        onAdd={onAddSdoFilesHandler}
                                                        onUpdate={onSdoFilesUpdateHandler}
                                                        onDelete={onSdoFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onSdoFilesBrowseHandler}
                                                        languages={sdoFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                CAD Zeichnungen
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={cadFilesItems}
                                                        fieldType={cadFiles.fieldType}
                                                        allowedExtensions={cadFiles.allowedExtensions}
                                                        isMultiple={cadFiles.isMultiple}
                                                        onAdd={onAddCadFilesHandler}
                                                        onUpdate={onCadFilesUpdateHandler}
                                                        onDelete={onCadFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onCadFilesBrowseHandler}
                                                        languages={cadFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Masszeichnungen
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={zeiFilesItems}
                                                        fieldType={zeiFiles.fieldType}
                                                        allowedExtensions={zeiFiles.allowedExtensions}
                                                        isMultiple={zeiFiles.isMultiple}
                                                        onAdd={onAddZeiFilesHandler}
                                                        onUpdate={onZeiFilesUpdateHandler}
                                                        onDelete={onZeiFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onZeiFilesBrowseHandler}
                                                        languages={zeiFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Explosionszeichnungen
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={expFilesItems}
                                                        fieldType={expFiles.fieldType}
                                                        allowedExtensions={expFiles.allowedExtensions}
                                                        isMultiple={expFiles.isMultiple}
                                                        onAdd={onAddExpFilesHandler}
                                                        onUpdate={onExpFilesUpdateHandler}
                                                        onDelete={onExpFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onExpFilesBrowseHandler}
                                                        languages={expFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Video
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={vidFilesItems}
                                                        fieldType={vidFiles.fieldType}
                                                        allowedExtensions={vidFiles.allowedExtensions}
                                                        isMultiple={vidFiles.isMultiple}
                                                        onAdd={onAddVidFilesHandler}
                                                        onUpdate={onVidFilesUpdateHandler}
                                                        onDelete={onVidFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onVidFilesBrowseHandler}
                                                        languages={vidFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Schemas
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={schFilesItems}
                                                        fieldType={schFiles.fieldType}
                                                        allowedExtensions={schFiles.allowedExtensions}
                                                        isMultiple={schFiles.isMultiple}
                                                        onAdd={onAddSchFilesHandler}
                                                        onUpdate={onSchFilesUpdateHandler}
                                                        onDelete={onSchFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onSchFilesBrowseHandler}
                                                        languages={schFiles.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Montageanleitungen
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={moaFilesItems}
                                                        fieldType={moaFiles.fieldType}
                                                        allowedExtensions={moaFiles.allowedExtensions}
                                                        isMultiple={moaFiles.isMultiple}
                                                        onAdd={onAddMoaFilesHandler}
                                                        onUpdate={onMoaFilesUpdateHandler}
                                                        onDelete={onMoaFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onMoaFilesBrowseHandler}
                                                        languages={moaFiles.languages}
                                                    />

                                                </TabPanel>
                                                <TabPanel value={tabValue} index={5}>
                                                    <InlineTechnicalSpecifications
                                                        items={technicalSpecifications}
                                                        technicalData={technicalSpecificationsTypes}
                                                        onAdd={onAddTechnicalSpecificationHandler}
                                                        onUpdate={onTechnicalSpecificationUpdateHandler}
                                                        onDelete={onTechnicalSpecificationRemoveHandler}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={6}>
                                                    <ErpProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={7}>
                                                    <PromotionProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }
            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Product;
