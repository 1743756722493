import React, {ChangeEvent, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Autocomplete,
    Button, Checkbox,
    DialogActions, FormControl, FormControlLabel, FormGroup, FormLabel,
    Grid,
    IconButton,
    InputLabel, ListItemText,
    MenuItem, OutlinedInput, Radio, RadioGroup,
    Select,
    TextField as MuiTextField
} from "@mui/material";

// Types
import {InlineListEditItemType, InlineListEditType} from "../../types/inlineListEdit";

// GraphQL
import {
    GetInlineEditTableQuery,
    useGetInlineEditTableQuery,
    useInlineEditUpdateMutation
} from "../../graphql/shared/shared.graphql-gen";

// Icons
import {X as CloseIcon, Save as SaveIcon} from "react-feather";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import ReactQuill from "react-quill";
import SnackbarNotification from "../../components/SnackbarNotification";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {
    min-height: 200px;
}`;

const InlineListViewEditDialog: React.FC<InlineListEditType> = (props) => {
    const {id, model, field} = props;

    // States
    const [item, setItem] = useState<InlineListEditItemType>();
    const [selectedOptions, setSelectedOptions] = useState<any>(null);
    const [fieldOptions, setFieldOptions] = useState<any>([]);

    // Get data from API
    const {data: data, error: error, loading: loading} = useGetInlineEditTableQuery(
        {
            variables: {
                id,
                model,
                field
            },
            onCompleted: (data: GetInlineEditTableQuery) => {
                setItem(data.getInlineEditTable);
                setFieldOptions(data.getInlineEditTable.fieldOptions);

                if (data.getInlineEditTable.fieldType === 'multiSelect') {
                    const options: { id: string, title: string }[] = data.getInlineEditTable.fieldOptions;
                    const values: any[] = data.getInlineEditTable.fieldValue;
                    const selectedOptionsObj = options ? options.filter(({id}) => values.includes(id)) : [];

                    setSelectedOptions(selectedOptionsObj);
                } else {
                    setSelectedOptions(data.getInlineEditTable.fieldValue);
                }
            }
        }
    )

    const [inlineEditUpdate] = useInlineEditUpdateMutation()

    //------------------------------- Public methods -------------------------------------


    // This function is called when value changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement>, data?: any) => {
        let value: any;

        // Update field value
        switch (item?.fieldType) {
            case 'multiSelect':
                // @ts-ignore
                setItem({...item, fieldValue: data.map(item => item.id)});
                break;
            case 'check':
                value = event.target.checked;
                // @ts-ignore
                setItem({...item, fieldValue: value});
                break;
            case 'textareaEditor':
                value = event;
                setItem({...item, fieldValue: value});
                break;
            default:
                value = event.target.value;
                // @ts-ignore
                setItem({...item, fieldValue: value});
        }
    };

    // Set value on select/radio change
    const onSelectChangeHandler = (value: any) => {
        // Update selected value
        setSelectedOptions(value);
    };


    // Save new values
    const onSave = () => {
        inlineEditUpdate({
            variables: {
                id,
                model,
                data: item
            }
        }).then((res: any) => {
            props.onUpdateData();
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='sm'
                fullWidth={true}
            >
                <IconButton
                    aria-label="close"
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <DialogTitle id="alert-dialog-title" sx={{m: 0, p: 2, mb: 5}}>
                        Inline-Feldbearbeitung
                    </DialogTitle>
                    <Grid container spacing={6}>
                        {(() => {
                            switch (item?.fieldType) {
                                case 'singleSelect':
                                    return <Grid item md={12} mt={2}>
                                        <FormControl fullWidth>
                                            <InputLabel>{item?.fieldLabel}</InputLabel>
                                            <Select
                                                name={item?.fieldLabel}
                                                label={item?.fieldLabel}
                                                value={selectedOptions ? selectedOptions : ''}
                                                onChange={(e: any) => {
                                                    inputHandler(e);
                                                    onSelectChangeHandler(e.target.value);
                                                }}
                                            >
                                                {fieldOptions.map((item: { id: string, title: string }) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <ListItemText primary={item.title}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                case 'multiSelect':
                                    return <Grid item md={12} mt={2}>
                                        <Autocomplete
                                            multiple
                                            options={fieldOptions}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            value={selectedOptions || []}
                                            defaultValue={selectedOptions || []}
                                            onChange={(e: any, value: any) => {
                                                inputHandler(e, value);
                                                onSelectChangeHandler(value);
                                            }}
                                            renderOption={(props, option, {selected}) => (
                                                <li {...props} key={option.id}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label={item?.fieldLabel}
                                                           placeholder={item?.fieldLabel}
                                                           name={item?.fieldName}/>
                                            )}
                                        />
                                    </Grid>
                                case 'check':
                                    return <Grid item md={5} mt={2}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                name={item?.fieldLabel}
                                                checked={item.fieldValue ? item.fieldValue : false}
                                                onChange={(e) => {
                                                    inputHandler(e)
                                                    onSelectChangeHandler(e.target.value)
                                                }}
                                            />
                                        } label={item?.fieldLabel}/>
                                    </Grid>
                                case 'radio':
                                    return <Grid item md={5} mt={2}>
                                        <FormControl>
                                            <FormLabel id={item?.fieldLabel}>{item?.fieldLabel}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby={item?.fieldLabel}
                                                name={item?.fieldName}
                                                value={item?.fieldValue}
                                                onChange={(e) => {
                                                    inputHandler(e);
                                                }}
                                            >
                                                {item?.fieldOptions!.map((option: { id: string, title: string }) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={option.id}
                                                            value={option.id}
                                                            control={<Radio/>}
                                                            label={option.title}/>
                                                    )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                case 'textarea':
                                    return <Grid item md={12} mt={2}>
                                        <TextField
                                            name={item?.fieldName}
                                            label={item?.fieldLabel}
                                            value={item?.fieldValue ? item?.fieldValue : ''}
                                            multiline
                                            rows={4}
                                            fullWidth
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                            my={2}
                                        />
                                    </Grid>
                                case 'textareaEditor':
                                    return <Grid item md={12} mt={2}>
                                        <QuillWrapper className="full-width">
                                            <InputLabel className="mb-5">{item?.fieldLabel}</InputLabel>
                                            <ReactQuill
                                                theme="snow"
                                                value={item?.fieldValue ? item?.fieldValue : ''}
                                                onChange={(e: any) => inputHandler(e)}
                                            />
                                        </QuillWrapper>
                                    </Grid>
                                default:
                                    return <Grid item md={12}>
                                        <TextField
                                            name={item?.fieldName}
                                            label={item?.fieldLabel}
                                            value={item?.fieldValue ? item?.fieldValue : ''}
                                            fullWidth
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                            variant="outlined"
                                            my={2}
                                        />
                                    </Grid>
                            }
                        })()}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{mb: 3}}>
                    <Button variant="contained" color="primary" onClick={onSave}
                            className="multi-btn" startIcon={<SaveIcon/>}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default InlineListViewEditDialog;
