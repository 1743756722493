import React, {useState} from "react";

import {
    Grid,
    Typography,
    Divider as MuiDivider,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel, FormControl, Select, MenuItem,
} from "@mui/material";

import ReactQuill from "react-quill";

// Styles
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import {parseISO} from "date-fns";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {
  min-height: 200px;
}`;
const Divider = styled(MuiDivider)(spacing);


const GeneralInfoProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit, checked, dataObj} = props;
    // States
    const [description, setDescription] = useState(state.description ? state.description : (values.description ? values.description : ''));
    const [publishDate, setPublishDate] = React.useState<Date | null>(state.updatedAt ? state.updatedAt : (values.updatedAt ? values.updatedAt : null));
    const [valueExportWeb, setValueExportWeb] = useState<boolean>(values.exportWeb?.length == 0 || !values.exportWeb ? false : values.exportWeb);
    const [valueExportToErp, setValueExportToErp] = useState<boolean>(values.exportToErp?.length == 0 || !values.exportToErp ? false : values.exportToErp);
    const [valueExportToWebshop, setValueExportToWebshop] = useState<boolean>(values.exportToWebshop?.length == 0 || !values.exportToWebshop ? false : values.exportToWebshop);


    //------------------------------- Public methods -------------------------------------

    // Update description on change
    const handleChangeDescription = (event: any) => {
        event.toString('html');
        setDescription(event);
    };

    //update checkbox
    const handleExportWebChange = (event: any) => {
        setValueExportWeb(event.target.checked);
    };

    const handleExportToErpChange = (event: any) => {
        setValueExportToErp(event.target.checked);
    };

    const handleExportToWebshopChange = (event: any) => {
        setValueExportToWebshop(event.target.checked);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="sku"
                        label={'Nr.'}
                        value={values.sku ? values.sku : ''}
                        error={Boolean(touched.sku && errors.sku)}
                        fullWidth
                        helperText={touched.sku && errors.sku}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment><span style={{fontSize: 13, color: "blue"}}>Erp *</span> <span
                        style={{fontSize: 13, color: "green"}}>Pim *</span> <span
                        style={{fontSize: 13, color: "orange"}}>Shop *</span></React.Fragment>
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Teilegruppen</InputLabel>
                        <Select
                            readOnly={true}
                            name="categories"
                            value={checked}
                            label="Teilegruppen"
                        >
                            {dataObj.categoriesThree.categories.map((item: { value: string, label: string }) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <React.Fragment><span style={{fontSize: 13, color: "blue"}}>Erp *</span> <span
                        style={{fontSize: 13, color: "green"}}>Pim *</span> <span
                        style={{fontSize: 13, color: "orange"}}>Shop *</span></React.Fragment>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                        <DatePicker
                            label="Letztes Änderungsdatum"
                            value={new Date(publishDate!)}
                            onChange={(newValue: any) => {
                                setPublishDate(newValue)
                                onChange('', 'publishDate', 'date', newValue);
                            }}
                        />
                    </LocalizationProvider>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearUpdatedAt"
                                    checked={state.clearUpdatedAt ? state.clearUpdatedAt : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sortierung"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSorting"
                                    checked={state.clearSorting ? state.clearSorting : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportWeb"
                                checked={valueExportWeb}
                                onChange={(e) => {
                                    handleExportWebChange(e);
                                    onChange(e)
                                }}
                            />
                        } label="Export zum Website"/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportToErp"
                                checked={valueExportToErp}
                                onChange={(e) => {
                                    handleExportToErpChange(e);
                                    onChange(e)
                                }}
                            />
                        } label="Export zum ERP"/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportToWebshop"
                                checked={valueExportToWebshop}
                                onChange={(e) => {
                                    handleExportToWebshopChange(e);
                                    onChange(e)
                                }}
                            />
                        } label="Export zum Webshop"/>
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label={'Beschreibung'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment><span style={{fontSize: 13, color: "blue"}}>Erp *</span> <span
                        style={{fontSize: 13, color: "green"}}>Pim *</span> <span
                        style={{fontSize: 13, color: "orange"}}>Shop *</span></React.Fragment>
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label='Beschreibung FRS'
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitleDe"
                                    checked={state.clearTitleDe ? state.clearTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitle"
                        label="Beschreibung 2"
                        value={values.subtitle ? values.subtitle : ''}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment><span style={{fontSize: 13, color: "blue"}}>Erp *</span> <span
                        style={{fontSize: 13, color: "green"}}>Pim *</span> <span
                        style={{fontSize: 13, color: "orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitle"
                                    checked={state.clearSubtitle ? state.clearSubtitle : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitleDe"
                        label='Beschreibung 2 FRS'
                        value={values.subtitleDe ? values.subtitleDe : ''}
                        error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                        fullWidth
                        helperText={touched.subtitleDe && errors.subtitleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitleDe"
                                    checked={state.clearSubtitleDe ? state.clearSubtitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
        ;
}


export default GeneralInfoProduct;
