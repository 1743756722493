import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoPlatform: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange, state, multiEdit} = props;

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label={multiEdit ? 'Titel' : 'Titel *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={1} mb={5}>
                    <TextField
                        name="code"
                        label="Code"
                        value={values.code ? values.code : ''}
                        error={Boolean(touched.code && errors.code)}
                        fullWidth
                        helperText={touched.code && errors.code}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCode"
                                    checked={state.clearCode ? state.clearCode : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoPlatform;
