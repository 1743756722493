import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import ListViewTable from "../../components/ListViewTable";
import {DynamicSelectContext} from "./store/dynamic-select-context";

// Types
import {HeadCell} from "../../../types/headCell";


// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const Divider = styled(MuiDivider)(spacing);


function DynamicSelects() {
    const dynamicSelectCtx = useContext(DynamicSelectContext);

    // Set table headers data
    const headCells: Array<HeadCell> = dynamicSelectCtx.headers;
    const model = 'SelectField';

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Dynamic Select"/>
            <Grid item xs={12} mb={10}>
                <Typography variant="h1" gutterBottom display="inline">
                    Dynamische Auswahl
                </Typography>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={dynamicSelectCtx.search} onSearch={dynamicSelectCtx.onSearch}/>
                </Grid>
            </Grid>


            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={dynamicSelectCtx.items}
                    tableHeaders={headCells}
                    onSelectHandler={dynamicSelectCtx.selectItem}
                    onPaginateHandler={dynamicSelectCtx.onPaginate}
                    onSortHandler={dynamicSelectCtx.onSort}
                    onDataUpdate={dynamicSelectCtx.onDataUpdate}
                    count={dynamicSelectCtx.count}
                    page={dynamicSelectCtx.page}
                    sorting={dynamicSelectCtx.sorting}
                    rowsPerPage={dynamicSelectCtx.rowsPerPage}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default DynamicSelects;
