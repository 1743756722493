import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Checkbox,
    Divider as MuiDivider,
    Autocomplete, FormGroup, FormControlLabel, FormControl, InputLabel, MenuItem, Select
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const companySolutions: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'companySolutions');
    const companyType: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'companyType');

    // States
    //const [selectedCompanyType, setSelectedCompanyType] = useState<any>(state.companyType ? companyType.filter((item) => item.id === state.companyType[0])  : (values.companyType ? companyType.filter((item) => item.id === values.companyType[0]) : []));
    const [selectedCompanyType, setSelectedCompanyType] = useState(state.companyType ? state.companyType : (values.companyType ? values.companyType[0] : ''));
    const [selectedCompanySolutions, setSelectedCompanySolutions] = useState<any>(state.companySolutions ? state.companySolutions.map((id: string) => companySolutions.filter((item) => item.id === id)[0]) : (values.companySolutions ? values.companySolutions.map((id: string) => companySolutions.filter((item) => item.id === id)[0]) : []));

    //------------------------------- Public methods -------------------------------------


    //SINGLE SELECT autocomplete not working change tab
    // // Update companySolutions on change
    // const handleCompanyTypeChange = (value: any) => {
    //     setSelectedCompanyType(value ? value : []);
    // };

    // Update category on change
    const handleCompanySolutionChange = (value: any) => {
        setSelectedCompanySolutions(value)

    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={10}>
                    <TextField
                        disabled={true}
                        name="companyNo"
                        label="Lieferant Nr."
                        value={values.companyNo ? values.companyNo : ''}
                        error={Boolean(touched.companyNo && errors.companyNo)}
                        fullWidth
                        helperText={touched.companyNo && errors.companyNo}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={4} mb={10}>
                    <TextField
                        disabled={true}
                        name="title"
                        label={multiEdit ? 'Name' : 'Name *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"
                    />

                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        disabled={true}
                        name="building"
                        label="Name 2"
                        value={values.building ? values.building : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoCompany;
