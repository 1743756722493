import React, {useEffect, useState} from "react";

import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Checkbox, Divider as MuiDivider, Grid,
    IconButton, ListItemText, MenuItem, OutlinedInput,
    Paper as MuiPaper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
} from "@mui/material";
import {HeadCell} from "../../types/headCell";
import {useLocation, useNavigate} from "react-router-dom";


// Types
import {EnhancedTableHeadProps} from "../../types/enhancedTableHeadProps";
import {EnhancedTableToolbarProps} from "../../types/enhancedTableToolbarProps";
import {TableData} from "../../types/tableData";
import {Filters} from "../../types/filters";

// Styling
import styled from "styled-components/macro";
import {SelectChangeEvent} from "@mui/material/Select";
import {spacing} from "@mui/system";
import {Edit3 as EditIcon, Trash2 as TrashIcon} from "react-feather";
import Avatar from "@mui/material/Avatar";
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import FolderIcon from '@mui/icons-material/Folder';
import {
    RemoveRedEye as RemoveRedEyeIcon,
    FilterList as FilterListIcon,
    Folder as FolderIcon,
    Assignment as AssignmentIcon
} from "@mui/icons-material";


const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
    const location: any = useLocation();

    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        rowData,
        onRequestSort,
        rowsPerPage,
        model
    } = props;

    //------------------------------- Public methods -------------------------------------


    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {location.state?.modelName &&
                    <TableCell padding="checkbox">

                    </TableCell>

                }

                {rowData.map((headCell: HeadCell) => (
                    headCell.fieldType === 'simple'
                        ? <TableCell
                            key={headCell.fieldName}
                            align='left'
                            padding="normal"
                            sortDirection={orderBy === headCell.fieldName ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.fieldName}
                                direction={orderBy === headCell.fieldName ? order : "asc"}
                                onClick={createSortHandler(headCell.fieldName)}
                            >
                                {headCell.fieldLabel}
                            </TableSortLabel>
                        </TableCell>
                        : <TableCell
                            key={headCell.fieldName}
                            align='left'
                            padding="normal"
                        >
                            {headCell.fieldLabel}
                        </TableCell>
                ))}
                <TableCell
                    align='right'
                    padding="normal"
                >
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const {numSelected} = props;
    const [filtersArray, setFiltersArray] = useState<any>(props.filters ? props.filters : []);
    const [state, setState] = useState({});

    //------------------------------- Public methods -------------------------------------

    // This function is called when the select changes
    const inputHandler = (event: SelectChangeEvent<HTMLSelectElement>, currentFilter: Filters) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setState({...state, [fieldName]: value});

        if (filtersArray) {
            const filteritem = filtersArray.find((filter: any) => filter.fieldName == currentFilter.fieldName);
            filteritem.selectedOption = value;
            setFiltersArray(filtersArray);
        }
    };


    return (
        <React.Fragment>
            {filtersArray.lenght > 0 &&
                <Toolbar className="toolbar-wrapper">
                    <Grid item xs={12}>
                        <Accordion className="filters-wrapper">
                            <AccordionSummary
                                expandIcon={<FilterListIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6" id="tableTitle">
                                    Filters
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={6}>
                                    {filtersArray.map((filter: Filters) => {
                                        return (
                                            <Grid item xs={2} key={filter.fieldName} className="filter-item">
                                                <Select
                                                    className="full-width"
                                                    name={filter.fieldName}
                                                    value={filter.selectedOption}
                                                    input={<OutlinedInput label="Tag"/>}
                                                    onChange={(e: SelectChangeEvent<HTMLSelectElement>) => inputHandler(e, filter)}
                                                >
                                                    {filter.options.map((option: {
                                                        value: string | number,
                                                        label: string
                                                    }) => (
                                                        <MenuItem key={option.label} value={option.value}>
                                                            <ListItemText primary={option.label}/>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </AccordionDetails>
                            <Divider mt={5} mb={5}/>
                        </Accordion>
                    </Grid>
                </Toolbar>
            }
        </React.Fragment>

    );
};

const ListViewMediacenterTable: React.FC<TableData> = (props) => {
    const navigate = useNavigate();
    const location: any = useLocation();
    const {
        rows,
        tableHeaders,
        onSelectFolderHandler,
        onSelectHandler,
        onDeleteHandler,
        onPaginateHandler,
        onSortHandler,
        count,
        filters,
        onMultiEditHandler,
        selectedArray,
        model
    } = props;
    const [order, setOrder] = useState<"desc" | "asc">("desc");
    const [orderBy, setOrderBy] = useState("title");
    const [selected, setSelected] = useState<Array<string>>(selectedArray ? selectedArray : []);
    const [page, setPage] = useState(props.page);
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);


    //------------------------------- Public methods -------------------------------------

    const handleRequestSort = (event: any, property: string) => {
        const isAsc = orderBy === property && order === "asc";

        // @ts-ignore
        onSortHandler(property, order);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds: Array<string> = rows.map((n: any) => n.id);
            setSelected(newSelecteds);
            if (onMultiEditHandler) {
                onMultiEditHandler(newSelecteds);
            }
            return;
        }
        setSelected([]);
        if (onMultiEditHandler) {
            onMultiEditHandler([]);
        }

    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: Array<string> = [];

        if (location.state.isMultiple) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
        } else {
            newSelected = [id];
        }

        setSelected(newSelected);
        if (onMultiEditHandler) {
            onMultiEditHandler(newSelected);
        }

    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        onPaginateHandler(rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        onPaginateHandler(parseInt(event.target.value, 10), page);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;


    return (
        <div>
            <Paper>
                <EnhancedTableToolbar numSelected={selected.length} filters={filters}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                        className="list-table media-table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={tableHeaders.length}
                            rowData={tableHeaders}
                            rowsPerPage={rowsPerPage}
                            model={model}
                        />
                        <TableBody>

                            {rows.map((row: any, index: number) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={`${row.id}-${index}`}
                                        selected={isItemSelected}
                                    >

                                        {location.state?.modelName &&

                                            <TableCell padding="checkbox">
                                                {row.type !== 'folder'
                                                    ? <Checkbox checked={isItemSelected}
                                                                inputProps={{"aria-labelledby": labelId}}
                                                                onClick={(event) => handleClick(event, row.id)}/>
                                                    : ''
                                                }
                                            </TableCell>
                                        }


                                        {Object.keys(row).map((item: string, index) => {
                                            switch (item) {
                                                case 'id':
                                                case 'type':
                                                    break;
                                                case 'image':
                                                    return <TableCell
                                                        className="small-cell click-row"
                                                        key={`${row.id}-${index}`}
                                                        align="left"
                                                        //onClick={() => onSelectHandler(row.id)}
                                                        onClick={() => {
                                                            if (row.type == 'folder' && onSelectFolderHandler) {
                                                                onSelectFolderHandler(row.id)
                                                            } else {
                                                                if(onSelectHandler){
                                                                    onSelectHandler(row.id)
                                                                }
                                                            }
                                                        }}

                                                    >
                                                        {(() => {
                                                            if (row[item] == 'folder') {
                                                                return (
                                                                    <Avatar><FolderIcon/></Avatar>
                                                                )
                                                            } else if (row[item] == 'document') {
                                                                return (
                                                                    <Avatar><AssignmentIcon/></Avatar>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Avatar src={row[item]}/>
                                                                )
                                                            }
                                                        })()}
                                                    </TableCell>
                                                default:
                                                    return <TableCell
                                                        className="click-row"
                                                        key={`${row.id}-${index}`}
                                                        align="left"
                                                        dangerouslySetInnerHTML={{__html: row[item]}}
                                                        onClick={() => {
                                                            if (row.type == 'folder' && onSelectFolderHandler) {
                                                                onSelectFolderHandler(row.id)
                                                            } else {
                                                                if(onSelectHandler){
                                                                    onSelectHandler(row.id)
                                                                }
                                                            }
                                                        }}
                                                    />
                                            }
                                        })}

                                        <TableCell padding="none" align="right">
                                            <Box mr={2}>
                                                {row.type == 'folder' &&
                                                    <IconButton aria-label="details" size="large"
                                                                onClick={(e) => {
                                                                    navigate(`/mediacenter/folder/edit/${row.id}`, {
                                                                        replace: true,
                                                                        state: location.state
                                                                    })
                                                                }}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                }
                                                {row.type != 'folder' &&
                                                    <IconButton aria-label="details" size="large"
                                                                onClick={(e) => {
                                                                    navigate(`/mediacenter/file/${row.id}`, {
                                                                        replace: true,
                                                                        state: location.state
                                                                    })
                                                                }}>
                                                        <RemoveRedEyeIcon/>
                                                    </IconButton>
                                                }
                                                <span className="spacer"></span>
                                                {onDeleteHandler &&
                                                    <IconButton aria-label="delete" size="large"
                                                                onClick={() => onDeleteHandler(row.id, row.type)}>
                                                        <TrashIcon/>
                                                    </IconButton>
                                                }
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"Zeilen pro Seite"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default ListViewMediacenterTable;
