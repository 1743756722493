import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel, FormControl, Select, MenuItem,
} from "@mui/material";

import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const platforms: { id: string, title: string }[] = data.selectPlatforms;
    const products: { id: string, title: string }[] = data.selectProduct;


    // States
    const [selectedPlatforms, setSelectedPlatforms] = useState<any>(state.platforms ? state.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : (values.platforms ? values.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : []));
    const [selectedProduct, setSelectedProduct] = useState<any[]>(state.product ? state.product  : (values.product ? values.product[0] : ''));

    //------------------------------- Public methods -------------------------------------



    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="articleNo"
                        label={multiEdit ? 'Article Number' : 'Article Number *'}
                        value={values.articleNo ? values.articleNo : ''}
                        error={Boolean(touched.articleNo && errors.articleNo)}
                        fullWidth
                        helperText={touched.articleNo && errors.articleNo}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sorting"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSorting"
                                    checked={state.clearSorting ? state.clearSorting : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label={multiEdit ? 'Title' : 'Title *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>

            </Grid>



            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Product</InputLabel>
                        <Select
                            name="product"
                            value={selectedProduct}
                            label="Product"
                            onChange={(event: any) => {
                                setSelectedProduct(event.target.value);
                                onChange(event)
                            }}
                        >
                            {products.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearProduct"
                                    checked={state.clearProduct ? state.clearProduct : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={platforms}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title ? option.title : ''}
                        defaultValue={selectedPlatforms || []}
                        value={selectedPlatforms || []}
                        onChange={(event: any, value: any) => {
                            setSelectedPlatforms(value)
                            onChange(event, 'platforms', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Platforms (Website) " placeholder="Platforms (Website)"
                                       name="platforms"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPlatforms"
                                    checked={state.clearPlatforms ? state.clearPlatforms : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Description"
                        name="description"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.description ? values.description : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescription"
                                    checked={state.clearDescription ? state.clearDescription : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoArticle;
