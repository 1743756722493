import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {ProductsContext} from "./store/products-context";
import SearchBar from "../components/SearchBar";
import ListViewTableSettings from "../components/ListViewTableSettings";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz, PictureAsPdf} from "@mui/icons-material";
import {Settings} from "react-feather";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Products() {
    const productsCtx = useContext(ProductsContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = productsCtx.headers;
    const model = 'products';
    const tableSettings = productsCtx.getTableSettings!

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openSettings, setOpenSettings] = React.useState(false);

    //------------------------------- Public methods -------------------------------------


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Open setting modal
    const onSettingsHandel = (): void => {
        setOpenSettings(true);
    }

    // Close settings modal
    const toggleSettingsHandler = () => {
        setOpenSettings(!openSettings);
    }

    // Update settings
    const onSettingsUpdateHandel = (data: any): void => {
        if (productsCtx.setTableSettings) {
            productsCtx.setTableSettings(data);
        }
        setOpenSettings(false);
    }




    return (
        <React.Fragment>
            <Helmet title="Articles"/>
            <Grid item>
                <Typography variant="h1" gutterBottom display="inline">
                    Artikel
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={productsCtx.search} onSearch={productsCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={productsCtx.addItem} startIcon={<AddIcon/>}>
                            Neuer Artikel
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (productsCtx.exportCsv) {
                                    productsCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV-Export Artikel
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (productsCtx.exportCsv) {
                                    productsCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS-Export Artikel
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (productsCtx.exportServer) {
                                    productsCtx.exportServer('server')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV-Export Server
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS-Import Artikel
                            </MenuItem>

                            {productsCtx.selected && productsCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={productsCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Mehrfachbearbeitung
                                    </MenuItem>
                                    <MenuItem onClick={(event: any) => {
                                        if(productsCtx.generatePdf){
                                            productsCtx.generatePdf()
                                        }
                                        handleClose()
                                    }} disableRipple>
                                        <PictureAsPdf/>
                                        Pdf generieren
                                    </MenuItem>
                                </div>
                            }
                            <MenuItem
                                onClick={(event: any) => onSettingsHandel()}
                                disableRipple>
                                <Settings/>
                                Settings
                            </MenuItem>
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (productsCtx.importCsv) {
                                    productsCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={productsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={productsCtx.removeItem}
                    onSelectHandler={productsCtx.selectItem}
                    onPaginateHandler={productsCtx.onPaginate}
                    onSortHandler={productsCtx.onSort}
                    onFilterHandler={productsCtx.onFilter}
                    onDataUpdate={productsCtx.onDataUpdate}
                    count={productsCtx.count}
                    page={productsCtx.page}
                    rowsPerPage={productsCtx.rowsPerPage}
                    sorting={productsCtx.sorting}
                    filters={productsCtx.filters}
                    filter={productsCtx.filter}
                    onMultiEditHandler={productsCtx.multiSelected}
                    selectedArray = {productsCtx.selected}
                    model={model}
                />
            </Grid>

            <ListViewTableSettings
                open={openSettings}
                handleClose={toggleSettingsHandler}
                items={tableSettings}
                selectedItems={headCells}
                updateSettings={onSettingsUpdateHandel}
            />
        </React.Fragment>
    );
}

export default Products;
