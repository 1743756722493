import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert,
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import GeneralInfoCategory from "./fieldGroups/GeneralInfoCategory";


// GraphQL
import {FetchResult} from "@apollo/client";
import {
    CategoryQuery,
    CreateCategoryMutation, NewCategoryQuery,
    useCategoryQuery, useCreateCategoryMutation,
    useNewCategoryQuery, useResetCategoryMutation,
    useUpdateCategoryMutation
} from "../../graphql/category/category.graphql-gen";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
});

const CategoryImport: React.FC = () => {
    let title: string = 'Kategorie Import'
    let dataObj: any = {};
    const params = useParams();
    let id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'headerImages'];
    const navigate = useNavigate();
    const model: string = 'Category';
    let fromMediaCenter: boolean = false;
    const location: any = useLocation();

    // Queries
    const {data: data, error: error, loading: loading} = useCategoryQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: CategoryQuery) => {
            setMediaItems(data.category.images?.items);
            if (data.category && data.category.category) {
                setState(data.category.category);
                setInicialValue({
                    "data": data.category.category,
                    "images": data.category.images?.items,
                    "checked": data.categoriesThree.checked,
                    "checkedShop": data.categoriesThree.checkedShop
                });
            }
            setChecked(data.categoriesThree.checked);
            setCheckedShop(data.categoriesThree.checkedShop);

            setUserId(data.user.user?.id);
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewCategoryQuery({
        variables: {
            fields: inlineModels,
            model
        },
        skip: id !== '',
        onCompleted: (data: NewCategoryQuery) => {
            setState({});
            setInicialValue({});

            setUserId(data.user.user?.id);


        }
    });
    const [updateCategory] = useUpdateCategoryMutation();
    const [createCategory] = useCreateCategoryMutation();
    const [resetCategory] = useResetCategoryMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [mediaItems, setMediaItems] = useState<any>(dataObj.category ? dataObj.category.images.items : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [checked, setChecked] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checked : []);
    const [checkedShop, setCheckedShop] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checkedShop : []);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();

    const [userId, setUserId] = useState<any>();



    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.category && dataObj.category.category) {
        title = dataObj.category.category.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveCategory = (fieldName?: any, fieldType?: any, isMultiple?: any, submodelId?: any, submodelName?: string) => {
        if (id != '') {
            updateCategoryMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        } else {
            createCategoryMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        }
        setInicialValue({
            "data": state, "images": mediaItems, "checked": checked, "checkedShop": checkedShop
        });
    }

    // Update category mutation
    const updateCategoryMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean, submodelId?: any, submodelName?: string) => {
        updateCategory({
            variables: {
                model,
                id,
                data,
                parents: checked,
                parentsShop: checkedShop,
                images: mediaItems,
                removedMedia,
                temp: fromMediaCenter
            }
        }).then(r => {

            setUpdated(true)
            setRemovedMedia([])
            setTimeout(() => {
                setUpdated(false);
            }, 3000)
            if (fromMediaCenter) {
                navigate(`/mediacenter/`, {
                    replace: true,
                    state: {fieldName, fieldType, isMultiple, modelName: model, modelId: id, submodelId, submodelName}
                })
            }

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }

    // Create category mutation
    const createCategoryMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean, submodelId?: any, submodelName?: string) => {
        createCategory({
            variables: {
                model,
                data,
                user: userId,
                parents: checked,
                parentsShop: checkedShop,
                images: mediaItems,
                removedMedia,
                temp: fromMediaCenter
            },
        })
            .then((res: FetchResult<CreateCategoryMutation>) => {
                if (res.data && res.data.createCategory.category) {
                    setUpdated(true);
                    if (fromMediaCenter) {
                        navigate(`/mediacenter/`, {
                            replace: true,
                            state: {fieldName, fieldType, isMultiple, modelName: model, modelId: res.data.createCategory.category.id, submodelId, submodelName}
                        })
                    } else {
                        navigate(`/categories/${res.data.createCategory.category.id}`, {replace: true})
                    }

                }
            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        resetCategory({
            variables: {
                model,
                id
            }
        }).then(r => {
            navigate(`/categories`, {replace: true})
        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }
    const openDialog = () => {

        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "checked": checked, "checkedShop": checkedShop
        });

        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }



    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            if (event.target) {
                fieldName = event.target.name;
                value = event.target.value;
            }

            if (field) {
                fieldName = field
                value = event
            }
        }
        // Update select value
        setState({...state, [fieldName]: value});

    };


    return (
        <React.Fragment>
            <Helmet title="Category"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveCategory}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">

                                            <div >
                                                    <GeneralInfoCategory
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />

                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }

            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default CategoryImport;
