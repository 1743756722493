import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Alert as MuiAlert, CircularProgress,
} from "@mui/material";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import Loader from "../../../../components/Loader";
import SnackbarNotification from "../../../../components/SnackbarNotification";


// Types
import {dynamicListView} from "../../../../types/dynamicListView";
import {Filters} from "../../../../types/filters";

// Graphql
import {ApolloQueryResult} from "@apollo/client";
import {
    DangersListQuery,
    useDangersListQuery,
    useDeleteDangerMutation,
    useExportCsvDangerMutation, useImportCsvDangerMutation
} from "../../../../graphql/settings/danger/danger.graphql-gen";
import {useGetTableSettingsQuery, useSetTableSettingsMutation} from "../../../../graphql/shared/shared.graphql-gen";

// Style
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const Alert = styled(MuiAlert)(spacing);


export const DangerousContext = React.createContext<dynamicListView>({
    items: [],
    headers: [],
    count: 0,
    addItem: () => {},
    removeItem: (id: string) => {},
    selectItem: (id: string | number) => {},
    onPaginate: (limit: number, page: number) => {},
    onSearch: (searchText:string) => {},
    onSort: (filed:string, order: string) => {},
    onDataUpdate: () => {},
    page: 0,
    rowsPerPage: 10,
    search: '',
    sorting: {field: '', order: ''},
    filters: [],
    multiSelected: (idArray: string[]) => {},
    multiEdit: () => {},
    selected: [],
    exportCsv: (exportType: string) => {},
    importCsv: (event: any) => {},
    getTableSettings: [],
    setTableSettings: (data: any): void=> {},
});

const DangerousContextProvider: React.FC = (props) => {
    const model: string = 'DangerousGood';

    // States
    const [items, setItems] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [id, setId] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [sorting, setSorting] = useState<{ field: string, order: string }>({field: 'updated_at', order: 'desc'});
    const [filters, setFilters] = useState<Filters[]>([]);
    const [selectedArray, setSelectedArray] = useState<any[]>([]);
    const [updated, setUpdated] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [message, setMessage] = useState('');
    const [errorType, setErrorType] = useState<any>('success');
    const [preloder, setPreloder] = useState(false);
    const [tableSettings, setTableSettings] = useState<any[]>([]);

    // Get dangers data from API
    const {data, error, loading, refetch: refetchListViewData} = useDangersListQuery({
        variables: {
            model,
            search: search,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }, onCompleted: () => {
            if (data) {
                setHeaders(data.getDynamicTable.tableHeader);
                setItems(data.getDynamicTable.tableData);
                setCurrentPage(data.getDynamicTable.page);
                setItemsPerPage(data.getDynamicTable.limit);
                setCount(data.getDynamicTable.count);
                setSearch(data.getDynamicTable.search);
                setFilters(data.getDynamicTable.filters);
                setSorting(data.getDynamicTable.sorting);
            }
        }
    });
    const {data: settingsData, loading: settingsLoading, error: settingsError, refetch: refetchTableSettingsData} = useGetTableSettingsQuery({
        variables: {
            model,
        }, onCompleted: () => {
            if (settingsData) {
                setTableSettings(settingsData?.getTableSettings);

            }
        }
    });

    const [deleteDanger] = useDeleteDangerMutation();
    const [exportCsvDanger] = useExportCsvDangerMutation();
    const [importCsvDanger] = useImportCsvDangerMutation();
    const [setDynamicTable] = useSetTableSettingsMutation();

    if (loading) {
        return <Loader/>;
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    //------------------------------- Public methods -------------------------------------


    // Handle add
    const addItemHandler = () => {
        navigate(`/settings/dangers/new`, {replace: true});
    };

    // Handel delete
    const removeItemHandler = (id: string) => {
        if (id) {
            setId(id);
        }
        setOpen(true);

    }

    // Go to singe view
    const selectItemHandler = (id: string | number) => {
        navigate(`/settings/dangers/${id}`, {replace: true});
    };

    // Delete mutation
    const deleteDangerMutation = (id: string) => {
        setOpen(false);

        deleteDanger({
            variables: {
                id
            }
        }).then((res: any) => {
            refetchListViewData().then((res:ApolloQueryResult<DangersListQuery>) => {
                if (res) {
                    setHeaders(res.data.getDynamicTable.tableHeader);
                    setItems(res.data.getDynamicTable.tableData);
                    setCurrentPage(res.data.getDynamicTable.page);
                    setItemsPerPage(res.data.getDynamicTable.limit);
                    setCount(res.data.getDynamicTable.count);
                    setSearch(res.data.getDynamicTable.search);
                    setFilters(res.data.getDynamicTable.filters);
                    setSorting(res.data.getDynamicTable.sorting);
                }
            });
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    //Export CSV
    const exportCsvHandler = (exportType: string) => {
        setPreloder(true);
        exportCsvDanger({
            variables: {
                model,
                exportType: exportType,
                ids: selectedArray
            }
        }).then((res: any) => {
            refetchListViewData();
            window.location.href = res.data.exportModelData;
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    //Import CSV
    const importCsvHandler = (event: any) => {
        setLoadingCsv(true)
        const file = event;
        if (!file) return;

        importCsvDanger({
            variables: {
                model,
                file: file
            }
        }).then((res: any) => {
            setLoadingCsv(false)
            refetchListViewData();
            setErrorType('success');
            setMessage('Import completed successfully');
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false);
            }, 3000)
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Paginate listview table
    const onPaginateHandler = (limit: number, page: number) => {
        refetchListViewData({
            model,
            search,
            pagination: {
                limit: limit,
                page: page
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });

        setItemsPerPage(limit);
        setCurrentPage(page);
    };

    // Search
    const onSearchHandler = (searchText: string) => {
        setSearch(searchText);

        refetchListViewData({
            model,
            search: searchText,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Sort
    const onSortHandler = (field: string, order: string) => {
        setCurrentPage(0);
        setSorting({field, order});

        refetchListViewData({
            model,
            search,
            pagination: {
                limit: itemsPerPage,
                page: 0
            },
            sorting: {
                field,
                order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // multiselect
    const multiSelectedHendler = (idArray: string[]) => {
        setSelectedArray(idArray)
    }
    const multiEditHendler = () => {
        navigate(`/settings/dangers/multi-edit`, {replace: true, state:{ids:selectedArray}});
    }

    // Save table settings
    const onTableSettingsSetHandler = (data: any) => {

        setDynamicTable({
            variables: {
                model,
                data
            }
        }).then((res: any) => {
            refetchTableSettingsData();

            refetchListViewData()
                .then((res:ApolloQueryResult<DangersListQuery>) => {
                    if (res) {
                        setHeaders(res.data.getDynamicTable.tableHeader);
                        setItems(res.data.getDynamicTable.tableData);
                    }
                });
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }


    // Update data
    const onDataUpdateHandler= () => {
        refetchListViewData()
            .then((res:ApolloQueryResult<DangersListQuery>) => {
                if (res) {
                    setHeaders(res.data.getDynamicTable.tableHeader);
                    setItems(res.data.getDynamicTable.tableData);
                }
            });
    }

    // Set context values
    const contextValue: dynamicListView = {
        items: items,
        headers: headers,
        count: count,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        selectItem: selectItemHandler,
        onPaginate: onPaginateHandler,
        onSearch: onSearchHandler,
        onSort: onSortHandler,
        onDataUpdate: onDataUpdateHandler,
        page: currentPage,
        rowsPerPage: itemsPerPage,
        search: search,
        sorting: sorting,
        filters: filters,
        multiEdit: multiEditHendler,
        multiSelected: multiSelectedHendler,
        selected: selectedArray,
        exportCsv: exportCsvHandler,
        importCsv: importCsvHandler,
        getTableSettings: tableSettings,
        setTableSettings: onTableSettingsSetHandler
    };

    return (
        <DangerousContext.Provider value={contextValue}>
            {props.children}

            {updated &&
                <SnackbarNotification message={message} open={updated} type={errorType}/>
            }

            <ConfirmationDialog
                message="Are you sure you want to remove the item? This action cannot be undone!"
                title="Delete"
                button="Delete"
                open={open}
                onConfirm={() => deleteDangerMutation(id)}
                onCancel={closeDialog}/>
            {preloder &&
                <div className="preloader">
                    <CircularProgress color="secondary"/>
                </div>
            }
        </DangerousContext.Provider>
    );
};

export default DangerousContextProvider;
