import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField, Autocomplete, Checkbox, FormGroup, FormControlLabel,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const RelatedProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const accessories: { id: string, title: string, sku:string }[] = data.selectAccessories;
    const selectedAccessoriesObj = state.accessories ? state.accessories.map((id: string) => accessories.filter((item) => item.id === id)[0]) : (values.accessories ? values.accessories.map((id: string) => accessories.filter((item) => item.id === id)[0]) : []);

    const additionals: { id: string, title: string, sku:string }[] = data.selectAccessories;
    const selectedAdditionalObj = state.additionals ? state.additionals.map((id: string) => additionals.filter((item) => item.id === id)[0]) : (values.additionals ? values.additionals.map((id: string) => additionals.filter((item) => item.id === id)[0]) : []);

    // States
    const [selectedAccessories, setSelectedAccessories] = useState<any[]>(selectedAccessoriesObj);
    const [selectedAdditionals, setSelectedAdditionals] = useState<any[]>(selectedAdditionalObj);


    //------------------------------- Public methods -------------------------------------

    // Update platforms on change
    const handleAccessoriesChange = (value: any) => {
        setSelectedAccessories(value);
    };
    const handleAdditionalsChange = (value: any) => {
        setSelectedAdditionals(value);
    };


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <Typography variant="h6" mb={5}>
                        Ersatzteile
                    </Typography>
                    <Autocomplete
                        multiple
                        options={accessories}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title && option.sku ? option.title + ' (' + option.sku + ')' : ''}
                        value={selectedAccessories || []}
                        defaultValue={selectedAccessories || []}
                        onChange={(event: any, value: any) => {
                            handleAccessoriesChange(value);
                            onChange(event, 'accessories', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title + ' (' + option.sku + ')'}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Ersatzteile" placeholder="Ersatzteile" name="accessories"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearAccessories"
                                    checked={state.clearAccessories ? state.clearAccessories : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <Typography variant="h6" mb={5}>
                        Zubehör
                    </Typography>
                    <Autocomplete
                        multiple
                        options={additionals}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title && option.sku ? option.title + ' (' + option.sku + ')' : ''}
                        value={selectedAdditionals || []}
                        defaultValue={selectedAdditionals || []}
                        onChange={(event: any, value: any) => {
                            handleAdditionalsChange(value);
                            onChange(event, 'additionals', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title + ' (' + option.sku + ')'}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Zubehör" placeholder="Zubehör" name="additionals"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearAdditionals"
                                    checked={state.clearAdditionals ? state.clearAdditionals : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default RelatedProduct;
