import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../../components/ListViewTable";
import {ImportsContext} from "./store/imports-context";

// Types
import {HeadCell} from "../../../types/headCell";

// Styling
import {spacing} from "@mui/system";
import {Add as AddIcon} from "@mui/icons-material";
import SearchBar from "../../components/SearchBar";


function Imports() {
    const importsCtx = useContext(ImportsContext);

    // Set table headers data
    const headCells: Array<HeadCell> = importsCtx.headers;
    const model: string = 'Import';

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Imports"/>
            <Grid item xs={12} mb={10}>
                <Typography variant="h1" gutterBottom display="inline">
                    Import
                </Typography>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={importsCtx.search} onSearch={importsCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" onClick={importsCtx.addItem}  startIcon={<AddIcon/>}>
                            Neue Einfuhr
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={importsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={importsCtx.removeItem}
                    onSelectHandler={importsCtx.selectItem}
                    onPaginateHandler={importsCtx.onPaginate}
                    onSortHandler={importsCtx.onSort}
                    onDataUpdate={importsCtx.onDataUpdate}
                    count={importsCtx.count}
                    page={importsCtx.page}
                    rowsPerPage={importsCtx.rowsPerPage}
                    sorting={importsCtx.sorting}
                    filters={importsCtx.filters}
                    model={model}/>
            </Grid>
        </React.Fragment>
    );
}

export default Imports;
