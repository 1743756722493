import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {ArticlesContext} from "./store/articles-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {
    Add as AddIcon, FileUpload, FileDownload, BrowserUpdated, PictureAsPdf, Edit, MoreHoriz
} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Articles() {
    const articlesCtx = useContext(ArticlesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = articlesCtx.headers;
    const model = 'articles';

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Helmet title="Articles"/>
            <Grid item>
                <Typography variant="h1" gutterBottom display="inline">
                    Articles
                </Typography>
            </Grid>


            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={articlesCtx.search} onSearch={articlesCtx.onSearch}/>
                </Grid>

                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={articlesCtx.addItem} startIcon={<AddIcon/>}>
                            New Article
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (articlesCtx.exportCsv) {
                                    articlesCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV Export Articles
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (articlesCtx.exportCsv) {
                                    articlesCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Articles
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS Import Articles
                            </MenuItem>

                            {articlesCtx.selected && articlesCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={articlesCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Multi Edit
                                    </MenuItem>
                                    <MenuItem onClick={(event: any) => {
                                        if(articlesCtx.generatePdf){
                                            articlesCtx.generatePdf()
                                        }
                                        handleClose()
                                    }} disableRipple>
                                        <PictureAsPdf/>
                                        Generate Pdf
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (articlesCtx.importCsv) {
                                    articlesCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={articlesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={articlesCtx.removeItem}
                    onSelectHandler={articlesCtx.selectItem}
                    onPaginateHandler={articlesCtx.onPaginate}
                    onSortHandler={articlesCtx.onSort}
                    onDataUpdate={articlesCtx.onDataUpdate}
                    count={articlesCtx.count}
                    page={articlesCtx.page}
                    rowsPerPage={articlesCtx.rowsPerPage}
                    sorting={articlesCtx.sorting}
                    filters={articlesCtx.filters}
                    onMultiEditHandler={articlesCtx.multiSelected}
                    selectedArray={articlesCtx.selected}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Articles;
