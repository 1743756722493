import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../../components/ListViewTable";
import {ApprovalsContext} from "./store/approvals-context";

// Types
import {HeadCell} from "../../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz} from "@mui/icons-material";
import SearchBar from "../../components/SearchBar";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);

function Approvals() {
    const approvalsCtx = useContext(ApprovalsContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = approvalsCtx.headers;
    const model: string = 'Approval';

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Brand"/>
            <Grid item xs={12} mb={10}>
                <Typography variant="h1" gutterBottom display="inline">
                    Marke
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={approvalsCtx.search} onSearch={approvalsCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={approvalsCtx.addItem} startIcon={<AddIcon/>}>
                            Neue Marken
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (approvalsCtx.exportCsv) {
                                    approvalsCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV-Export Marke
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (approvalsCtx.exportCsv) {
                                    approvalsCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Marke
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS-Import Marke
                            </MenuItem>

                            {approvalsCtx.selected && approvalsCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={approvalsCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Mehrfachbearbeitung
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (approvalsCtx.importCsv) {
                                    approvalsCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={approvalsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={approvalsCtx.removeItem}
                    onSelectHandler={approvalsCtx.selectItem}
                    onPaginateHandler={approvalsCtx.onPaginate}
                    onSortHandler={approvalsCtx.onSort}
                    onDataUpdate={approvalsCtx.onDataUpdate}
                    count={approvalsCtx.count}
                    page={approvalsCtx.page}
                    rowsPerPage={approvalsCtx.rowsPerPage}
                    sorting={approvalsCtx.sorting}
                    filters={approvalsCtx.filters}
                    onMultiEditHandler={approvalsCtx.multiSelected}
                    selectedArray = {approvalsCtx.selected}
                    model = {model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Approvals;
