import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoDanger: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Beschreibung Fr"
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Beschreibung"
                        value={values.title ? values.title : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Beschreibung 2 Fr"
                        name="descriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.descriptionDe ? values.descriptionDe : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Beschreibung 2"
                        name="description"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.description ? values.description : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoDanger;
