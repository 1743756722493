import * as React from "react";
import {Navigate} from "react-router-dom";


interface AuthGuardType {
    children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({children}: AuthGuardType) {



    if (!localStorage.getItem('token')) {
        return <Navigate to="/auth/login"/>;
    }

    return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
