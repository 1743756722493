import React, {ChangeEvent, useContext, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert,
    Tabs,
    Tab,
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoCategory from "./fieldGroups/GeneralInfoCategory";
import SeoCategory from "./fieldGroups/SeoCategory";
import AtributesCategory from "./fieldGroups/AtributesCategory";
import {useTranslation} from "react-i18next";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Tree from "../../components/tree/Tree";
import {shallowEqual} from "react-redux";
import InlineRelatedModel from "../../components/inline-related-model/InlineRelatedModel";
import {RelationContext} from "../relation/store/relation-context";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    CategoryQuery,
    CreateCategoryMutation, InlineMediaUploadMutation, NewCategoryQuery,
    useCategoryQuery, useCreateCategoryMutation, useInlineMediaUploadMutation,
    useNewCategoryQuery, useResetCategoryMutation,
    useUpdateCategoryMutation
} from "../../graphql/category/category.graphql-gen";
import {InlineMediaUploadArticleMutation} from "../../graphql/article/article.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
});


const Category: React.FC = () => {
    let title: string = 'Neue Kategorie'
    let dataObj: any = {};
    const params = useParams();
    let id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'headerImages', 'files'];
    const navigate = useNavigate();
    const model: string = 'Category';
    const multipleSelect: boolean = false;
    let fromMediaCenter: boolean = false;
    const relationTabId: number = 5;
    const location: any = useLocation();
    const {i18n, t} = useTranslation();
    const relationCtx = useContext(RelationContext);

    // Queries
    const {data: data, error: error, loading: loading} = useCategoryQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: CategoryQuery) => {
            setImages(data.category.images);
            setMediaItems(data.category.images?.items);
            setFiles(data.category.files);
            setFilesItems(data.category.files?.items);
            setChecked(data.categoriesThree.checked);
            setCheckedShop(data.categoriesThree.checkedShop);
            setExpandedShop(data.categoriesThree.expandedShop);
            setUserId(data.user.user?.id);
            setProducts(data.category.category?.products);

            if (data.category && data.category.category) {
                setState(data.category.category);
                setInicialValue({
                    "data": data.category.category,
                    "images": data.category.images?.items,
                    "files": data.category.files?.items,
                    "checked": data.categoriesThree.checked,
                    "checkedShop": data.categoriesThree.checkedShop
                });
            }

            if (location.state.fromRelations && location.state.items) {
                setTabValue(relationTabId);
                setProducts(location.state.items);
                setState({...state, ['products']: location.state.items});
            }
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewCategoryQuery({
        variables: {
            fields: inlineModels,
            model
        },
        skip: id !== '',
        onCompleted: (data: NewCategoryQuery) => {
            setState({});
            setInicialValue({});

            setUserId(data.user.user?.id);

            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'files':
                            setFiles(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [updateCategory] = useUpdateCategoryMutation();
    const [createCategory] = useCreateCategoryMutation();
    const [inlineMediaUpload] = useInlineMediaUploadMutation();
    const [resetCategory] = useResetCategoryMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.category ? dataObj.category.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.category ? dataObj.category.images.items : []);
    const [files, setFiles] = useState<any>(dataObj.category ? dataObj.category.files : {});
    const [filesItems, setFilesItems] = useState<any>(dataObj.category ? dataObj.category.files.items : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [products, setProducts] = useState<any>(dataObj.category ? dataObj.category.products : {});
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(location.state?.submodelName ? 3 : (location.state?.fromMediaCenter ? 2 : 0));
    const [checked, setChecked] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checked : []);
    const [checkedShop, setCheckedShop] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checkedShop : []);
    const [expandedShop, setExpandedShop] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.expandedShop : []);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);
    const [userId, setUserId] = useState<any>();

    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.category && dataObj.category.category) {
        title = dataObj.category.category.title + ' (' + dataObj.category.category.code + ')';
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveCategory = (fieldName?: any, fieldType?: any, isMultiple?: any, submodelId?: any, submodelName?: string) => {
        if (id != '') {
            updateCategoryMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        } else {
            createCategoryMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        }
        setInicialValue({
            "data": state, "images": mediaItems, "files": filesItems,
            "checked": checked, "checkedShop": checkedShop
        });

    }

    // Update category mutation
    const updateCategoryMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean, submodelId?: any, submodelName?: string) => {
        updateCategory({
            variables: {
                model,
                id,
                data,
                parents: checked,
                parentsShop: checkedShop,
                images: mediaItems,
                files: filesItems,
                removedMedia,
                temp: fromMediaCenter
            }
        }).then(r => {

            setUpdated(true)
            setRemovedMedia([])
            setTimeout(() => {
                setUpdated(false);
            }, 3000)
            if (fromMediaCenter) {
                navigate(`/mediacenter/`, {
                    replace: true,
                    state: {fieldName, fieldType, isMultiple, modelName: model, modelId: id, submodelId, submodelName}
                })
            }

            relationCtx.related = [];

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        });
    }

    // Create category mutation
    const createCategoryMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?: boolean, submodelId?: any, submodelName?: string) => {
        createCategory({
            variables: {
                model,
                data,
                user: userId,
                parents: checked,
                parentsShop: checkedShop,
                images: mediaItems,
                files: filesItems,
                removedMedia,
                temp: fromMediaCenter
            },
        })
            .then((res: FetchResult<CreateCategoryMutation>) => {
                if (res.data && res.data.createCategory.category) {
                    setUpdated(true);
                    if (fromMediaCenter) {
                        navigate(`/mediacenter/`, {
                            replace: true,
                            state: {
                                fieldName,
                                fieldType,
                                isMultiple,
                                modelName: model,
                                modelId: res.data.createCategory.category.id,
                                submodelId,
                                submodelName
                            }
                        })
                    } else {
                        navigate(`/categories/${res.data.createCategory.category.id}`, {replace: true})
                    }
                    relationCtx.related = [];
                    setProducts([]);
                }
            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                             type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        resetCategory({
            variables: {
                model,
                id
            }
        }).then(r => {

            relationCtx.related = [];
            setProducts([]);

            if (!isLang) {
                navigate(`/categories`, {replace: true})
            } else {
                navigate(`/categories/${lang}/${id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        });
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "files": filesItems,
            "checked": checked, "checkedShop": checkedShop
        });
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }

    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "files": filesItems,
            "checked": checked, "checkedShop": checkedShop
        });
        i18n.changeLanguage(lang);
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/categories/${lang}/${id}`, {replace: true})
        }
    }

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'files',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true}
                                         type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...mediaItems];
        newItems[foundIndex] = updateData;
        setMediaItems(newItems);
    }
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        const newItems = [...filesItems];
        newItems[foundIndex] = updateData;
        setFilesItems(newItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onMediaBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveCategory('images', images.fieldType, images.isMultiple);
    }
    const onFilesBrowseHandler = () => {
        fromMediaCenter = true;
        onSaveCategory('files', files.fieldType, files.isMultiple);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {
            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }
        // Update select value
        setState({...state, [fieldName]: value});

    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const treeHandlerCheckShop = (checkedArr: any, targetNode: any) => {
        if (multipleSelect) {
            setCheckedShop(checkedArr)
        } else {
            if (checkedArr.length != 0) {
                setCheckedShop([targetNode.value.toString()])
            } else {
                setCheckedShop([]);
            }
        }
    }
    const treeHandlerExpandShop = (event: any) => {
        setExpandedShop(event)
    }

    // Remove product relations
    const onProductRelationsRemoveHandler = (id: number | string) => {
        const updatedProducts = products.filter((item: any) => item.id !== id);

        setState({...state, ['products']: updatedProducts});
        setProducts(updatedProducts);
        relationCtx.related = updatedProducts;
    }

    return (
        <React.Fragment>
            <Helmet title="Category"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>
                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Divider my={6}/>
            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveCategory}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                    <Tab label="Kategorien" {...tabsProps(1)} />
                                                    <Tab label="Medien" {...tabsProps(2)} />
                                                    <Tab label="SEO" {...tabsProps(3)} />
                                                    <Tab label="Eigenschaften" {...tabsProps(4)} />
                                                    <Tab label="Artikel" {...tabsProps(5)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoCategory
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}

                                                    />
                                                </TabPanel>
                                                <Grid container spacing={12}>
                                                    <Grid item xs={12} md={6} mb={5}>
                                                        <TabPanel value={tabValue} index={1}>
                                                            <Grid container spacing={6}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" mb={5}>
                                                                        Kategorie
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Tree
                                                                data={dataObj.categoriesThree.categoriesShop}
                                                                checked={checkedShop}
                                                                expanded={expandedShop}
                                                                onCheck={treeHandlerCheckShop}
                                                                onExpand={treeHandlerExpandShop}/>
                                                        </TabPanel>
                                                    </Grid>
                                                </Grid>
                                                <TabPanel value={tabValue} index={2}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Bilder
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onMediaBrowseHandler}
                                                        languages={images.languages}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Dateien
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={files.allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onFilesBrowseHandler}
                                                        languages={files.languages}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={3}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Werbeaktionen
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <SeoCategory
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Eigenschaften
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <AtributesCategory
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={5}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Artikel
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineRelatedModel
                                                        model='product'
                                                        path='categories'
                                                        id={id}
                                                        title='Artikel'
                                                        items={products}
                                                        removeRelation={onProductRelationsRemoveHandler}
                                                        onDialogConfirm={onSaveCategory}/>
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }

            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Category;
