import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Typography,
    Button
} from "@mui/material";
import ListViewMediacenterTable from "../components/ListViewMediacenterTable";
import {MediacenterContext} from "./store/mediacenter-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import {CreateNewFolder, CloudUpload, KeyboardReturn, Publish} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import {useLocation} from "react-router-dom";
import {X as CloseIcon} from "react-feather";
import ImportExportIcon from '@mui/icons-material/ImportExport';


function Mediacenter() {
    const mediacenterCtx = useContext(MediacenterContext);
    const location: any = useLocation();

    // Set table headers data
    const headCells: Array<HeadCell> = mediacenterCtx.headers;

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Mediacenter"/>
            <Grid item xs={12}>
                <Typography variant="h1" gutterBottom display="inline">
                    {mediacenterCtx.folderTitle ? mediacenterCtx.folderTitle : 'Mediacenter'}
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={mediacenterCtx.search} onSearch={mediacenterCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        {location.state?.modelId &&
                            <Button variant="contained" color="inherit" onClick={mediacenterCtx.backToModel} className="multi-btn" >
                                <CloseIcon/>
                            </Button>
                        }
                        {mediacenterCtx.selected && mediacenterCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={mediacenterCtx.addToModel}
                                    className="multi-btn" startIcon={<Publish/>}>
                                Hochladen auf {location.state.modelName}
                            </Button>
                        }
                        {mediacenterCtx.folderTitle &&
                            <Button variant="contained" color="primary" onClick={mediacenterCtx.backFolder}
                                    className="multi-btn" startIcon={<KeyboardReturn/>}>
                                Zurück
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={mediacenterCtx.addFolder} className="multi-btn" startIcon={<CreateNewFolder />} >
                            Neuer Folder
                        </Button>

                        <Button className="multi-btn" variant="contained" color="primary" onClick={mediacenterCtx.addItem} startIcon={<CloudUpload/>}>
                            Neue Datei
                        </Button>

                        <Button variant="contained" color="primary" onClick={mediacenterCtx.exportMedia} startIcon={<ImportExportIcon/>}>
                            Export
                        </Button>
                    </div>
                </Grid>
            </Grid>


            <Grid item xs={12} className="shadow">
                <ListViewMediacenterTable
                    key='list'
                    rows={mediacenterCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={mediacenterCtx.removeItem}
                    onSelectFolderHandler={mediacenterCtx.selectFolder}
                    onSelectHandler={mediacenterCtx.selectItem}
                    onPaginateHandler={mediacenterCtx.onPaginate}
                    onSortHandler={mediacenterCtx.onSort}
                    onDataUpdate={mediacenterCtx.onDataUpdate}
                    count={mediacenterCtx.count}
                    page={mediacenterCtx.page}
                    rowsPerPage={mediacenterCtx.rowsPerPage}
                    filters={mediacenterCtx.filters}
                    onMultiEditHandler={mediacenterCtx.multiSelected}
                    selectedArray = {mediacenterCtx.selected}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Mediacenter;
