import React, {ChangeEvent, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar,
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import GeneralInfoPlatform from "./fieldGroups/GeneralInfoPlatform";
import TabPanel from "../components/TabPanel";
import {useTranslation} from "react-i18next";


// GraphQL
import {FetchResult} from "@apollo/client";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {
    CreatePlatformMutation, NewPlatformQuery,
    PlatformQuery,
    useCreatePlatformMutation, useNewPlatformQuery,
    usePlatformQuery,
    useUpdatePlatformMutation
} from "../../graphql/platform/platform.graphql-gen";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";
import france from "../../assets/img/france.svg";




const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
});



const Platform: React.FC = () => {
    let title: string = 'Neue Plattform'
    const model: string = 'Platform'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    // Queries
    const {data: data, error: error, loading: loading} = usePlatformQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: PlatformQuery) => {
            if (data && data.platform) {
                setState(data.platform.platform);
                setInicialValue(data.platform.platform);
            }
            setUserId(data.user.user?.id);
        }
    });

    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewPlatformQuery({
        skip: id !== '',
        onCompleted: (data: NewPlatformQuery) => {
            setState({});
            setInicialValue({});
            setUserId(data.user.user?.id);
        }
    });


    const [updatePlatform] = useUpdatePlatformMutation();
    const [createPlatform] = useCreatePlatformMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [userId, setUserId] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);

    useEffect(() => {
        if (id === '') {
            setState({});
            setInicialValue({});
        }
    }, []);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }

    // Set title
    if (dataObj.platform) {
        title = dataObj.platform.platform.title;
    }



    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSavePlatform = () => {
        if (id != '') {
            updatePlatformMutation(state);
        } else {
            createPlatformMutation(state);
        }
        setInicialValue(state);
    }

    // Update mutation
    const updatePlatformMutation = (data: any) => {
        updatePlatform({
            variables: {
                model,
                id,
                data
            }
        })
            .then(r => {
                setUpdated(true)
                setTimeout(() => {
                    setUpdated(false);
                }, 3000)

            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    // Create mutation
    const createPlatformMutation = (data: any) => {

        createPlatform({
            variables: {
                model,
                data,
                user: userId
            },
        }).then((res: FetchResult<CreatePlatformMutation>) => {
            if (res.data && res.data.createPlatform.platform) {
                setUpdated(true);
                navigate(`/platforms/${res.data.createPlatform.platform.id}`, {replace: true})
            }
        })
            .catch(error => {
                return  <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        if(!isLang){
            navigate(`/platforms`, {replace: true})
        }else{
            navigate(`/platforms/${lang}/${id}`, {replace: true})
        }
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, state);
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, state);
        setLang(lang);
        setIsLang(true);
        i18n.changeLanguage(lang);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/platforms/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (event.target) {
            fieldName = event.target.name;
            value = event.target.value;
        }

        if (field) {
            fieldName = field
            value = event
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Platform"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {id &&
                            <div>
                                <Chip className="lang-nav active"  variant="outlined" avatar={<Avatar src={germany} />} label="De" />
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {openTranslateDialog('fr') }} clickable={true} avatar={<Avatar src={france} />} label="Fr" />
                            </div>
                        }
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSavePlatform}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoPlatform
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }
            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Platform;
