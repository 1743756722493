import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Checkbox,
    FormGroup, FormControlLabel, Divider as MuiDivider, FormControl, InputLabel, Select, MenuItem, Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Styles
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

// Styling
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Divider = styled(MuiDivider)(spacing);


const ErpProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const unitOfMeasure: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'unitOfMeasure');
    const selectedProductUnitOfMeasureObj = state.unitOfMeasure ? state.unitOfMeasure : (values.unitOfMeasure ? values.unitOfMeasure[0] : '');
    const companies: { id: string, title: string }[] = data.selectCompanies;
    const selectedCompaniesObj = state.companies ? state.companies.map((id: string) => companies.filter((item) => item.id === id)[0]) : (values.companies ? values.companies.map((id: string) => companies.filter((item) => item.id === id)[0]) : []);
    const selectedAssortmentItemsObj = state.assortmentItems ? state.assortmentItems : '';
    const selectedLvsStockItemsObj = state.lvsStockItems ? state.lvsStockItems : '';
    const selectedCatalogItemsObj = state.catalogItems ? state.catalogItems : '';
    const selectedCustomerItemsObj = state.customerItems ? state.customerItems : '';


    // States
    const [selectedCompanies, setSelectedCompanies] = useState<any[]>(selectedCompaniesObj);
    const [selectedProductUnitOfMeasure, setSelectedProductUnit] = useState<any[]>(selectedProductUnitOfMeasureObj);
    const [selectedAssortmentItems, setSelectedAssortmentItems] = useState<any[]>(selectedAssortmentItemsObj);
    const [selectedLvsStockItems, setSelectedLvsStockItems] = useState<any[]>(selectedLvsStockItemsObj);
    const [selectedCatalogItems, setSelectedCatalogItems] = useState<any[]>(selectedCatalogItemsObj);
    const [selectedCustomerItems, setSelectedCustomerItems] = useState<any[]>(selectedCustomerItemsObj);

    //------------------------------- Public methods -------------------------------------

    // Update Companies on change
    const handleCompaniesChange = (value: any) => {
        setSelectedCompanies(value);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                ERP-Felder
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Basiseinheitencode</InputLabel>
                        <Select
                            name="unitOfMeasure"
                            value={selectedProductUnitOfMeasure}
                            label="Basiseinheitencode"
                            onChange={(event: any) => {
                                setSelectedProductUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {unitOfMeasure.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearUnitOfMeasure"
                                    checked={state.clearUnitOfMeasure ? state.clearUnitOfMeasure : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="price"
                        label="Einstandspreis"
                        type="number"
                        value={values.price ? values.price : ''}
                        error={Boolean(touched.price && errors.price)}
                        fullWidth
                        helperText={touched.price && errors.price}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPrice"
                                    checked={state.clearPrice ? state.clearPrice : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Preise
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="vk1"
                        label="VK-1"
                        type="number"
                        value={values.vk1 ? values.vk1 : ''}
                        error={Boolean(touched.vk1 && errors.vk1)}
                        fullWidth
                        helperText={touched.vk1 && errors.vk1}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVk1"
                                    checked={state.clearVk1 ? state.clearVk1 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="vk2"
                        label="VK-2"
                        type="number"
                        value={values.vk2 ? values.vk2 : ''}
                        error={Boolean(touched.vk2 && errors.vk2)}
                        fullWidth
                        helperText={touched.vk2 && errors.vk2}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVk2"
                                    checked={state.clearVk2 ? state.clearVk2 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="vk3"
                        label="VK-3"
                        type="number"
                        value={values.vk3 ? values.vk3 : ''}
                        error={Boolean(touched.vk3 && errors.vk3)}
                        fullWidth
                        helperText={touched.vk3 && errors.vk3}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVk3"
                                    checked={state.clearVk3 ? state.clearVk3 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="vk4"
                        label="VK-4"
                        type="number"
                        value={values.vk4 ? values.vk4 : ''}
                        error={Boolean(touched.vk4 && errors.vk4)}
                        fullWidth
                        helperText={touched.vk4 && errors.vk4}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVk4"
                                    checked={state.clearVk4 ? state.clearVk4 : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Lieferanten
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={4} mb={5}>
                    <Autocomplete
                        multiple
                        options={companies}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedCompanies || []}
                        defaultValue={selectedCompanies || []}
                        onChange={(event: any, value: any) => {
                            handleCompaniesChange(value);
                            onChange(event, 'companies', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Lieferanten" placeholder="Lieferanten" name="companies"/>
                        )}
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCompanies"
                                    checked={state.clearCompanies ? state.clearCompanies : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
              {/*  <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="supplierItemName"
                        label="Lieferant Artikelbezeichnung"
                        value={values.supplierItemName ? values.supplierItemName : ''}
                        error={Boolean(touched.supplierItemName && errors.supplierItemName)}
                        fullWidth
                        helperText={touched.supplierItemName && errors.supplierItemName}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSupplierItemName"
                                    checked={state.clearSupplierItemName ? state.clearSupplierItemName : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>*/}
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="creditArticleNumber"
                        label="Kred.-Artikelnr."
                        value={values.creditArticleNumber ? values.creditArticleNumber : ''}
                        error={Boolean(touched.creditArticleNumber && errors.creditArticleNumber)}
                        fullWidth
                        helperText={touched.creditArticleNumber && errors.creditArticleNumber}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCreditArticleNumber"
                                    checked={state.clearCreditArticleNumber ? state.clearCreditArticleNumber : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="eanCode"
                        label="EAN-Code"
                        type="number"
                        value={values.eanCode ? values.eanCode : ''}
                        error={Boolean(touched.eanCode && errors.eanCode)}
                        fullWidth
                        helperText={touched.eanCode && errors.eanCode}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearEanCode"
                                    checked={state.clearEanCode ? state.clearEanCode : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Lagerbestand
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Sortimentsartikel</InputLabel>
                        <Select
                            name="assortmentItems"
                            value={selectedAssortmentItems}
                            label="Sortimentsartikel"
                            onChange={(event: any) => {
                                setSelectedAssortmentItems(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="Ja" value="Ja">
                                Ja
                            </MenuItem>
                            <MenuItem key="Nein" value="Nein">
                                Nein
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearAssortmentItems"
                                    checked={state.clearAssortmentItems ? state.clearAssortmentItems : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>LVS Lagerartikel</InputLabel>
                        <Select
                            name="lvsStockItems"
                            value={selectedLvsStockItems}
                            label="LVS Lagerartikel"
                            onChange={(event: any) => {
                                setSelectedLvsStockItems(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="Ja" value="1">
                                Ja
                            </MenuItem>
                            <MenuItem key="Nein" value="0">
                                Nein
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearLvsStockItems"
                                    checked={state.clearLvsStockItems ? state.clearLvsStockItems : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Katalogartikel</InputLabel>
                        <Select
                            name="catalogItems"
                            value={selectedCatalogItems}
                            label="Katalogartikel"
                            onChange={(event: any) => {
                                setSelectedCatalogItems(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="Ja" value="1">
                                Ja
                            </MenuItem>
                            <MenuItem key="Nein" value="0">
                                Nein
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCatalogItems"
                                    checked={state.clearCatalogItems ? state.clearCatalogItems : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Kundenartikel</InputLabel>
                        <Select
                            name="customerItems"
                            value={selectedCustomerItems}
                            label="Kundenartikel"
                            onChange={(event: any) => {
                                setSelectedCustomerItems(event.target.value);
                                onChange(event)
                            }}
                        >
                            <MenuItem key="Ja" value="1">
                                Ja
                            </MenuItem>
                            <MenuItem key="Nein" value="0">
                                Nein
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCustomerItems"
                                    checked={state.clearCustomerItems ? state.clearCustomerItems : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Attribute
            </Typography>
                <Grid container spacing={12}>
                    <Grid item xs={12} md={3} mb={5}>
                        <TextField
                            name="grossWeight"
                            label="Bruttogewicht"
                            type="number"
                            value={values.grossWeight ? values.grossWeight : ''}
                            error={Boolean(touched.grossWeight && errors.grossWeight)}
                            fullWidth
                            helperText={touched.grossWeight && errors.grossWeight}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                        {multiEdit &&
                            <FormGroup className="clear-check">
                                <FormControlLabel control={
                                    <Checkbox
                                        name="clearGrossWeight"
                                        checked={state.clearGrossWeight ? state.clearGrossWeight : false}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                    />
                                } label="Löschen"/>
                            </FormGroup>
                        }
                    </Grid>
                    <Grid item xs={12} md={3} mb={5}>
                        <TextField
                            name="netWeight"
                            label="Nettogewicht"
                            type="number"
                            value={values.netWeight ? values.netWeight : ''}
                            error={Boolean(touched.netWeight && errors.netWeight)}
                            fullWidth
                            helperText={touched.netWeight && errors.netWeight}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <React.Fragment ><span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                        {multiEdit &&
                            <FormGroup className="clear-check">
                                <FormControlLabel control={
                                    <Checkbox
                                        name="clearNetWeight"
                                        checked={state.clearNetWeight ? state.clearNetWeight : false}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                    />
                                } label="Löschen"/>
                            </FormGroup>
                        }
                    </Grid>
                    <Grid item xs={12} md={3} mb={5}>
                        <TextField
                            name="valuePointsCalculation"
                            label="Wert für Punkteberechnung"
                            type="number"
                            value={values.valuePointsCalculation ? values.valuePointsCalculation : ''}
                            error={Boolean(touched.valuePointsCalculation && errors.valuePointsCalculation)}
                            fullWidth
                            helperText={touched.valuePointsCalculation && errors.valuePointsCalculation}
                            onChange={onChange}
                            variant="outlined"
                        />
                        {multiEdit &&
                            <FormGroup className="clear-check">
                                <FormControlLabel control={
                                    <Checkbox
                                        name="clearValuePointsCalculation"
                                        checked={state.clearValuePointsCalculation ? state.clearValuePointsCalculation : false}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                    />
                                } label="Löschen"/>
                            </FormGroup>
                        }
                    </Grid>
                    <Grid item xs={12} md={3} mb={5}>
                        <TextField
                            name="unitPointsCalculation"
                            label="Einheit für Punkteberechnung"
                            value={values.unitPointsCalculation ? values.unitPointsCalculation : ''}
                            error={Boolean(touched.unitPointsCalculation && errors.unitPointsCalculation)}
                            fullWidth
                            helperText={touched.unitPointsCalculation && errors.unitPointsCalculation}
                            onChange={onChange}
                            variant="outlined"
                        />
                        {multiEdit &&
                            <FormGroup className="clear-check">
                                <FormControlLabel control={
                                    <Checkbox
                                        name="clearUnitPointsCalculation"
                                        checked={state.clearUnitPointsCalculation ? state.clearUnitPointsCalculation : false}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                    />
                                } label="Löschen"/>
                            </FormGroup>
                        }
                    </Grid>
                </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Menge
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="orderQuantity"
                        label="Bestellmenge"
                        type="number"
                        value={values.orderQuantity ? values.orderQuantity : ''}
                        error={Boolean(touched.orderQuantity && errors.orderQuantity)}
                        fullWidth
                        helperText={touched.orderQuantity && errors.orderQuantity}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearOrderQuantity"
                                    checked={state.clearOrderQuantity ? state.clearOrderQuantity : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="quantityPerPackaging"
                        label="Menge pro Verpackung"
                        type="number"
                        value={values.quantityPerPackaging ? values.quantityPerPackaging : ''}
                        error={Boolean(touched.quantityPerPackaging && errors.quantityPerPackaging)}
                        fullWidth
                        helperText={touched.quantityPerPackaging && errors.quantityPerPackaging}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearQuantityPerPackaging"
                                    checked={state.clearQuantityPerPackaging ? state.clearQuantityPerPackaging : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="quantityPerPallet"
                        label="Menge pro Palette"
                        type="number"
                        value={values.quantityPerPallet ? values.quantityPerPallet : ''}
                        error={Boolean(touched.quantityPerPallet && errors.quantityPerPallet)}
                        fullWidth
                        helperText={touched.quantityPerPallet && errors.quantityPerPallet}
                        onChange={onChange}
                        variant="outlined"
                    />
                    <React.Fragment ><span style={{fontSize: 13, color:"blue"}}>Erp *</span> <span style={{fontSize: 13, color:"green"}}>Pim *</span> <span style={{fontSize: 13, color:"orange"}}>Shop *</span></React.Fragment>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearQuantityPerPallet"
                                    checked={state.clearQuantityPerPallet ? state.clearQuantityPerPallet : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default ErpProduct;
