import React from "react";
import {Button, Grid} from "@mui/material";

import TechnicalSpecificationItem from "./TechnicalSpecificationItem";

// Types
import {
    InlineTechnicalSpecificationType,
    TechnicalSpecificationItemType
} from "../../../types/inlineTechnicalSpecificationsType";

// Styling
import {Plus} from "react-feather";



const InlineTechnicalSpecifications: React.FC<InlineTechnicalSpecificationType> = (props) => {
    const {items, onUpdate, onAdd, onDelete, multiEdit, technicalData} = props;

    // Trigger element update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }

    // Trigger element delete
    const onDeleteHandler = (data: TechnicalSpecificationItemType) => {
        onDelete(data.id);
    }

    const addElement = () => {
        const newElement = {
            id: new Date().getTime().toString(),
            selectedType: '',
            selectedOption: '',
            sorting: '',
        }
        onAdd(newElement);
    }


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                {items.length > 0 &&
                    <Grid item xs={12}>
                        {items.map((item: TechnicalSpecificationItemType) => {
                            return (
                                <TechnicalSpecificationItem
                                    key={item.id}
                                    item={item}
                                    technicalData={technicalData}
                                    onUpdate={onUpdateHandler}
                                    onDelete={onDeleteHandler}
                                    multiEdit={multiEdit}
                                />
                            )
                        })}
                    </Grid>
                }
                <Grid item xs={12} mb={5}>
                    <Button variant="contained" component="span" startIcon={<Plus/>} onClick={addElement}>Attribut hinzufügen</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default InlineTechnicalSpecifications;
