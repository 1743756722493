import React, {ChangeEvent, useState} from "react";
import {MediaItemType} from "../../types/inlineMedia";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField as MuiTextField,
    Typography
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Lightbox from "react-image-lightbox";

// Icons
import {Trash2 as TrashIcon} from "react-feather";
import {Download as Download} from "react-feather";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {RemoveRedEye as RemoveRedEyeIcon} from "@mui/icons-material";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const MediaItem: React.FC<{ item: MediaItemType, fieldType: string, languages: [], onUpdate: (data: any) => void, onDelete: (data: any) => void }> = (props) => {
    const [item, setItem] = useState(props.item);
    const [lightbox, setLightbox] = useState(false);

    // States
    const [selectedLanguage, setSelectedLanguage] = useState(props.languages ? props.languages : '');


    //------------------------------- Public methods -------------------------------------

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setItem({...item, [fieldName]: value});
    };

    // trigger update
    const updateHandler = () => {
        props.onUpdate(item);
    };

    // Trigger delete
    const deleteHandler = () => {
        props.onDelete(item);
    };

    // Preview image in lightbox
    const onPreview = () => {
        setLightbox(true);
    };


    // File download
    const download = (e: any, fileName: string,  name: string) => {
        e.preventDefault();
        fetch(fileName, {
            method: 'GET',
            headers: {
                //'Content-Type': mimeType,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name,
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if(link.parentNode){
                    link.parentNode.removeChild(link);
                }

            });
    };

    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={9} md={10}>
                    <Accordion className="media-item-accordion" sx={{borderRadius: 6, borderColor: 'rgba(0,0,0,0.12)'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            sx={{margin: '0'}}
                        >
                            <Stack direction="row" spacing={2} alignItems="center" sx={{padding: '0 10px'}}>
                                {(() => {
                                    if ((props.fieldType == 'mediacenterFile' && item.thumbnail !='') || props.fieldType == 'image') {
                                        return (
                                            <Avatar src={item.thumbnail}/>
                                        )
                                    } else {
                                        return (
                                            <Avatar><AssignmentIcon/></Avatar>
                                        )
                                    }
                                })()}
                                <Typography>{item.fileName}</Typography>
                            </Stack>

                        </AccordionSummary>
                        <AccordionDetails className="media-item-content">
                            <Grid container spacing={6}>
                                <Grid item md={4}>
                                    <TextField
                                        name="title"
                                        label="Titel"
                                        value={item.title}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <TextField
                                        name="altTitle"
                                        label="Alter Titel"
                                        value={item.altTitle}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item md={2.5}>
                                    <TextField
                                        name="author"
                                        label="Autor"
                                        value={item.author}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item md={1.5} mt={2}>
                                    <TextField
                                        name="lang"
                                        value={selectedLanguage}
                                        label="Sprache"
                                        fullWidth
                                        select
                                        variant="outlined"
                                        onChange={(event: any) => {
                                            setSelectedLanguage(event.target.value);
                                        }}
                                    >
                                     {/*   <MenuItem key='55' value='test'>
                                            test
                                        </MenuItem>*/}


                                    </TextField>
                                </Grid>


                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        name="description"
                                        label="Beschreibung"
                                        value={item.description}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={3} md={2}>
                    {props.fieldType !== 'image'
                        ? <IconButton aria-label="details" size="large" href={item.filePath} target="_blank"> <RemoveRedEyeIcon/> </IconButton>
                        : <IconButton aria-label="details" size="large" onClick={() => onPreview()}> <RemoveRedEyeIcon/> </IconButton>
                    }

                    <a href={item.filePath} download onClick={e => download(e, item.filePath, item.fileName)}>
                        <IconButton aria-label="download" size="large">
                            <Download/>
                        </IconButton>
                    </a>
                    <IconButton aria-label="delete" size="large"
                                onClick={() => deleteHandler()}>
                        <TrashIcon/>
                    </IconButton>
                </Grid>
            </Grid>

            {lightbox && (
                <Lightbox
                    mainSrc={item.filePath}
                    onCloseRequest={() => setLightbox(false)}
                />
            )}
        </React.Fragment>
    );
}

export default MediaItem;
