import React, {ChangeEvent, useEffect, useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    Grid,
    IconButton, InputLabel, MenuItem, Select,
    Stack,
    TextField as MuiTextField,
    Typography
} from "@mui/material";


// Types
import {TechnicalSpecificationItemType} from "../../../types/inlineTechnicalSpecificationsType";

// Icons
import {Trash2 as TrashIcon} from "react-feather";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const TechnicalSpecificationItem: React.FC<{ item: TechnicalSpecificationItemType, onUpdate: (data: any) => void, onDelete: (data: any) => void, multiEdit?: boolean, technicalData: any[] }> = (props) => {
    const {technicalData} = props;
    const [item, setItem] = useState(props.item);

    const [selectedType, setSelectedType] = useState<number | string>(item.selectedType ? +item.selectedType : '');
    const [typesData, setTypesData] = useState<any[]>(selectedType !== '' ? technicalData.find(item => item.id == selectedType).options : []);
    const [selectedOption, setSelectedOption] = useState<string>(item.selectedOption);
    const [selectedTypeData, setSelectedTypeData] = useState<any>(selectedType  !=='' ? technicalData.find(item => item.id == selectedType) : {title: 'Neue Spezifikation', code: '-'});


    //------------------------------- Public methods -------------------------------------

    useEffect(() => {
        props.onUpdate(item);
    }, [item]);

    const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;
        setSelectedType(event.target.value);

        setTypesData(technicalData.find(item => item.id == event.target.value).options);
        setSelectedOption('');
        setSelectedTypeData(technicalData.find(item => item.id == event.target.value));

        // Update select value
        setItem({...item, [fieldName]: value, 'selectedOption': ''});
    };

    const handleOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        setSelectedOption(event.target.value);

        // Update select value
        setItem({...item, [fieldName]: value});
    };

    // Trigger delete
    const deleteHandler = () => {
        props.onDelete(item);
    };


    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={9} md={10}>
                    <Accordion className="media-item-accordion" sx={{borderRadius: 6, borderColor: 'rgba(0,0,0,0.12)'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            sx={{margin: '0'}}
                        >
                            <Stack direction="row" spacing={2} alignItems="center" sx={{padding: '0 10px'}}>
                                    <Typography>{selectedTypeData.title}</Typography>
                                    <Typography>({selectedTypeData.code})</Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails className="media-item-content">
                            <Grid container spacing={6} mb={5} alignItems="center">
                                <Grid item xs={12} md={6} mb={5}>
                                    <FormControl className="full-width">
                                        <InputLabel>Typ</InputLabel>
                                        <Select
                                            name="selectedType"
                                            value={selectedType}
                                            label="Typ"
                                            onChange={(e: any) => {
                                                handleTypeChange(e);
                                            }}
                                        >
                                            {technicalData.map((type: { id: string, title: string, code: string }) => (
                                                <MenuItem key={type.id} value={type.id}>
                                                    {type.title} ({type.code})
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} mb={5}>
                                    <FormControl className="full-width">
                                        <InputLabel>Optionen</InputLabel>
                                        <Select
                                            name="selectedOption"
                                            value={selectedOption}
                                            label="Optionen"
                                            required={true}
                                            onChange={(e: any) => {
                                                handleOptionChange(e);
                                            }}
                                        >
                                            {typesData.map((type: { id: string, title: string }) => (
                                                <MenuItem key={type.id} value={type.id}>
                                                    {type.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={3} md={2}>
                    <IconButton aria-label="delete" size="large"
                                onClick={() => deleteHandler()}>
                        <TrashIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default TechnicalSpecificationItem;
