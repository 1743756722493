import React from "react";
import {Box} from "@mui/material";

type TabPanelType = {
    children: React.ReactNode,
    index: number,
    value: number,
};

const TabPanel: React.FC<TabPanelType> = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{mt:10, px:5}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel;
