import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";
import {Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import SnackbarNotification from "../../../components/SnackbarNotification";
import TabPanel from "../../components/TabPanel";
import GeneralInfoDanger from "./fieldGroups/translate/GeneralInfoDanger";

// GraphQL
import {
    TranslateDangerQuery,
    useTranslateDangerQuery,
    useUpdateDangerMutation
} from "../../../graphql/settings/danger/danger.graphql-gen";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../../assets/img/england.svg";
import germany from "../../../assets/img/germany.svg";
import france from "../../../assets/img/france.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);



const DangerTranslate: React.FC = () => {
    let title: string = 'Neues Gefahrgut'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const model: string = 'DangerousGood';

    // Queries
    const {data: data, error: error, loading: loading} = useTranslateDangerQuery({
        variables: {id, model, language: params.lang ? params.lang : ''},
        skip: id === '',
        onCompleted: (data: TranslateDangerQuery) => {
            if (data.translateDangerousGood && data.translateDangerousGood.dangerousgood) {
                setState(data.translateDangerousGood.dangerousgood);
                setInicialValue({
                    "data": data.translateDangerousGood.dangerousgood
                });
            }
        }
    });
    const [updateDanger] = useUpdateDangerMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>(params.lang ? params.lang : '')
    const [isLang, setIsLang] = useState<boolean>(false);

    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }

    // Set title
    if (dataObj.translateDangerousGood && dataObj.translateDangerousGood.dangerousGood) {
        title = dataObj.translateDangerousGood.dangerousGood.title;
    }

    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveDanger = () => {
        if (id !== '') {
            updateDangerMutation(state);
        }
        setInicialValue({
            "data": state
        });
    }

    // Update danger mutation
    const updateDangerMutation = (data: any) => {
        updateDanger({
            variables: {
                model,
                id,
                data,
                removedMedia
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }

    //Back to ListView
    const backToListView = () => {
        if(!isLang){
            navigate(`/settings/dangers`, {replace: true})
        }else if(lang==''){
            navigate(`/settings/dangers/${id}`, {replace: true})
        }else{
            navigate(`/settings/dangers/${lang}/${id}`, {replace: true});
            setOpen(false);
        }
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state});
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state});
        setIsLang(true);
        if (hasChanged) {
            setLang(lang);
            setOpen(true);
        } else {
            if(lang ==''){
                navigate(`/settings/dangers/${id}`, {replace: true})
            }else{
                navigate(`/settings/dangers/${lang}/${id}`, {replace: true})
            }
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;


        if (event.target) {
            fieldName = event.target.name;
            value = event.target.value;
        }

        if (field) {
            fieldName = field
            value = event
        }

        // Update select value
        setState({...state, [fieldName]: value});
    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Dangerous Goods"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Chip className="lang-nav"  variant="outlined" onClick={(lang) => {openTranslateDialog('') }}  clickable={true} avatar={<Avatar src={germany} />} label="De" />
                        <Chip className={`lang-nav ${params.lang=='fr' ? "active" : ""}`} variant="outlined" onClick={(lang) => {openTranslateDialog('de') }} clickable={true} avatar={<Avatar src={france} />} label="Fr" />
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>
                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    onSubmit={onSaveDanger}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoDanger
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }
            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default DangerTranslate;
