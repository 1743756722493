import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoSymbol: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange, state, multiEdit} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label={multiEdit ? 'Titel' : 'Titel *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label='Titel FRS'
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoSymbol;
