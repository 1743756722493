import React, {useContext, useState} from "react";
import {Button, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog";

// Types
import {InlineRelationType} from "../../types/inlineRelation";

// Styling
import {RelationContext} from "../../pages/relation/store/relation-context";
import {Plus, X as CloseIcon} from "react-feather";


const InlineRelatedModel: React.FC<InlineRelationType> = (props) => {
    const {model, title, path, id, items, removeRelation, onDialogConfirm} = props;
    const relationCtx = useContext(RelationContext);
    const navigate = useNavigate();

    // States
    const [open, setOpen] = useState<boolean>(false);


    //------------------------------- Public methods -------------------------------------

    // Navigate to relations list view
    const addRelation = () => {
        relationCtx.model = model;
        relationCtx.title = title;
        relationCtx.path = path;
        relationCtx.id = id;

        if (props.items.length > 0) {
            relationCtx.related = props.items;
        }
        navigate(`/relation`, {replace: true});
    }

    // Remove related from model
    const onRemoveHandler = (id: number | string) => {
        removeRelation(id);
    }

    // Open dialog notification
    const openDialog = () => {
        setOpen(true);
    }

    // Close dialog notification
    const closeDialog = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} mb={5}>
                    <Button className="browse-media-btn" variant="contained" component="span" startIcon={<Plus/>}
                            onClick={() => {
                                if (!id) {
                                    openDialog()
                                } else {
                                    addRelation()
                                }
                            }}>Add relation</Button>
                </Grid>
            </Grid>

            {props.items.length > 0 &&
                <Grid item xs={12} >
                    <table>
                        <tbody>
                        {props.items.map((item: any) => {
                            return (
                                <tr key={item.id}>
                                    <td><strong>({item.sku})</strong> {item.title}</td>
                                    <td><Button variant="text"
                                                onClick={(e) => {
                                                    onRemoveHandler(item.id)
                                                }}><CloseIcon/>
                                    </Button>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </Grid>
            }

            <ConfirmationDialog
                message="Bitte speichern Sie zuerst Ihre Änderungen!"
                title="Speichern"
                open={open}
                button="Speichern"
                onConfirm={onDialogConfirm}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default InlineRelatedModel;
