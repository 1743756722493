import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Typography,
    Button,
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";


// Types
import {HeadCell} from "../../types/headCell";

// Styling
import SearchBar from "../components/SearchBar";
import {RelationContext} from "./store/relation-context";
import {Add as AddIcon} from "@mui/icons-material";


function Relation() {
    const relationCtx = useContext(RelationContext);

    // Set table headers data
    const headCells: Array<HeadCell> = relationCtx.headers;
    const model = relationCtx.model;
    const title = relationCtx.title;



    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Articles"/>
            <Grid item>
                <Typography variant="h1" gutterBottom display="inline">
                    {title}
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={relationCtx.search} onSearch={relationCtx.onSearch}/>
                </Grid>

                {relationCtx.selected && relationCtx.selected?.length > 0 &&
                    <Grid item>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={(e) => {
                            if (relationCtx.addRelations) {
                                relationCtx.addRelations();
                            }
                        }} startIcon={<AddIcon/>}>
                            Add relation
                        </Button>
                    </Grid>
                }
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={relationCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={relationCtx.removeItem}
                    onPaginateHandler={relationCtx.onPaginate}
                    onSortHandler={relationCtx.onSort}
                    onFilterHandler={relationCtx.onFilter}
                    onDataUpdate={relationCtx.onDataUpdate}
                    count={relationCtx.count}
                    page={relationCtx.page}
                    rowsPerPage={relationCtx.rowsPerPage}
                    sorting={relationCtx.sorting}
                    filters={relationCtx.filters}
                    filter={relationCtx.filter}
                    selectedArray={relationCtx.selected}
                    onRelationHandler={relationCtx.relationSelected}
                    model={model}
                    relation={true}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Relation;
