import React, {useEffect, useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Card,
    CardContent, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";
import {MappedField} from "../../../../types/mappedField";
import {ImportAvailableField} from "../../../../types/importAvailableField";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FieldsMappingImport: React.FC<FieldsGroupType> = (props) => {
    const {data, onAdditionalDataChange} = props;
    const allAvailableFields: ImportAvailableField[] = data.fileFields ? data.fileFields : [];
    let modUnique: MappedField[] = [];

    // States
    const [modelFields, setModelFields] = useState<MappedField[]>(data.mappedFields);

    useEffect(() => {
        setModelFields(data.mappedFields)
    }, [data.mappedFields]);


    //------------------------------- Public methods -------------------------------------

    // update mapped fields
    const handleFieldMapChange = (event: any, value: any, field: any) => {
        modUnique = modelFields.map((el: MappedField) => {
            let updatedEl;

            if (el.field === field.field) {
                updatedEl = {...el, mappedField: value}
            } else {
                updatedEl = el;
            }
            return updatedEl;
        });

        setModelFields(modUnique);

        if (onAdditionalDataChange) {
            onAdditionalDataChange(modUnique);
        }
    }

    // set unique field
    const handleFieldUniqueChange = (event: any, field: any) => {

        modUnique = modelFields.map((el: MappedField) => {
            let updatedEl;

            if (event.target.checked) {
                updatedEl = {...el, unique: true}

                if (el.field === field.field) {
                    updatedEl = {...el, unique: true}
                } else {
                    updatedEl = {...el, unique: false}
                }
            } else {
                updatedEl = {...el, unique: false}
            }

            return updatedEl
        });

        setModelFields(modUnique);

        if (onAdditionalDataChange) {
            onAdditionalDataChange(modUnique);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12}>
                    <Card sx={{minWidth: 275}} variant="outlined">
                        <CardContent>
                            <Typography variant="h5" mb={2}>
                                Karte der Felder
                            </Typography>

                            <Typography variant="subtitle1" mb={10}>
                                Die rot umrandeten Felder sind Pflichtfelder
                            </Typography>

                            {modelFields.map((item, index) => {
                                return (
                                    <Grid container spacing={6} mb={2} key={item.id}>

                                        <Grid item xs={5} md={4} mb={2}>
                                            <TextField
                                                name={item.field}
                                                label={item.label}
                                                value={item.label}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}/>
                                        </Grid>

                                        <Grid item xs={5} md={3} mb={2}>
                                            <FormControl className="full-width">
                                                <InputLabel>Feld wählen</InputLabel>

                                                <Select
                                                    className={item.required ? 'required-field' : ''}
                                                    name={item.field}
                                                    value={item.mappedField ? item.mappedField : ''}
                                                    label="Choose field"
                                                    onChange={(event: any) => {
                                                        handleFieldMapChange(event, event.target.value, item);
                                                    }}
                                                >
                                                    <MenuItem key={0} value=''>
                                                        Feld wählen
                                                    </MenuItem>
                                                    {allAvailableFields.map((el: { id: string, field: string }) => (
                                                        <MenuItem key={el.id} value={el.field}>
                                                            {el.field}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={2} mb={5}>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={item.unique}
                                                        onChange={(e) => {
                                                            handleFieldUniqueChange(e, item);
                                                        }}
                                                    />
                                                } label="Einzigartig"/>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                );
                            })}


                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </React.Fragment>
    );
}


export default FieldsMappingImport;
