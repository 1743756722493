import React, {useState} from "react";

import {
    Checkbox, Divider as MuiDivider, FormControl,
    FormControlLabel,
    FormGroup, FormLabel,
    Grid, Radio, RadioGroup,
    TextField as MuiTextField,

} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Divider = styled(MuiDivider)(spacing);


const AtributesCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const [valueHasChildElements, setValueHasChildElements] = useState<string>(values.hasChildElements ? values.hasChildElements : '1');
    const [valueCustomerPartGroupsEvaluation, setValueCustomerPartGroupsEvaluation] = useState<string>(values.customerPartGroupsEvaluation ? values.customerPartGroupsEvaluation : '1');


    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueHasChildElements((event.target as HTMLInputElement).value);
    };
    const handleRadioCustomerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueCustomerPartGroupsEvaluation((event.target as HTMLInputElement).value);
    };


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="indentation"
                        label="Einrückung"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.indentation ? values.indentation : ''}
                        error={Boolean(touched.indentation && errors.indentation)}
                        fullWidth
                        helperText={touched.indentation && errors.indentation}
                        onChange={onChange}
                        variant="outlined"

                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearIndentation"
                                    checked={state.clearIndentation ? state.clearIndentation : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={4}>
                    <FormControl>
                        <FormLabel
                            id="hasChildElements">Hat untergeordnete Elemente</FormLabel>
                        <RadioGroup
                            aria-labelledby="hasChildElements"
                            name="hasChildElements"
                            value={valueHasChildElements}
                            onChange={(e) => {
                                handleRadioChange(e);
                                onChange(e)
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Ja"/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label="Nein"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} mb={4}>
                    <FormControl>
                        <FormLabel
                            id="customerPartGroupsEvaluation">Kunden-Teilegruppen Bewertung</FormLabel>
                        <RadioGroup
                            aria-labelledby="customerPartGroupsEvaluation"
                            name="customerPartGroupsEvaluation"
                            value={valueCustomerPartGroupsEvaluation}
                            onChange={(e) => {
                                handleRadioCustomerChange(e);
                                onChange(e)
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Ja"/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label="Nein"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={6}>
                    <TextField
                        name="ratingFromPercentageValue"
                        label={'Bewertung ab +/- Prozentwert'}
                        value={values.ratingFromPercentageValue ? values.ratingFromPercentageValue : ''}
                        error={Boolean(touched.ratingFromPercentageValue && errors.ratingFromPercentageValue)}
                        fullWidth
                        helperText={touched.ratingFromPercentageValue && errors.ratingFromPercentageValue}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearRatingFromPercentageValue"
                                    checked={state.clearRatingFromPercentageValue ? state.clearRatingFromPercentageValue : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={6}>
                    <TextField
                        name="valuationFromTurnover"
                        label={'Bewertung ab +/- Umsatz'}
                        value={values.valuationFromTurnover ? values.valuationFromTurnover : ''}
                        error={Boolean(touched.valuationFromTurnover && errors.valuationFromTurnover)}
                        fullWidth
                        helperText={touched.valuationFromTurnover && errors.valuationFromTurnover}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearValuationFromTurnover"
                                    checked={state.clearValuationFromTurnover ? state.clearValuationFromTurnover : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AtributesCategory;
