import React, {ChangeEvent, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Button, Checkbox,
    DialogActions, FormControl,
    Grid,
    IconButton,
    InputLabel, ListItemText,
    MenuItem, OutlinedInput,
    Select,
    TextField as MuiTextField
} from "@mui/material";

// GraphQL

// Icons
import {X as CloseIcon, Save as SaveIcon} from "react-feather";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {FileType, MediaFileType} from "../../types/mediaCenter";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Popup: React.FC<MediaFileType> = (props) => {
    const langOptions = ['de','en','fr', 'it', 'es', 'in'];

    // States
    const [item, setItem] = useState<FileType>(props.item);
    const [selectedLanguage, setSelectedLanguage] = useState<string[]>(props.item?.languages ? props.item.languages : []);

    //------------------------------- Public methods -------------------------------------


    // This function is called when input changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        // @ts-ignore
        setItem({...item, [fieldName]: value});
    };

    // Set current lang on lang change
    const onLangChangeHandler = (value: any) => {
        setSelectedLanguage(value)
    };

    // Save new values
    const onSave = () => {
        props.onUpdateMetadata(item);
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth={true}
            >
                <IconButton
                    aria-label="close"
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <DialogTitle id="alert-dialog-title" sx={{m: 0, p: 2, mb: 5}}>
                        Bearbeiten von Dateimetadaten
                    </DialogTitle>
                    <Grid container spacing={6}>
                        <Grid item md={5}>
                            <TextField
                                name="name"
                                label="Titel"
                                value={item?.name}
                                fullWidth
                                onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                variant="outlined"
                                my={2}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <TextField
                                name="altName"
                                label="Alter Titel"
                                value={item?.altName}
                                fullWidth
                                onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                variant="outlined"
                                my={2}
                            />
                        </Grid>
                        <Grid item md={2} mt={2}>
                            <FormControl fullWidth>
                                <InputLabel id="lable-languages">Sprache</InputLabel>
                                <Select
                                    name="languages"
                                    labelId="lable-languages"
                                    id="languages"
                                    multiple
                                    fullWidth
                                    value={selectedLanguage}
                                    onChange={(e: any) => {
                                        inputHandler(e);
                                        onLangChangeHandler(e.target.value);
                                    }}
                                    input={<OutlinedInput label="Sprache" />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {langOptions.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox checked={selectedLanguage.indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{mb: 3}}>
                    <Button variant="contained" color="primary" onClick={onSave}
                            className="multi-btn" startIcon={<SaveIcon/>}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Popup;
