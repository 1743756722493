import React, {useEffect, useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, Checkbox, Autocomplete, Divider as MuiDivider,
} from "@mui/material";


// Types


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {FieldsGroupType} from "../../../../types/fieldsGroup";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Divider = styled(MuiDivider)(spacing);

const GeneralInfoAdditional: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange, state, multiEdit, additionalData} = props;
    const categories: { value: string, label: string }[] = additionalData ? additionalData : [];
    const selectedCategoriesObj = values.categories ? categories.filter(({value}) => values.categories.includes(value.toString())) : [];

    // State
    const [selectedCategories, setSelectedCategories] = useState<any[]>(selectedCategoriesObj);

    useEffect(() => {
        setSelectedCategories(selectedCategoriesObj);
    }, [categories]);

    //------------------------------- Public methods -------------------------------------

    const handleCategoriesChange = (value: any) => {
        setSelectedCategories(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} sm={6} md={3} mb={5}>
                    <TextField
                        name="title"
                        label="Titel"
                        value={values.title ? values.title : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Titel FRS"
                        value={values.titleDe ? values.titleDe : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1} mb={5}>
                    <TextField
                        name="code"
                        label="Code"
                        value={values.code ? values.code : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={10} mb={7}>
                    <Autocomplete
                        multiple
                        options={categories}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label }
                        value={selectedCategories || []}
                        defaultValue={selectedCategories || []}
                        onChange={(event: any, value: any) => {
                            handleCategoriesChange(value);
                            onChange(event, 'categories', 'autocomplete', value.map((item: any) => item.value))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={option.value}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Teilegruppen" placeholder="Teilegruppen"
                                       name="categories"/>
                        )}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoAdditional;
