import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    NewFileQuery,
    useCreateFileMutation,
    useNewFileQuery,
    CreateFileMutation,
    useInlineMediaUploadFileMutation,
    InlineMediaUploadFileMutation
} from "../../graphql/mediacenter/mediacenter.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    //title: Yup.string().required("Required"),
});

const FileUpload: React.FC = () => {
    let title: string = 'Neue Datei'
    let dataObj: any = {};
    const inlineModels: string[] = ['files', 'images', 'cadData'];
    const navigate = useNavigate();
    const multipleSelect: boolean = false;
    const model: string = 'File';
    const location: any = useLocation();
    let allowedExtensions: string = '';

    // Queries
    const {data: data, error: error, loading: loading} = useNewFileQuery({
        variables: {
            fields: inlineModels,
        },
        onCompleted: (data: NewFileQuery) => {
            setState({});
            setInicialValue({});

            if (data.inlineMedia) {
                data.inlineMedia.map(item => {

                    switch (item.field) {
                        case 'files':
                            setFiles(item.inlineMedia);
                            allowedExtensions = allowedExtensions + item.inlineMedia.allowedExtensions;
                            break;
                        case 'images':
                            setFiles(item.inlineMedia);
                            allowedExtensions = allowedExtensions + ',' + item.inlineMedia.allowedExtensions;
                            break;
                        case 'cadData':
                            setFiles(item.inlineMedia);
                            allowedExtensions = allowedExtensions + ',' + item.inlineMedia.allowedExtensions;
                            break;
                    }
                })
            }
        }
    });

    const [createFile] = useCreateFileMutation();
    const [inlineMediaUploadFile] = useInlineMediaUploadFileMutation();


    // States
    const [updated, setUpdated] = useState(false);
    const [files, setFiles] = useState<any>({});
    const [filesItems, setFilesItems] = useState<any>([]);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveFile = () => {

        createFileMutation(state);

        setInicialValue({
            "data": state, "files": filesItems
        });
    }


    // Create file mutation
    const createFileMutation = (data: any) => {
        createFile({
            variables: {
                parent: location.state.id,
                file: filesItems,
                removedMedia,
            },
        })
            .then((res: FetchResult<CreateFileMutation>) => {
                //if (res.data && res.data.createFile) {

                setUpdated(true);
                if (location.state.id) {
                    navigate(`/mediacenter/folder/${location.state.id}`, {
                        replace: true,
                        state: {
                            fieldName: location.state?.fieldName,
                            fieldType: location.state?.fieldType,
                            isMultiple: location.state?.isMultiple,
                            modelName: location.state?.modelName,
                            modelId: location.state?.modelId,
                            modelIds: location.state?.modelIds,
                            fromMediaCenter: true,
                            submodelName: location.state?.submodelName,
                            submodelId: location.state?.submodelId
                        }
                    })
                } else {
                    navigate(`/mediacenter/`, {
                        replace: true,
                        state: {
                            fieldName: location.state?.fieldName,
                            fieldType: location.state?.fieldType,
                            isMultiple: location.state?.isMultiple,
                            modelName: location.state?.modelName,
                            modelId: location.state?.modelId,
                            modelIds: location.state?.modelIds,
                            fromMediaCenter: true,
                            submodelName: location.state?.submodelName,
                            submodelId: location.state?.submodelId
                        }
                    })
                }

                //}
            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        navigate(`/mediacenter`, {
            replace: true,
            state: {
                fieldName: location.state?.fieldName,
                fieldType: location.state?.fieldType,
                isMultiple: location.state?.isMultiple,
                modelName: location.state?.modelName,
                modelId: location.state?.modelId,
                modelIds: location.state?.modelIds,
                fromMediaCenter: true,
                submodelName: location.state?.submodelName,
                submodelId: location.state?.submodelId
            }
        })
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "files": filesItems
        });
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // Add media
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUploadFile({
            variables: {
                model,
                field: 'files',
                files: files,
                folder: location.state.id
            }
        }).then((res: FetchResult<InlineMediaUploadFileMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        })
    }

    // Update media
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        filesItems[foundIndex] = updateData;
        setFilesItems(filesItems)
    }

    // Remove media
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <React.Fragment>
            <Helmet title="File Upload"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveFile}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">


                                                <TabPanel value={tabValue} index={0}>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Dateien
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}
                                                        languages={files.languages}/>
                                                </TabPanel>

                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }

            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default FileUpload;
