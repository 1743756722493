import React, {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Button, Checkbox,
    DialogActions, DialogContentText,
    Grid,
    IconButton,
} from "@mui/material";

// Types
import {dynamicListViewSettings} from "../../types/dynamicListView";

// Icons
import {X as CloseIcon, Save as SaveIcon} from "react-feather";

const ListViewTableSettings: React.FC<dynamicListViewSettings> = (props) => {
    const {items, selectedItems, open, handleClose, updateSettings} = props;

    // States
    const [selectedFields, setSelectedFields] = useState<any[]>(selectedItems);

    useEffect(() => {
        setTimeout(() => {
            setSelectedFields(selectedItems);
        }, 500)
    }, [open]);

    //------------------------------- Public methods -------------------------------------

    // Check if field is selected
    const isSelected = (item: any) => selectedFields.findIndex(el => el.fieldName === item.fieldName) !== -1;

    // Set selected fields
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
        const selectedIndex = selectedFields.findIndex(el => el.fieldName === item.fieldName);
        let newSelected: Array<string> = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedFields, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedFields.slice(1));
        } else if (selectedIndex === selectedFields.length - 1) {
            newSelected = newSelected.concat(selectedFields.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedFields.slice(0, selectedIndex),
                selectedFields.slice(selectedIndex + 1)
            );
        }

        setSelectedFields(newSelected);
    };

    // Save selected fields
    const onSave = () => {
        props.updateSettings(selectedFields);
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="List settings"
                aria-describedby="List settings"
                maxWidth='md'
                fullWidth={true}
            >
                <IconButton
                    aria-label="close"
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    <DialogTitle id="alert-dialog-title" sx={{m: 0, p: 2}}>
                        Tabellenfelder der Listenansicht
                    </DialogTitle>
                    <DialogContentText sx={{m: 0, p: 2, mb: 5}}>
                        Bitte wählen Sie die Felder aus, die in der Tabelle angezeigt werden sollen
                    </DialogContentText>

                    <Grid container spacing={6}>
                        {items.map((settingItem: any, index: number) => {
                            let isItemSelected = isSelected(settingItem);

                            return (
                                <Grid key={index} item md={4}>
                                    <Checkbox
                                        checked={isItemSelected}
                                        onClick={(event) => handleClick(event, settingItem)}
                                        disabled={settingItem.fieldRequired}
                                    />
                                    {settingItem.fieldLabel}
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </DialogContent>

                <DialogActions sx={{mb: 3}}>
                    <Button variant="contained" color="primary" onClick={onSave}
                            className="multi-btn" startIcon={<SaveIcon/>}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ListViewTableSettings;
