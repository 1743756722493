import React from "react";
import {Button, Grid, Typography} from "@mui/material";

// Types
import {AdditionalOption} from "../../../../types/additionalOption";

// Styling
import AdditionalOptionItem from "./AdditionalOptionItem";
import {Plus} from "react-feather";


const AdditionalOptions: React.FC<{ options: AdditionalOption[], onAdd: (data: AdditionalOption) => void, onUpdate: (data: any) => void, onDelete: (data: any) => void }> = (props) => {
    const {onAdd, onUpdate, onDelete} = props;

    // Prepare files for upload
    const onAddNew = () => {

        const newOption = {
            id: new Date().getTime().toString(),
            title: '',
            titleDe: '',
            sorting: 0,
        }

        onAdd(newOption);
    }

    // Trigger file update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }

    // Trigger file delete
    const onDeleteHandler = (data: any) => {
        onDelete(data.id);
    }

    return (
        <React.Fragment>

            <Grid item xs={12} mb={5} mx={2}>

                <Typography variant="h6" mb={5}>
                    Optionen
                </Typography>
            </Grid>

            <Grid item xs={12}  mx={2}>
                {props.options.map((item: AdditionalOption) => {
                    return (
                        <AdditionalOptionItem key={item.id} item={item} onUpdate={onUpdateHandler}
                                              onDelete={onDeleteHandler}/>
                    )
                })}
            </Grid>

            <Grid item xs={12} mb={5} mx={2}>
                <Button variant="contained" component="span" startIcon={<Plus />} onClick={() => onAddNew()}>Option hinzufügen</Button>
            </Grid>
        </React.Fragment>
    );
}

export default AdditionalOptions;
