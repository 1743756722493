import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const ConfirmationDialog: React.FC<{ message: string, title: string, button:string, onConfirm: () => void, onCancel: () => void, open: boolean }> = (props) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>Abbrechen</Button>
                <Button onClick={props.onConfirm} autoFocus>
                    {props.button}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmationDialog
