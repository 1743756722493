import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../../components/ListViewTable";
import {DangerousContext} from "./store/dangerous-context";
import SearchBar from "../../components/SearchBar";
import ListViewTableSettings from "../../components/ListViewTableSettings";

// Types
import {HeadCell} from "../../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz} from "@mui/icons-material";
import {Settings} from "react-feather";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);

function Dangerous() {
    const dangerousCtx = useContext(DangerousContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = dangerousCtx.headers;
    const model = 'DangerousGood';
    const tableSettings = dangerousCtx.getTableSettings!

    // States
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openSettings, setOpenSettings] = React.useState(false);

    //------------------------------- Public methods -------------------------------------


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Open setting modal
    const onSettingsHandel = (): void => {
        setOpenSettings(true);
    }

    // Close settings modal
    const toggleSettingsHandler = () => {
        setOpenSettings(!openSettings);
    }

    // Update settings
    const onSettingsUpdateHandel = (data: any): void => {
        if (dangerousCtx.setTableSettings) {
            dangerousCtx.setTableSettings(data);
        }
        setOpenSettings(false);
    }


    return (
        <React.Fragment>
            <Helmet title="Dangerous Goods"/>
            <Grid item xs={12} mb={10}>
                <Typography variant="h1" gutterBottom display="inline">
                    Gefahrengut
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={dangerousCtx.search} onSearch={dangerousCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={dangerousCtx.addItem} startIcon={<AddIcon/>}>
                            Neues Gefahrgut
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (dangerousCtx.exportCsv) {
                                    dangerousCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV-Export Gefahrengut
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (dangerousCtx.exportCsv) {
                                    dangerousCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Gefahrengut
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS-Import Gefahrengut
                            </MenuItem>

                            {dangerousCtx.selected && dangerousCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={dangerousCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Mehrfachbearbeitung
                                    </MenuItem>
                                </div>
                            }
                            <MenuItem
                                onClick={(event: any) => onSettingsHandel()}
                                disableRipple>
                                <Settings/>
                                Settings
                            </MenuItem>
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (dangerousCtx.importCsv) {
                                    dangerousCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={dangerousCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={dangerousCtx.removeItem}
                    onSelectHandler={dangerousCtx.selectItem}
                    onPaginateHandler={dangerousCtx.onPaginate}
                    onSortHandler={dangerousCtx.onSort}
                    onDataUpdate={dangerousCtx.onDataUpdate}
                    count={dangerousCtx.count}
                    page={dangerousCtx.page}
                    rowsPerPage={dangerousCtx.rowsPerPage}
                    sorting={dangerousCtx.sorting}
                    filters={dangerousCtx.filters}
                    onMultiEditHandler={dangerousCtx.multiSelected}
                    selectedArray = {dangerousCtx.selected}
                    model={model}
                />
            </Grid>

            <ListViewTableSettings
                open={openSettings}
                handleClose={toggleSettingsHandler}
                items={tableSettings}
                selectedItems={headCells}
                updateSettings={onSettingsUpdateHandel}
            />
        </React.Fragment>
    );
}

export default Dangerous;
