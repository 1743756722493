import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import {
    Alert as MuiAlert,
} from "@mui/material";
import SnackbarNotification from "../../../components/SnackbarNotification";

// Types
import {dynamicListView} from "../../../types/dynamicListView";
import {Filters} from "../../../types/filters";

// Graphql
import {useModelListQuery} from "../../../graphql/relation/relation.graphql-gen";

// Style
import styled from "styled-components/macro";
import {spacing} from "@mui/system";

const Alert = styled(MuiAlert)(spacing);


export const RelationContext = React.createContext<dynamicListView>({
    items: [],
    headers: [],
    count: 0,
    addRelations:() => {},
    removeItem: (id: string) => {},
    onPaginate: (limit: number, page: number) => {},
    onSearch: (searchText:string) => {},
    onSort: (filed:string, order: string) => {},
    onFilter: (values: any) => {},
    onDataUpdate: () => {},
    page: 0,
    rowsPerPage: 10,
    search: '',
    sorting: {field: '', order: ''},
    filters: [],
    filter: {},
    multiSelected: (idArray: string[]) => {},
    selected: [],
    model: '',
    title: '',
    path: '',
    id: 'new'
});

const RelationContextProvider: React.FC = (props) => {
    const relationCtx = useContext(RelationContext);
    const model: string = relationCtx.model! ;
    const title: string = relationCtx.title! ;
    const path: string = relationCtx.path! ;
    const id: string = relationCtx.id! ;
    const navigate = useNavigate();

    // States
    const [items, setItems] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [sorting, setSorting] = useState<{ field: string, order: string }>({field: '', order: ''});
    const [filters, setFilters] = useState<Filters[]>([]);
    const [filter, setFilter] = useState<any>({} );
    const [selectedArray, setSelectedArray] = useState<any[]>(relationCtx.related ? relationCtx.related.map(item => item.id): []);
    const [selectedRelations, setSelectedRelations] = useState<any[]>(relationCtx.related ? relationCtx.related : []);

    // Get products data from API
    const {data, error, loading, refetch: refetchListViewData} = useModelListQuery({
        variables: {
            model,
            search,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }, onCompleted: () => {
            if (data) {
                setHeaders(data.getDynamicTable.tableHeader);
                setItems(data.getDynamicTable.tableData);
                setCurrentPage(data.getDynamicTable.page);
                setItemsPerPage(data.getDynamicTable.limit);
                setCount(data.getDynamicTable.count);
                setSearch(data.getDynamicTable.search);
                setFilters(data.getDynamicTable.filters);
                setFilter(data.getDynamicTable.filter);
                setSorting(data.getDynamicTable.sorting);
            }
        },
        notifyOnNetworkStatusChange: true
    });


    if (loading) {
        return <Loader/>;
    }

    if(!model){
        navigate('/');
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    //------------------------------- Public methods -------------------------------------

    // Paginate listview table
    const onPaginateHandler = (limit: number, page: number) => {
        refetchListViewData({
            model,
            search,
            filter,
            pagination: {
                limit: limit,
                page: page
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch((error: any) => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
        setItemsPerPage(limit);
        setCurrentPage(page);
    };

    // Search
    const onSearchHandler = (searchText: string) => {
        setSearch(searchText);

        refetchListViewData({
            model,
            search: searchText,
            filter,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch((error: any) => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Sort
    const onSortHandler = (field: string, order: string) => {
        setCurrentPage(0);
        setSorting({field, order});

        refetchListViewData({
            model,
            search,
            filter,
            pagination: {
                limit: itemsPerPage,
                page: 0
            },
            sorting: {
                field,
                order
            }
        }).catch((error: any) => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // filter
    const onFilterHandler = (values: any) => {

        refetchListViewData({
            model,
            search,
            filter: values,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch((error: any) => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
        setFilter(values);
    };

    // Set relations
    const onSetRelationsHandler = (idArray: string[], data:any) => {
        setSelectedArray(idArray);
        setSelectedRelations(oldState => [...oldState, data]);
    }

    // Add relations
    const onAddRelationsHandler = () => {
        navigate(`/${path}/${id}`, {replace: true, state: {fromRelations: true, activeTab: 'relation', items: selectedRelations}});
    }

    // Update data
    const onDataUpdateHandler= () => {
        refetchListViewData();
    }

    // Set context values
    const contextValue: dynamicListView = {
        items: items,
        headers: headers,
        count: count,
        relationSelected: onSetRelationsHandler,
        addRelations: onAddRelationsHandler,
        onPaginate: onPaginateHandler,
        onSearch: onSearchHandler,
        onSort: onSortHandler,
        onFilter: onFilterHandler,
        onDataUpdate: onDataUpdateHandler,
        page: currentPage,
        rowsPerPage: itemsPerPage,
        search: search,
        sorting: sorting,
        filters: filters,
        filter: filter,
        selected: selectedArray,
        related: selectedRelations,
        title,
        model,
        path,
        id,
        relation: true
    };

    return (
        <RelationContext.Provider value={contextValue}>
            {props.children}
        </RelationContext.Provider>
    );
};

export default RelationContextProvider;
