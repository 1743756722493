import React, {useEffect, useState} from "react";

import {
    Grid,
    TextField as MuiTextField,
    Button, Typography
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import DoneAllIcon from '@mui/icons-material/DoneAll';


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const FileFromUrlImport: React.FC<FieldsGroupType> = (props) => {
    const {state, values, touched, errors, onChange, additionalData, onAdditionalDataChange, data} = props;

    const [validImportFile, setValidImportFile] = useState<boolean>(values.validImportFile);

    useEffect(() => {
        setValidImportFile(state.validImportFile);
    }, [state]);


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            {!additionalData.showInlineMedia &&
                <Grid container spacing={6} mb={5}>

                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="filePath"
                            label='Datei-Pfad'
                            value={values.filePath ? values.filePath : ''}
                            error={Boolean(touched.filePath && errors.filePath)}
                            fullWidth
                            helperText={touched.filePath && errors.filePath}
                            onChange={onChange}
                            variant="outlined"/>
                    </Grid>
                </Grid>
            }

            <Grid container spacing={6} mb={5}>

                <Grid item xs={12} md={12}>
                    <Typography variant="h6" mb={1}>
                        Parameter der Importdatei
                    </Typography>
                </Grid>

                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="separator"
                        label='Abscheider'
                        value={values.separator ? values.separator : ','}
                        error={Boolean(touched.separator && errors.separator)}
                        fullWidth
                        helperText={touched.separator && errors.separator}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>

                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="enclosure"
                        label='Gehege'
                        value={values.enclosure ? values.enclosure : '"'}
                        error={Boolean(touched.enclosure && errors.enclosure)}
                        fullWidth
                        helperText={touched.enclosure && errors.enclosure}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>
            </Grid>

            <Grid container spacing={6} mb={5}>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="imagesFileDirectory"
                        label='Verzeichnis der Bilddateien'
                        value={values.imagesFileDirectory ? values.imagesFileDirectory : ''}
                        error={Boolean(touched.imagesFileDirectory && errors.imagesFileDirectory)}
                        fullWidth
                        helperText={touched.imagesFileDirectory && errors.imagesFileDirectory}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>
            </Grid>

            {!validImportFile &&
                <Grid container spacing={6} mb={5}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color={"red"} mb={1}>
                            Die aktuelle Datei ist eine andere als die zugeordnete Datei!
                        </Typography>
                    </Grid>
                </Grid>
            }

            {data.mappedFields &&
                <Grid container spacing={6} mb={5}>

                    <Grid item xs={12} mb={5}>
                        <Button variant="contained" endIcon={<DoneAllIcon/>} onClick={onAdditionalDataChange}>Validieren Sie
                            Datei</Button>
                    </Grid>
                </Grid>
            }

        </React.Fragment>
    );
}


export default FileFromUrlImport;
