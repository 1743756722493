import {SidebarItemsType} from "../../types/sidebar";

import {
    List,
    Sliders,
    Server,
    Layers,
    Settings,
    Briefcase,
    Folder,
    Grid as GridIcon
} from "react-feather";
import {useTranslation} from "react-i18next";


const pagesSection = [
    {
        href: "/dashboard",
        icon: Sliders,
        title: "Dashboard",
    },
    {
        href: "/categories",
        icon: Layers,
        title: "Kategorien",
    },
    {
        href: "/products",
        icon: List,
        title: "Artikel",
    },
    {
        href: "/companies",
        icon: Briefcase,
        title: "Lieferanten",
    },
    {
        href: "/mediacenter",
        icon: Folder,
        title: "Mediacenter",
    },
    {
        href: "/settings",
        icon: Settings,
        title: "Einstellungen",
        children: [
            {
                href: "/settings/selects",
                title: "Dynamische Auswahl",
            },
            {
                href: "/settings/benefits",
                title: "Marke",
            },
            {
                href: "/settings/dangers",
                title: "Gefahrengut",
            },
            {
                href: "/settings/symbols",
                title: "Gefahrensymbole",
            },
            {
                href: "/settings/additional",
                title: "Zusätzliche Attribute",
            },
            {
                href: "/settings/users",
                title: "Benutzer",
            },
            {
                href: "/settings/imports",
                title: "Import",
            },
        ],
    },
] as SidebarItemsType[];


const navItems = [
    {
        title: "",
        pages: pagesSection,
    },
];

export default navItems;
