import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../../components/ListViewTable";
import {SymbolsContext} from "./store/symbols-context";

// Types
import {HeadCell} from "../../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz} from "@mui/icons-material";
import SearchBar from "../../components/SearchBar";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);

function Symbols() {
    const symbolsCtx = useContext(SymbolsContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = symbolsCtx.headers;
    const model: string = 'Symbol';

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Gefahrensymbole"/>
            <Grid item xs={12} mb={10}>
                <Typography variant="h1" gutterBottom display="inline">
                    Gefahrensymbole
                </Typography>

            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={symbolsCtx.search} onSearch={symbolsCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={symbolsCtx.addItem} startIcon={<AddIcon/>}>
                            Neue Gefahrensymbole
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (symbolsCtx.exportCsv) {
                                    symbolsCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV-Export Gefahrensymbole
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (symbolsCtx.exportCsv) {
                                    symbolsCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Gefahrensymbole
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS-Import Gefahrensymbole
                            </MenuItem>

                            {symbolsCtx.selected && symbolsCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={symbolsCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Mehrfachbearbeitung
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (symbolsCtx.importCsv) {
                                    symbolsCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={symbolsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={symbolsCtx.removeItem}
                    onSelectHandler={symbolsCtx.selectItem}
                    onPaginateHandler={symbolsCtx.onPaginate}
                    onSortHandler={symbolsCtx.onSort}
                    onDataUpdate={symbolsCtx.onDataUpdate}
                    count={symbolsCtx.count}
                    page={symbolsCtx.page}
                    rowsPerPage={symbolsCtx.rowsPerPage}
                    sorting={symbolsCtx.sorting}
                    filters={symbolsCtx.filters}
                    onMultiEditHandler={symbolsCtx.multiSelected}
                    selectedArray = {symbolsCtx.selected}
                    model = {model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Symbols;
